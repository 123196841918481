var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_vm._v("test "),_c('v-group',{attrs:{"config":_vm.configGroup}},[_c('v-rect',{attrs:{"config":_vm.configArea}}),_c('v-label',{attrs:{"config":{
        x: 30,
        y: 15,
      }}},[_c('v-text',{attrs:{"config":{ text: 'A :', fill: 'black' }}})],1),_c('v-label',{attrs:{"config":{
        x: 50,
        y: 15,
      }}},[_c('v-text',{attrs:{"config":{ text: _vm.areaText, fill: 'black' }}})],1),_c('v-rect',{attrs:{"config":_vm.configOl1}}),_c('v-label',{attrs:{"config":{
        x: 100,
        y: 15,
      }}},[_c('v-text',{attrs:{"config":{ text: 'OLF :', fill: 'black' }}})],1),_c('v-label',{attrs:{"config":{
        x: 135,
        y: 15,
      }}},[_c('v-text',{attrs:{"config":{ text: _vm.olfText, fill: 'black' }}})],1),_c('v-rect',{attrs:{"config":_vm.configOlf}}),_c('v-label',{attrs:{"config":{
        x: 20,
        y: 55,
      }}},[_c('v-text',{attrs:{"config":{ text: 'OL :', fill: 'black' }}})],1),_c('v-label',{attrs:{"config":{
        x: 50,
        y: 55,
      }}},[_c('v-text',{attrs:{"config":{ text: _vm.olnText, fill: 'black' }}})],1),_c('v-rect',{attrs:{"config":_vm.configExit}}),_c('v-label',{attrs:{"config":{
        x: 110,
        y: 55,
      }}},[_c('v-text',{attrs:{"config":{ text: 'E :', fill: 'black' }}})],1),_c('v-label',{attrs:{"config":{
        x: 130,
        y: 55,
      }}},[_c('v-text',{attrs:{"config":{ text: _vm.exitText, fill: 'black' }}})],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }