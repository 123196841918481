<template>
  <div>
    <CToaster :autohide="3000">
      <template v-for="info in infoList">
        <CToast
          :key="info.message"
          :show="true"
          :header="info.header"
          :color="info.color"
        >
          {{ info.message }}.
        </CToast>
      </template>
    </CToaster>
    <div>
      <WpZoom :percent="parseFloat(zoomPercentage).toFixed(0)" class="bottom-right invert--" @zoomIn="zoomIn"
          @zoomReset="zoomReset" @zoomOut="zoomOut"></WpZoom>
      <CModal size="xl"
      :show.sync="cropImagePopup"
      title="FSL Image"
      :closeOnBackdrop="false"
      :centered="true"
      >
        <CModalBody>
          <div class="clipper-ratio">
            <div>
              <div style="display: flex;">
                <div class="clipper-ratio-heading">
                  Aspect Ratio
                </div>
                <CInputRadioGroup
                  :checked.sync="selectedRatio"
                  :options="options"
                  inline
                />
              </div>
             <div>
                <CInputCheckbox
                  :checked.sync="showRoomEgressCapacity"
                  label="Show Room Egress Capacity"
                  custom
                  class="mt-2"
                />
              </div>
            </div>

            <div>
              <CInput 
                label="Scaling Percentage %" 
                :value.sync="scalingPercentage" 
                type="number"
                min="0"
                max="100"
              />
            </div>

            <div>
              <CSelect
                label="Select Paper Size"
                vertical
                :options="showRoomEgressCapacity ? ['A0', 'A1'] : ['A0', 'A1', 'A3']"
                :value.sync="paperSizeVal"
              >
              </CSelect>
            </div>
          </div>

          <div class="clipper-wrapper">
            <clipper-basic
              ref="clipper"
              class="clipper"
              :ratio="computedRatio"
              :src="stageImage"
              preview="cropResult"
              :handle-zoom-event="handleZoom"
            />
            <!-- <clipper-preview class="clipper preview--" name="cropResult"></clipper-preview> -->
          </div>
          <div class="image-preview-wrapper">
            <div
            v-for="(imageData, index) in croppedImages"
            @click="onViewImage(imageData)"
            :key="index"
            class="image-preview"
            >
              <img :src="imageData" :alt="`Image Preview ${index + 1}`"/>
              <div class="button-group">
                <CButton @click="onDeleteImage(index, $event)" color="danger">
                  <CIcon name="cil-trash"/>
                </CButton>
              </div>
            </div>
          </div>
        </CModalBody>
        <template #footer>
          <CButton
            @click="onCropImage"
            color="secondary"
            >Crop
          </CButton>
          <CButton
            @click="getCroppedImages"
            color="primary"
            :disabled="croppedImages.length === 0"
            >Done
          </CButton>
        </template>
      </CModal>

      <CModal size="lg"
      :show.sync="imagePreviewPopup"
      title="Image Preview"
      :closeOnBackdrop="false"
      :centered="true"
      >
        <CModalBody>
          <div class="image-prev">
            <img :src="previewImage" alt="">
          </div>
        </CModalBody>
      </CModal>

      <WpLegends @export-pdf="stageImagePopup" :data="obj"></WpLegends>
      <v-stage
        ref="stage"
        :config="stageSize"
        :style="{
          top: 0,
          overflow: 'auto',
        }"
      >
        <v-layer>
          <v-group ref="group">
            <v-image
              ref="image"
              :config="{
                x: 0,
                y: 0,
                image: image,
              }"
            />
            <KvRoom
              v-for="item in rooms"
              :key="item.code"
              :obj="item"
              :distanceInMeterForOnePixel="distanceInMeterForOnePixel"
            >
            </KvRoom>
            <template v-for="item in diagonalDistances">
              <template v-if="obj.buildingCode && (obj.buildingCode.code === 'NFPA 101' || obj.buildingCode.code === 'IBC')">
                <v-line
                  :key="item.code"
                  :config="item"
                  @click="diagonalDistanceLabelClick(item)"
                />
                <v-label
                  :key="item.code + 'label'"
                  :config="{
                    x: item.points[0],
                    y: item.points[1],
                  }"
                >
                  <v-text
                    :config="{
                      text: getLineInfo(item),
                      fill: item.stroke,
                      padding: 5,
                    }"
                  />
                </v-label>
              </template>
            </template>

            <template v-for="item in exitSeperations">
              <v-line :key="item.code" :config="item" />
              <v-label
                :key="item.code + 'label'"
                :config="{
                  x: item.points[0],
                  y: item.points[1],
                }"
              >
                <v-text
                  :config="{
                    text: getLineInfo(item),
                    fill: item.stroke,
                    padding: 5,
                  }"
                />
              </v-label>
            </template>

            <template v-for="item in computedTravelDistances">
              <v-arrow :key="item.code" :config="item" v-if="!item.isHidden"/>
              <v-label :key="item.code + 'label'" :config="getLabelConfig(item)" v-if="!item.isHidden">
                <v-text :config="getTextConfig(item)" />
              </v-label>
            </template>

            <template v-for="item in computedCommonPaths">
              <v-arrow :key="item.code" :config="item" v-if="!item.isHidden"/>
              <v-label :key="item.code + 'label'" :config="getLabelConfig(item)" v-if="!item.isHidden">
                <v-text :config="getTextConfig(item)" />
              </v-label>
            </template>

            <template v-for="item in computedCorridorEnds">
              <v-arrow :key="item.code" :config="item" />
              <v-label :key="item.code + 'label'" :config="getLabelConfig(item)">
                <v-text :config="getTextConfig(item)" />
              </v-label>
            </template>

            <template v-for="item in computedDeadEnds">
              <v-arrow :key="item.code" :config="item" v-if="!item.isHidden"/>
              <v-label :key="item.code + 'label'" :config="getLabelConfig(item)" v-if="!item.isHidden">
                <v-text :config="getTextConfig(item)" />
              </v-label>
            </template>

            <template v-for="(item, index) in obj.doors">
                <v-label
                  :key="item.code + '-' + index"
                  :config="item"
                  v-if="item.isFinalExitDoor"
                >
                  <v-image
                    :key="item.code + '-icon-' + index"
                    :config="{
                      image: exitDoorImg,
                      width: item.width ?? 108,
                      height: calculateNewExitDoorHeight(item.width),
                    }"
                  />
                  <v-text
                    :config="{
                      text: item.name,
                      fill: 'green',
                      shadowColor: 'black',
                      shadowBlur: 10,
                      shadowOffset: { x: 10, y: 10 },
                      shadowOpacity: 0.5,
                      padding: -15,
                    }"
                  />
                </v-label>
              </template>
          </v-group>
        </v-layer>
      </v-stage>
    </div>
  </div>
</template>
<script>
import FloorPlanApi from "@/lib/floorPlanApi";
import OlfMainCategoryApi from "@/lib/olfMainCategoryApi";
import OccupantLoadFactorApi from "@/lib/occupantLoadFactorApi";
import BuildingCodeApi from "@/lib/buildingCodeApi";
import OlfSubCategoryApi from "@/lib/olfSubCategoryApi";
import DeepFloorPlanApi from "@/lib/deepFloorPlanApi";
import RoomApi from "@/lib/roomApi";
import DoorApi from "@/lib/doorApi";
import KvRoom from "./KvRoom.vue";
import WpLegends from "./WpLegends.vue";
import html2pdf from 'html2pdf.js';
import { clipperUpload, clipperBasic, clipperPreview, clipperFixed } from "vuejs-clipper";
import VueRx from "vue-rx";
import Vue from "vue";
import ExitDoorImg from "./../assets/images/doors/exit-icon.png";
import PdfApi from "../lib/pdf.api";
import WpZoom from "./WpZoom.vue";

const occupantLoadFieldList = [
  // { key: "id", _style: "min-width:200px;" },
  { key: "name", label: "Name" },

  // {
  //   key: "olfMainCategoryName",

  //   label: "Main Category",
  // },
  { key: "area", label: "Area (m2)" },

  {
    key: "oclValue",
    label: "Occupant Load Factor (m2/person)",
  },
  {
    key: "occupantNumber",
    label: "Occupant Number",
  },
];

const egressCapacityList = [
  { key: "stairNo", label: "Stair No." },
  { key: "totalStairWidthRequired", label: "Total Stair Width Required" },

  {
    key: "totalExitWidthProvided",
    label: "Total Exit Width Provided",
  },
];
Vue.use(VueRx);

export default {
  name: "WpPrint",
  components: {
    KvRoom,
    WpLegends,
    WpZoom,
    clipperUpload,
    clipperBasic,
    clipperPreview,
    clipperFixed
  },
  props: {
    obj: null,
  },
  data() {
    return {
      scalingPercentage: 1,
      showRoomEgressCapacity: false,
      zoomPercentage: 100,
      exitDoorImg: null,
      paperSizeVal: 'A0',
      options: [
        { label: "Default", value: null },
        { label: "1:1", value: "1/1" },
        { label: "4:3", value: "4/3" },
        { label: "16:9", value: "16/9" },
      ],
      selectedRatio: null,
      previewImage: null,
      floorPlanImage: null,
      croppedImages: [],
      scale: 1,
      stageImage: null,
      cropImagePopup: false,
      imagePreviewPopup: false,
      occupantLoadFieldList,
      egressCapacityList,
      infoList: [],
      api: new FloorPlanApi(),
      olfMainCategoryApi: new OlfMainCategoryApi(),
      occupantLoadFactorApi: new OccupantLoadFactorApi(),
      buildingCodeApi: new BuildingCodeApi(),
      olfSubCategoryApi: new OlfSubCategoryApi(),
      deepFloorPlanApi: new DeepFloorPlanApi(),
      doorApi: new DoorApi(),
      roomApi: new RoomApi(),
      pdfApi: new PdfApi(),

      stageSize: {
        width: 3000,
        height: 3000,
        draggable: true,
        dragBoundFunc: (pos) => {
          this.obj.stagePosX = pos.x;
          this.obj.stagePosY = pos.y;
          return {
            x: pos.x,
            y: pos.y,
          };
        },
      },
      image: null,
      rooms: [],
      doors: [],
      exitStairAreas: [],
      exitStairDoors: [],
      exitStairWidths: [],
      exitSeperations: [],
      commonPaths: [],
      travelDistances: [],
      diagonalDistances: [],
      obstacles: [],
      corridorEnds: [],
      deadEnds: [],
      fireRating: []
    };
  },

  computed: {
    computedRatio() {
      return this.selectedRatio ? eval(this.selectedRatio) : null;
    },
    dominantClassification() {
      var array = this.findOccurance(
        this.computedOccupantLoadItems,
        "olfMainCategoryName"
      );

      array = array.sort(function (a, b) {
        return b.occurrence - a.occurrence;
      });

      if (array.length == 0) return "";
      return array[0].olfMainCategoryName;

      // var counts = {};
      // your_array.forEach(function (x) {
      //   counts[x] = (counts[x] || 0) + 1;
      // });
    },
    computedOccupantLoadItems() {
      if (this.roomItems != null) {
        var rooms = this.roomItems.map((item) => {
          return {
            ...item,
            olfMainCategoryId: this.getOlfMainCategoryId(item),
            olfMainCategoryName: this.getOlfMainCategoryName(item),
            buildingCodeName: this.getBuildingCodeName(item),
            area: this.getPolygonAreaInMeter(item),
            oclBuildingCodeName: this.getOclBuildingCodeName(item),
            oclValue: this.getOclValue(item),
            occupantNumber: this.calcOccupantNumber(item),
            mneBuildingRuleName: this.getMneBuildingRuleName(item),
            mneCount: this.getMneCount(item),
          };
        });

        let sortedRooms = rooms.sort((a, b) => {
          if (a.name < b.name) {
            return -1;
          }
          if (a.name > b.name) {
            return 1;
          }
          return 0;
        });

        return sortedRooms;
      }
    },
    computedCommonPaths() {
      return this.computeDynamicArray(this.commonPaths);
    },
    computedTravelDistances() {
      return this.computeDynamicArray(this.travelDistances);
    },
    computedCorridorEnds() {
      return this.computeDynamicArray(this.corridorEnds);
    },
    computedDeadEnds() {
      return this.computeDynamicArray(this.deadEnds);
    },
    computedFireRating() {
      let fireRating = []
      return this.computeDynamicArray(fireRating);
    },

    distanceInMeterForOnePixel() {
      return this.obj.scale.distanceInMeterForOnePixel;
    },
    floorPlanUrl() {
      var fullUrl = "";
      if (this.obj) fullUrl = this.obj.documentUrl;
      return fullUrl;
    },
  },
  watch: {
    obj(newVal, oldVal) {
      this.stageSize.x = this.obj.stagePosX;
      this.stageSize.y = this.obj.stagePosY;
        },
    drawingMeta(newVal, oldVal) {
    },

    floorPlanUrl(newVal, oldVal) {
      const image = new window.Image();
      image.src = this.floorPlanUrl;
      image.crossOrigin = "anonymous";

      // Calculate exit separation distances
      this.obj.exitSeperations.forEach((exitSeperation) => {
        let distance = this.getDistance(exitSeperation);
        exitSeperation.exitSeperationDistanceProvided = distance;
      });

      // Find the maximum exit separation distance
      let maxExitSeperationDistance = this.obj.exitSeperations.reduce((max, current) => {
        return Math.max(max, current.exitSeperationDistanceProvided);
      }, -Infinity);

      // Filter the array to include only objects with the highest exit separation distance
      let highestExitSeperationDistance = this.obj.exitSeperations.filter((item) => {
        return item.exitSeperationDistanceProvided === maxExitSeperationDistance;
      });


      image.onload = () => {
        this.image = image;
        this.stageSize.width = image.width * 2;
        this.stageSize.height = image.height * 2;

        this.exitSeperations = highestExitSeperationDistance;
        this.exitStairWidths = this.obj.exitStairWidths;
        this.exitStairDoors = this.obj.exitStairDoors;
        this.diagonalDistances = this.obj.diagonalDistances;
        this.travelDistances = this.obj.travelDistances;
        this.commonPaths = this.obj.commonPaths;
        this.rooms = this.obj.rooms;
        this.obstacles = this.obj.obstacles;
        this.doors = this.obj.doors;
        this.exitStairAreas = this.obj.exitStairAreas;
        this.corridorEnds = this.obj.corridorEnds;
        this.deadEnds = this.obj.deadEnds;
        this.fireRating = this.obj.fireRating;
      };
    },
  },
  methods: {
    resetRatio() {
      this.$refs.clipper.setAspectRatio(this.defaultRatio); // Set the default ratio
    },
    calculateNewExitDoorHeight(roomDoorWidth) {
      if (!roomDoorWidth) { return 108 * roomDoorAspectRatio } 
      const roomDoorAspectRatio = 258 / 512;
      return roomDoorWidth * roomDoorAspectRatio;
    },
    computeDynamicArray(inputArray) {
      return inputArray.map(item => ({
        ...item,
        length: this.getDistanceInMeter(item),
      })).sort((a, b) => a.length - b.length);
    },
    getLabelConfig(item) {
      return {
        x: this.getMiddlePoint(item.points).x,
        y: this.getMiddlePoint(item.points).y,
      };
    },
    getTextConfig(item) {
      return {
        text: this.getLineInfo(item),
        fill: item.stroke,
      };
    },
    getDominantClassificationId() {
      var array = this.findOccurance(
        this.computedOccupantLoadItems,
        "olfMainCategoryId"
      );

      array = array.sort(function (a, b) {
        return b.occurrence - a.occurrence;
      });

      if (array.length == 0) return "";
      return array[0].olfMainCategoryId;
    },
    getDistanceInMeter(item) {
      if (!item || !this.obj || !this.obj.scale || !this.obj.scale.distanceInMeterForOnePixel) {
        return
      }
      var distanceInPixel = this.getDistance(item);
      var distainceInMeter =
        distanceInPixel * this.obj.scale.distanceInMeterForOnePixel;
      distainceInMeter = Math.round(distainceInMeter * 1000) / 1000;
      return distainceInMeter;
    },

    getDistance(item) {
      try {
        return drawing.getDistanceFromPoints(item.points);
      } catch (error) {}
      return 0;
    },

    getMiddlePoint(item) {
      return drawing.getMiddlePoint(item);
    },

    getLineInfo(item) {
      return item.name;
    },
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        header: header,
        message: message,
        color: color,
      });
    },
    captureDataURL() {
      const stage = this.$refs.stage.getStage();

      // Check if the stage exists
      if (stage) {
        this.floorPlanImage = stage.toDataURL();

        // You can now use the dataURL for your desired purposes
        // console.log(this.floorPlanImage);

        // Optionally, you can download the captured image
        // this.downloadDataURL(this.floorPlanImage, 'stage_capture.png');
      } else {
        console.error('Stage is not initialized.');
      }
    },

    downloadDataURL(dataURL, filename) {
      const link = document.createElement('a');
      link.download = filename;
      link.href = dataURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    },
    stageImagePopup() {
      this.cropImagePopup = true;
      const stage = this.$refs.group.getStage();
      const dataURL = stage.toDataURL();
      this.stageImage = dataURL;
    },
    handleZoom(scale) {
      this.scale = scale;
      return scale;
    },
    onCropImage() {
      const clipper = this.$refs.clipper.clip();
      const croppedImageUrl = clipper.toDataURL();
      this.croppedImages.push(croppedImageUrl)
    },
    getCroppedImages() {
      if (this.scalingPercentage > 100 || this.scalingPercentage < 1) {
        const errMsg = "Please provide a valid scaling percentage"
        this.toast("Error", errMsg, "danger");
      } else {
        this.createFslReport();
        this.cropImagePopup = false;        
      }
    },
    onDeleteImage(i, e) {
      e.stopPropagation();
      this.croppedImages.splice(i, 1)
    },
    onViewImage(image) {
      this.previewImage = image
      this.imagePreviewPopup = true;
    },
    zoomReset() {
      var stage = this.$refs.stage.getStage();
      stage.scale({ x: 0.8, y: 0.8 });
      this.zoomPercentage = 100;
    },
    zoomIn() {
      this.scaleBy(0.05);
    },
    zoomOut() {
      if (this.zoomPercentage > 5) {
        this.scaleBy(-0.05);
      }
    },
    scaleBy(scale) {
      var stage = this.$refs.stage.getStage();
      var oldScale = stage.scaleX();
      var newScale = oldScale + scale; // oldScale + scale;
      this.zoomPercentage = newScale * 100;
      stage.scale({ x: newScale, y: newScale });
    },
    async createFslReport() {
      this.captureDataURL();
      this.obj.fslReport.images = this.croppedImages;
      this.obj.fslReport.floorPlanImage = this.floorPlanImage;
      this.obj.fslReport.size = this.paperSizeVal;
      this.obj.fslReport.scalingPercentage = parseFloat(this.scalingPercentage)
      // console.log('fslReport is', this.obj.fslReport)
      this.obj.fslReport.showRoomEgressCapacity = this.showRoomEgressCapacity;
      // Call API here
      try {
        const res = await this.pdfApi.getPdf(this.obj.fslReport);
        const base64Data = res
        const binaryData = atob(base64Data);
        const arrayBuffer = new ArrayBuffer(binaryData.length);
        const uint8Array = new Uint8Array(arrayBuffer);
        for (let i = 0; i < binaryData.length; i++) {
          uint8Array[i] = binaryData.charCodeAt(i);
        }
        const blob = new Blob([uint8Array], { type: 'application/pdf' });
        const blobUrl = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = blobUrl;
        link.download = `${this.obj.fslReport.projectName}.pdf`;
        // Append the link to the body and trigger the download
        document.body.appendChild(link);
        link.click();

        // Remove the link from the body
        document.body.removeChild(link);

        // Revoke the Blob URL to free up resources
        URL.revokeObjectURL(blobUrl);
        console.log('fslReport is', this.obj.fslReport);
      } catch (error) {
        console.log('error is', error);
      }
    },
    loadImage(source, propertyName) {
      const image = new window.Image();
      image.src = source;
      image.onload = () => {
        this[propertyName] = image;
      };
    },
  },
  created() {
    this.loadImage(ExitDoorImg, "exitDoorImg");

  }
};
async function streamToBlob(stream) {
  const chunks = [];
  const reader = stream.getReader();

  while (true) {
    const { done, value } = await reader.read();

    if (done) {
      break;
    }

    chunks.push(value);
  }

  const blob = new Blob(chunks, { type: 'application/octet-stream' });
  return blob;
}
</script>

<style>
#divToExport {
  background-color: #EBEDEF;
  overflow: hidden;
  height: 100vh;
}

.table-row {
  position: fixed;
  bottom: 10px;
  left: 10px;
  z-index: 3;
  overflow: auto;
  background: white;
  padding: 20px;
  display: flex;
  gap: 20px;
}

.clipper {
  height: 100%;
  border: 2px dashed #000;
  max-height: 600px;
  margin: 0 auto;
  overflow: auto;
  /* width: 100%; */
}
.clipper.preview-- {
  border: 2px dashed #000;
}

.clipper-wrapper {
  display: flex;
  gap: 15px;
}

.image-preview-wrapper {
  margin-top: 20px;
  display: flex;
  gap: 10px;
  overflow: auto;
}

.image-preview {
  width: 100%;
  max-width: 200px;
  border: 1px solid;
  padding: 10px;
  flex-shrink: 0;
  position: relative;
  cursor: pointer;
  overflow: hidden;
}

.image-preview:hover img {
  transform: scale(1.15);
}

.image-preview img {
  width: 100%;
  transition: 0.4s ease;

}

.button-group button {
  margin-right: 10px;
}

.button-group {
  position: absolute;
  bottom: 10px;
  right: 10px;
}

.image-prev img {
    margin: auto;
    display: block;
    border: 1px solid;
    width: 100%;
}

.clipper-ratio {
  padding: 0 16px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.clipper-ratio-heading {
  margin-right: 10px;
}

.invert-- {
  left: 10px;
}
</style>
