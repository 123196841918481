<template>
  <div>
    <div v-if="pageLoading" class="d-flex justify-content-center mb-3" style="margin-top: 300px">
      <CSpinner color="primary" style="width: 4rem; height: 4rem" />
    </div>
    <div @keydown="onKeyboardShortcutEvent($event)">
      <CToaster :autohide="3000" style="top: 90px;">
        <template v-for="info in infoList">
          <CToast :key="info.id" :show="true" :header="info.header" :color="info.color">
            {{ info.message }}.
          </CToast>
        </template>
      </CToaster>

      <div class="work-component" v-if="showComponent">
        <WorkComponents :obj="obj" @roomClick="roomSettingClick" @doorClick="doorSettingClick"
          @exitAreaClick="exitAreaSettingClick" @exitStairWidthClick="exitStairWidthSettingClick"
          @exitDoorClick="exitDoorSettingClick" @exitSeperationClick="exitSeperationSettingClick"
          @diagonalDistanceClick="diagonalDistanceSettingClick" @commonPathClick="commonPathSettingClick"
          @deadEndClick="deadEndSettingClick" @corridorEndClick="corridorEndSettingClick"
          @travelDistanceClick="travelDistanceSettingClick" @refreshUpdate="refreshTravelDistance"
          @obstacleClick="obstacleSettingClick" />
      </div>
      <div>
        <WpTopRight class="top-right" @showComponentList="showComponentList"></WpTopRight>
        <WpMenuLeft v-if="obj" :distanceInMeterForOnePixel="obj.scale.distanceInMeterForOnePixel" ref="wpMenuLeftRef"
          class="mid-left" @fireRatingClick="toolClick('fireRating')" @roomClick="toolClick('room')"
          @roomRectClick="toolClick('roomRect')" @roomAutoClick="toolClick('roomAuto')" @doorClick="toolClick('doors')"
          @doorVerticalClick="toolClick('doorVertical')" @doorHorizontalClick="toolClick('doorHorizontal')"
          @stairClick="toolClick('stairExit')" @stairRectClick="toolClick('stairExitRect')"
          @stairWidthClick="toolClick('exitStairWidth')" @exitSeperationClick="toolClick('exitSeparation')"
          @diagonalDistanceClick="toolClick('diagonalDistance')" @travelDistanceClick="toolClick('travelDistance')"
          @travelDistanceAutoClick="toolClick('travelDistanceAuto')" @commonPathClick="toolClick('commonPath')"
          @deadEndClick="toolClick('deadEnd')" @obstacleClick="toolClick('obstacle')"
          @obstructionClick="toolClick('obstruction')" @obstructionRectClick="toolClick('obstructionRect')"
          @obstacleRectClick="toolClick('obstacleRect')" @obstacleAutoClick="toolClick('obstacleAuto')"
          @exitDoorClick="toolClick('exitDoor')" @exitDoorVerticalClick="toolClick('exitDoorVertical')"
          @exitDoorHorizontalClick="toolClick('exitDoorHorizontal')" @corridorEndClick="toolClick('corridorEnd')"
          @corridorEndRectClick="toolClick('corridorEndRect')" @cancelClick="toolClick('cancel')"
          @showGridUpdateClick="showGridUpdateClick" @gridSizeUpdateClick="gridSizeUpdateClick"
          @travelDistanceClearClick="travelDistanceClearClick" @obstacleClearClick="obstacleClearClick"
          @devDetectObstacleClick="dev('detectObstacle')" @devDetectWallClick="dev('detectWall')"
          @devClearBlockedDoor="dev('clearBlockedDoor')" @devDetectDoor="dev('detectDoor')"
          @wallAiClick="toolClick('wallAi')" @scaleFloorPlan="toolScaleClick" @strokeWidthUpdateClick="strokeWidthUpdateClick"
          :drawingMeta="drawingMeta">
        </WpMenuLeft>
        <!-- <WpBottomLeft class="bottom-left"></WpBottomLeft> -->
        <WpBottomMid class="bottom-mid" :scale="obj.scale" @scaleClick="toolScaleClick" @aiClick="toolAIClick">
        </WpBottomMid>
        <!-- <DrawingInfo class="bottom-right"></DrawingInfo> -->

        <WpQuickTool class="top-mid" :undoBtnDisabled="step === 0"
          :redoBtnDisabled="this.step == this.stageHistory.length - 1" @save="quickSave" @clear="quickClear"
          @undo="handlePrevStep" @redo="handleNextStep" @reload="quickReload" @run="handleRun"></WpQuickTool>
        <WpZoom :percent="parseFloat(zoomPercentage).toFixed(0)" class="bottom-right" @zoomIn="zoomIn"
          @zoomReset="zoomReset" @zoomOut="zoomOut"></WpZoom>

        <SettingsPanel :isVisible="activePanel === 'roomSettingPopup'" :title="'Room Setting'" :dataObj="roomSettingObj"
          :onDelete="onRoomSettingDelete" :onCancel="() => (activePanel = null)" :isEditable="true">
          <template v-slot:formContent>
            <CInput label="Occupancy Classification" readonly :value="getOccupantLoadFactorForDisplay(selectedOccupantLoadFactor)
              ">
            </CInput>
            <CButton size="sm" color="primary" class="mb-2 d-block ml-auto" @click="onSearchOccupantLoadFactor()">
              Search
            </CButton>
            <CInput label="Effective Percentage" :value.sync="roomSettingObj.effectivePercentage" type="number" :max="100"
              :min="0" step="0.01">
            </CInput>
            <CustomTable v-if="obj.doors.length" title="Doors" :items="computedRoomDoorList" :fields="roomDoorFieldList"
              @show_remove="onRemoveRoomDoor" @add="onAddRoomDoor" />

            <CustomTable v-if="obj.rooms.length > 1" title="Subroom" :items="computedSubRoomList"
              :fields="subRoomFieldList" @show_remove="onRemoveSubRoom" @add="onAddSubRoom" />

            <!-- <CustomTable
              v-if="travelDistances.length"
              title="Travel Distance"
              :items="computedRoomTravelDistanceList"
              :fields="roomTravelDistanceFieldList"
              @show_remove="onRemoveRoomDoor"
              @add="onAddTravelDistance"
            />

            <CustomTable
              v-if="commonPaths.length"
              title="Common Path"
              :items="computedRoomCommonPathList"
              :fields="roomCommonPathFieldList"
              @show_remove="onRemoveRoomDoor"
              @add="onAddCommonPath"
            /> -->

            <div>
              <div :style="{ marginBottom: '0.5rem' }" class="table-headaer">
                Diagonal
              </div>
              <div>
                <CDataTable :items="computedRoomDiagonalList" :fields="roomDiagonalFieldList" striped
                  caption="Striped Table" :loading="loading">
                </CDataTable>
              </div>
            </div>
          </template>

          <template v-slot:buttons>
            <CButton color="primary" size="sm" @click="onRoomSettingConfirmation(roomSettingObj)">OK</CButton>
          </template>
        </SettingsPanel>

        <SettingsPanel :isVisible="activePanel === 'doorSettingPopup'" :title="'Room Door Setting'"
          :dataObj="doorSettingObj" :onDelete="onDoorSettingDelete" :onCancel="() => (activePanel = null)"
          :isEditable="true">
          <template v-slot:formContent>
            <CForm>
              <div class="btn-grp">
                <div class="btn-grp-label">Door Options</div>
                <CButtonGroup size="sm">
                  <CButton :value.sync="doorSettingObj.isRoomDoor"
                    :color="doorSettingObj.isRoomDoor ? 'primary' : 'secondary'" :active="doorSettingObj.isRoomDoor"
                    @click="doorSettingObj.isRoomDoor = !doorSettingObj.isRoomDoor">
                    Room Door
                  </CButton>
                  <CButton :value.sync="doorSettingObj.isFinalExitDoor"
                    :color="doorSettingObj.isFinalExitDoor ? 'primary' : 'secondary'"
                    :active="doorSettingObj.isFinalExitDoor"
                    @click="doorSettingObj.isFinalExitDoor = !doorSettingObj.isFinalExitDoor">
                    Final Exit Door
                  </CButton>
                  <CButton :value.sync="doorSettingObj.isUnitDoor"
                    :color="doorSettingObj.isUnitDoor ? 'primary' : 'secondary'" :active="doorSettingObj.isUnitDoor"
                    @click="doorSettingObj.isUnitDoor = !doorSettingObj.isUnitDoor">
                    Unit Door
                  </CButton>
                </CButtonGroup>
              </div>
              <CSelect label="Door Type" :options="doorTypeOptionsList" :value.sync="doorSettingObj.doorType">
              </CSelect>
              <!-- <CInput
                label="Distance (m)"
                :value="doorSettingObj.distance"
                readonly
              /> -->
              <!-- <CInput label="Width (in meters)" :value.sync="doorSettingObj.width" type="number" />
              <CInput label="Height (in meters)" :value.sync="doorSettingObj.height = calculateDoorHeight" readonly /> -->
              <!-- <CInput
                label="Rotation"
                :value.sync="computedDoorRotation"
                @input="value => doorSettingObj.rotation = value"
                type="number" 
                max="360"
              /> -->
              <CInput label="Location 1 (px)" :value="getCoordinateFirst(doorSettingObj)" readonly />
              <CInput label="Location 2 (px)" :value="getCoordinateSecond(doorSettingObj)" readonly />
              <!-- <CInput
                label="Center Between 1 and 2"
                :value="getCenterPositionForDisplay(doorSettingObj)"
                readonly
              /> -->
              <CRow form class="form-group">
                <CCol tag="label" sm="12" class="col-form-label">Accessible</CCol>
                <CCol sm="12">
                  <CSwitch class="mr-1" color="primary" :checked.sync="doorSettingObj.isAccessible" label-on="YES"
                    label-off="NO" />
                </CCol>
              </CRow>
            </CForm>
          </template>

          <!-- Buttons for Room Setting -->
          <template v-slot:buttons>
            <CButton color="primary" size="sm" @click="onDoorSettingConfirmation">OK</CButton>
          </template>
        </SettingsPanel>

        <SettingsPanel :isVisible="activePanel === 'exitDoorSetting'" :title="'Exit Door Setting'"
          :dataObj="exitDoorSettingObj" :onDelete="onExitDoorSettingDelete" :onCancel="() => (activePanel = null)" />

        <SettingsPanel :isVisible="activePanel === 'exitAreaSettingPopup'" :title="'Exit Area Setting'"
          :dataObj="exitAreaSettingObj" :onDelete="onExitAreaSettingDelete" :onCancel="() => (activePanel = null)" />

        <SettingsPanel :isVisible="activePanel === 'exitStairWidthSettingPopup'" :title="'Exit Stair Setting'"
          :dataObj="exitStairWidthSettingObj" :onDelete="onExitStairWidthSettingDelete"
          :onCancel="() => (activePanel = null)" />

        <SettingsPanel :isVisible="activePanel === 'commonPathSettingPopup'" :title="'Common Path Setting'"
          :dataObj="commonPathSettingObj" :onDelete="onCommonPathSettingDelete" :onCancel="() => (activePanel = null)">
          <template v-slot:formContent>
            <CSelect label="Room" :options="roomListOptions" :value.sync="commonPathSettingObj.roomId" />
            <CRow form class="form-group">
              <CCol tag="label" sm="9" class="col-form-label">Path To SOU</CCol>
              <CCol sm="3">
                <CSwitch class="mr-1" color="primary" :checked.sync="commonPathSettingObj.sou" label-on="YES"
                  label-off="NO" />
              </CCol>
            </CRow>
          </template>

          <template v-slot:buttons>
            <CButton color="primary" size="sm" @click="onCommonPathSettingConfirmation">OK</CButton>
          </template>
        </SettingsPanel>

        <SettingsPanel :isVisible="activePanel === 'deadEndSettingPopup'" :title="'Dead End Setting'"
          :dataObj="deadEndSettingObj" :onDelete="onDeadEndSettingDelete" :onCancel="() => (activePanel = null)">
          <template v-slot:formContent>
            <CSelect label="Corridor End" :options="corridorEndListOptions"
              :value.sync="deadEndSettingObj.corridorEndId" />
          </template>

          <template v-slot:buttons>
            <CButton color="primary" size="sm" @click="onDeadEndSettingConfirmation">OK</CButton>
          </template>
        </SettingsPanel>

        <SettingsPanel :isVisible="activePanel === 'corridorEndSettingPopup'" :title="'Corridor End Setting'"
          :dataObj="corridorEndSettingObj" :onDelete="onCorridorEndSettingDelete"
          :onCancel="() => (activePanel = null)" />

        <SettingsPanel :isVisible="activePanel === 'travelDistanceSettingPopup'" :title="'Travel Distance Setting'"
          :dataObj="travelDistanceSettingObj" :onDelete="onTravelDistanceSettingDelete"
          :onCancel="() => (activePanel = null)">
          <template v-slot:formContent>
            <CSelect label="Room" :options="roomListOptions" :value.sync="travelDistanceSettingObj.roomId" />
          </template>

          <template v-slot:buttons>
            <CButton color="primary" size="sm" @click="onTravelDistanceSettingConfirmation">OK</CButton>
          </template>
        </SettingsPanel>

        <SettingsPanel :isVisible="activePanel === 'obstacleSettingPopup'" :title="'Obstacle/Wall Setting'"
          :dataObj="obstacleSettingObj" :onDelete="onObstacleSettingDelete" :onCancel="() => (activePanel = null)" />

        <SettingsPanel :isVisible="activePanel === 'aiConfirmationModal'" :title="'AI Mode'" :dataObj="null"
          :onDelete="null" :onCancel="null">
          <template v-slot:formContent>
            <CRow>
              <CCol sm="12">
                <p v-if="!obj.isKreoAIReady">
                  Are you sure to apply AI mode? Current changes in manual mode
                  will be overwrite.
                </p>
                <p v-if="obj.isKreoAIReady">
                  Please adjust the scale slightly to ensure an accurate fit.
                  Begin with a small adjustment, for example, 1.1, and gradually
                  increase until you achieve the perfect fit for the image.
                </p>
              </CCol>
            </CRow>
            <CForm sm="12" v-if="obj.isKreoAIReady">
              <!-- <CInput label="X-Scale" v-model="kreoScale.x" type="number"/> -->
              <CInput label="X-Scale" v-model="kreoScale.x" type="number" step="0.1" min="1" :disabled="resizeLoading"
                @change="handleKreoInputChange" />
              <CInput label="Y-Scale" disabled v-model="kreoScale.x" />
            </CForm>
          </template>

          <template v-slot:buttons>
            <CButton class="ml-1 mr-1 float-right" color="secondary" @click="onCancelAIConfirmation"
              :disabled="resizeLoading">
              Close
            </CButton>
            <CButton class="ml-1 mr-1 float-right" color="primary" @click="onAiConfirmation" :disabled="resizeLoading">
              OK
            </CButton>
          </template>
        </SettingsPanel>

        <div class="ai-overlay" v-if="resizeLoading">
          <svg version="1.1" id="L6" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px"
            y="0px" viewBox="0 0 100 100" enable-background="new 0 0 100 100" xml:space="preserve">
            <rect fill="none" stroke="#fff" stroke-width="4" x="25" y="25" width="50" height="50">
              <animateTransform attributeName="transform" dur="0.5s" from="0 50 50" to="180 50 50" type="rotate"
                id="strokeBox" attributeType="XML" begin="rectBox.end" />
            </rect>
            <rect x="27" y="27" fill="#fff" width="46" height="50">
              <animate attributeName="height" dur="1.3s" attributeType="XML" from="50" to="0" id="rectBox" fill="freeze"
                begin="0s;strokeBox.end" />
            </rect>
          </svg>
          <h1>{{ currentLoadingMessage }}</h1>
        </div>

        <div v-if="!pageLoading" v-bind:style="{ height: '100vh', width: '100vw' }" ref="container">
          <v-stage ref="stage" :config="stageSize" :style="{
            top: 0,
            //   backgroundColor: 'gray',
            //   borderWidth: '2px',
            //   borderStyle: 'solid',
            //   borderColor: 'blue',
            overflow: 'hidden',
          }" @click="handleStageMouseClick" @contextmenu="onContextMenu" @mousedown="handleStageMouseDown"
            @mouseup="handleStageMouseUp" @mousemove="handleStageMouseMove">
            <v-layer @wheel="onZoomEvent($event)">
              <!-- <v-circle :config="configCircle"></v-circle> -->
              <v-image ref="image" :config="imageConfig" />
              <template v-for="(item, index) in obj.rooms">
                <v-line :key="item.code + '-' + index" :config="item" @mousemove="onMouseMove(item)"
                  @mouseout="onMouseOut" @click="roomLabelClick(item, index)" />
                <v-circle v-for="anchor in getAnchors(item)" @dragmove="updatePoly" :key="anchor.code + '-' + index"
                  :config="{
                    roomId: anchor.roomId,
                    roomCode: anchor.roomCode,
                    pointFirstIndex: anchor.pointFirstIndex,
                    code: anchor.code,
                    x: anchor.x,
                    y: anchor.y,
                    radius: 4,
                    fill: 'white',
                    stroke: 'black',
                    draggable: true,
                  }" />

                <v-label :key="item.code + '_label_' + index" :config="{
                  x: getCenterOfShape(item.points)[0], // item.points[0],
                  y: getCenterOfShape(item.points)[1], //item.points[1],
                }" @click="roomLabelClick(item, index)">
                  <v-text :config="{
                    text: getRoomInfo(item),
                    fill: 'green',
                  }" />
                </v-label>
              </template>

              <template v-if="scaleLinePreview">
                <v-line :key="obj.scale.code" :config="obj.scale" />
              </template>

              <template v-for="(item, index) in obstacles">
                <v-line :key="item.code + '-' + index" :config="item" @click="obstacleLabelClick(item)" />
                <!-- <v-label
                          :key="item.code + 'label'"
                          :config="{
                            x: item.points[0],
                            y: item.points[1],
                          }"
                          @click="obstacleSettingClick(item)"
                        >
                          <v-text
                            :config="{
                              text: getRoomInfo(item),
                              fill: 'green',
                              padding: 5,
                            }"
                          />
                        </v-label> -->
              </template>
              <template v-for="(item, index) in corridorEnds">
                <v-line :key="item.code + '-' + index" :config="item" @click="corridorEndLabelClick(item)" />
                <!-- <v-label
                          :key="item.code + 'label'"
                          :config="{
                            x: item.points[0],
                            y: item.points[1],
                          }"
                          @click="obstacleSettingClick(item)"
                        >
                          <v-text
                            :config="{
                              text: getRoomInfo(item),
                              fill: 'green',
                              padding: 5,
                            }"
                          />
                        </v-label> -->
              </template>
              <template v-if="doorPreviewElement">
                <v-label :draggable="true" :key="doorPreviewElement.code" :config="doorPreviewElement">
                  <v-image :key="doorPreviewElement.code + '-icon'" :config="{
                    image: doorPreviewElement.image,
                    width: doorPreviewElement.width,
                    height: doorPreviewElement.height,
                    fill: 'rgb(0 0 0 / 30%)',
                  }" />
                </v-label>
              </template>
              <template v-for="(item, index) in obj.doors">
                <!-- <v-line :key="item.code + '-' + index" :config="item" /> -->
                <v-label :draggable="true" :key="item.code + '-' + index" :config="item" @click="doorLabelClick(item)"
                  @dragmove="updateDoorPositionOnMouseMove(item, $event)"
                  @dragend="updateDoorPositionOnDragStop(item, $event)">
                  <v-image :key="item.code + '-icon-' + index" :config="{
                    image: item.image,
                    width: item.width,
                    height: item.height,
                    fill: 'rgb(0 0 0 / 30%)',
                  }" />
                  <v-image :key="item.code + '-fr-' + index" :config="{
                    image: item.fireRatingDoorImg,
                    width: item.width / 2,
                    height: item.width / 2,
                  }" />
                  <v-text :config="{
                    text: getLineInfo(item),
                    fill: 'green',
                    padding: 5,
                  }" />
                </v-label>
              </template>

              <!-- <v-line
                      v-for="(item, index) in exitStairAreas"
                      :key="item.code + '-' + index"
                      :config="item"
                    /> -->

              <template v-for="(item, index) in exitStairAreas">
                <v-line :key="item.code + '-' + index" :config="item" />
                <v-label :key="item.code + 'label'" :config="{
                  x: item.points[0],
                  y: item.points[1],
                }" @click="exitAreaLabelClick(item)">
                  <v-text :config="{
                    text: getRoomInfo(item),
                    fill: 'green',
                    padding: 5,
                  }" />
                </v-label>
              </template>

              <!-- <v-line
                      v-for="(item, index) in exitStairWidths"
                      :key="item.code + '-' + index"
                      :config="item"
                    /> -->
              <template v-for="(item, index) in exitStairWidths">
                <v-line :key="item.code + '-' + index" :config="item" />
                <v-label :key="item.code + 'label'" :config="{
                  x: item.points[0],
                  y: item.points[1],
                }" @click="exitStairWidthLabelClick(item)">
                  <v-text :config="{
                    text: getLineInfo(item),
                    fill: 'green',
                    padding: 5,
                  }" />
                </v-label>
              </template>

              <template v-for="(item, index) in exitStairDoors">
                <v-label :key="item.code + '_' + index + '_label'" :config="{
                  x: item.posX ?? item.points[0],
                  y: item.posY ?? item.points[1],
                }">
                  <v-image :key="item.code + '-icon-' + index" :config="{
                    image: exitDoorImg,
                    width: item.width,
                    height: item.height,
                    rotation: item.rotation,
                  }" @click="exitDoorSettingClick(item)" />
                  <v-text :config="{
                    text: getLineInfo(item),
                    fill: 'green',
                    padding: 5,
                  }" />
                </v-label>
              </template>

              <!-- <v-line
                      v-for="(item, index) in exitSeperations"
                      :key="item.code + '-' + index"
                      :config="item"
                    /> -->

              <!-- <template v-for="(item, index) in exitSeperations">
              <v-line :key="item.code + '-' + index" :config="item" />
              <v-label
                :key="item.code + 'label'"
                :config="{
                  x: item.points[0],
                  y: item.points[1],
                }"
                @click="exitSeperationLabelClick(item)"
              >
                <v-text
                  :config="{
                    text: getLineInfo(item),
                    fill: 'green',
                    padding: 5,
                  }"
                />
              </v-label>
            </template> -->

              <!-- <v-line
                        v-for="(item, index) in roomDiagonalDistances"
                        :key="item.code + '-' + index"
                        :config="item"
                      /> -->

              <!-- <template v-for="(item, index) in diagonalDistances">
              <v-line
                :key="item.code + '-' + index"
                :config="item"
                @click="diagonalDistanceLabelClick(item)"
                @mousemove="onMouseMove(item)"
                @mouseout="onMouseOut"
              />
            </template> -->

              <!-- <v-line
                      v-for="(item, index) in travelDistances"
                      :key="item.code + '-' + index"
                      :config="item"
                    /> -->
              <template v-for="(item, index) in travelDistances">
                <v-arrow v-if="!item.isHidden" :key="item.code + '-' + index" :config="item"
                  @click="travelDistanceLabelClick(item)" @mousemove="onMouseMoveTravelDistance(item)"
                  @mouseout="onMouseOut" />
                <v-circle v-for="anchor in getLineAnchors(item)" v-if="!item.isHidden"
                  @dragmove="updatePolyLine('travelDistances', $event)" :key="anchor.code + '_anchor_' + index" :config="{
                    lineId: anchor.lineId,
                    lineCode: anchor.lineCode,
                    pointFirstIndex: anchor.pointFirstIndex,
                    code: anchor.code,
                    x: anchor.x,
                    y: anchor.y,
                    radius: 2,
                    fill: 'white',
                    stroke: 'black',
                    draggable: true,
                  }" />
                <v-label v-if="!item.isHidden" :key="item.code + '_label_' + index" :config="{
                  x: getMiddlePoint(item.points).x,
                  y: getMiddlePoint(item.points).y,
                }" @click="travelDistanceLabelClick(item)">
                  <v-text :config="{
                    text: getLineInfo(item),
                    fill: item.stroke,
                    //padding: 5,
                  }" />
                </v-label>
              </template>

              <!-- <v-line
                      v-for="(item, index) in commonPaths"
                      :key="item.code + '-' + index"
                      :config="item"
                    /> -->
              <template v-for="(item, index) in commonPaths">
                <v-arrow v-if="!item.isHidden" :key="item.code + '-' + index" :config="item"
                  @mousemove="onMouseMoveTravelDistance(item)" @mouseout="onMouseOut" />
                <v-circle v-for="anchor in getLineAnchors(item)" v-if="!item.isHidden"
                  @dragmove="updatePolyLine('commonPaths', $event)" :key="anchor.code" :config="{
                    lineId: anchor.lineId,
                    lineCode: anchor.lineCode,
                    pointFirstIndex: anchor.pointFirstIndex,
                    code: anchor.code,
                    x: anchor.x,
                    y: anchor.y,
                    radius: 4,
                    fill: 'purple',
                    stroke: 'black',
                    draggable: true,
                  }" />
                <v-label v-if="!item.isHidden" :key="item.code + 'label'" :config="{
                  x: getMiddlePoint(item.points).x,
                  y: getMiddlePoint(item.points).y,
                }" @click="commonPathLabelClick(item)">
                  <v-text :config="{
                    text: getLineInfo(item),
                    fill: item.stroke,
                  }" />
                </v-label>
              </template>

              <template v-for="(item, index) in fireRating">
                <v-line :key="item.code + '-' + index" :config="item" @mousemove="onMouseMoveTravelDistance(item)"
                  @mouseout="onMouseOut" />
                <v-label :key="item.code + 'label'" :config="{
                  x: getMiddlePoint(item.points).x,
                  y: getMiddlePoint(item.points).y,
                }" @click="commonPathLabelClick(item)">
                  <v-text :config="{
                    text: getLineInfo(item),
                    fill: item.stroke,
                    //padding: 5,
                  }" />
                </v-label>
              </template>

              <template v-for="(item, index) in deadEnds">
                <v-arrow v-if="!item.isHidden" :key="item.code + '-' + index" :config="item"
                  @mousemove="onMouseMoveTravelDistance(item)" @mouseout="onMouseOut" />
                <v-circle v-for="anchor in getLineAnchors(item)" v-if="!item.isHidden"
                  @dragmove="updatePolyLine('deadEnds', $event)" :key="anchor.code" :config="{
                    lineId: anchor.lineId,
                    lineCode: anchor.lineCode,
                    pointFirstIndex: anchor.pointFirstIndex,
                    code: anchor.code,
                    x: anchor.x,
                    y: anchor.y,
                    radius: 4,
                    fill: 'blue',
                    stroke: 'blue',
                    draggable: true,
                  }" />
                <v-label v-if="!item.isHidden" :key="item.code + 'label'" :config="{
                  x: getMiddlePoint(item.points).x,
                  y: getMiddlePoint(item.points).y,
                }" @click="deadEndLabelClick(item)">
                  <v-text :config="{
                    text: getLineInfo(item),
                    fill: 'black',
                    padding: 5,
                  }" />
                </v-label>
              </template>
            </v-layer>
            <v-layer ref="toolTipLayer" v-if="this.toolTip.visible">
              <v-text ref="toolTip" :config="{
                x: this.toolTip.x,
                y: this.toolTip.y,
                text: this.toolTip.text,
                fontFamily: 'Calibri',
                fontSize: 12,
                padding: 5,
                textFill: 'white',
                fill: 'black',
                alpha: 0.75,
                visible: this.toolTip.visible,
              }" />
            </v-layer>
            <div>
              <v-layer ref="pathFinderLayer">
                <div v-if="showTravelDistancePage">
                  <v-circle v-for="item in walls" :key="item.id" :config="{
                    id: item.id,
                    x: item.x,
                    y: item.y,
                    radius: 1,
                    fill: 'white',
                    stroke: 'white',
                    draggable: false,
                  }" />
                  <!-- Temp -->
                    <!-- <template v-for="rows in grid">
                      <v-rect v-for="(item, index) in rows" :key="item.code + '-' + index" :config="item"
                        @click="gridClick(item)" />
                    </template> -->
                    <!-- Temp comment -->
                    <v-rect
                      v-for="i in horizontalGridLines"
                      :key="`hline-${i}`"
                      :config="{
                        x: i * cellSize,
                        y: 0,
                        width: 1,
                        height: originalStageSize.height,
                        fill: '#000',
                        strokeWidth: 1
                      }"
                    />
                    <v-rect
                      v-for="j in verticalGridLines"
                      :key="`vline-${j}`"
                      :config="{
                        x: 0,
                        y: j * cellSize,
                        width: originalStageSize.width,
                        height: 1,
                        fill: '#000',
                        strokeWidth: 0.5
                      }"
                    />

                    <v-rect 
                      v-for="(item, index) in markedGrid"
                      :key="item.name + '_' + index"
                      :config="item"
                    />
                  <v-circle v-for="item in centerDoorPositions" :key="item.id" :config="{
                    id: item.id,
                    x: item.x,
                    y: item.y,
                    radius: 2,
                    fill: 'red',
                    stroke: 'red',
                  }" />
                  <v-circle v-for="item in roomStartPosition" :key="item.id" :config="{
                    id: item.id,
                    x: item.x,
                    y: item.y,
                    radius: 2,
                    fill: 'green',
                    stroke: item.stroke,
                    draggable: true,
                  }" />
                  <v-circle v-for="item in deadEndStartPosition" :key="item.id" :config="{
                    id: item.id,
                    x: item.x,
                    y: item.y,
                    radius: 2,
                    fill: 'blue',
                    stroke: 'blue',
                    draggable: false,
                  }" />
                </div>
              </v-layer>
            </div>
          </v-stage>
        </div>
      </div>
      <div>
        <CModal title="Scale Setting" :show.sync="scaleSettingPopup" size="sm" :closeOnBackdrop="false"
          :show-close="false" class="scale-modal">
          <CRow>
            <CCol sm="12">
              <CForm>
                <CInput label="Scale" description="Please set the scale in meter" horizontal v-model="newScale" />
              </CForm>
            </CCol>
          </CRow>

          <div slot="footer" class="w-100">
            <CButton class="ml-1 mr-1 float-right" color="primary" @click="onScaleSettingConfirmation">
              OK
            </CButton>
            <CButton class="ml-1 mr-1 float-right" color="secondary" @click="onScaleSettingCancel">
              Cancel
            </CButton>
          </div>
        </CModal>
      </div>
      <div>
        <CModal title="Clear Drawings" :show.sync="clearDrawingsConfirmationModal">
          <CRow>
            <CCol sm="12">
              <p>
                This action is irreversible. Are you absolutely sure you want to
                clear drawings?
              </p>
            </CCol>
          </CRow>

          <div slot="footer" class="w-100">
            <CButton class="ml-1 mr-1 float-right" color="primary" @click="onClearDrawings">
              OK
            </CButton>
            <CButton class="ml-1 mr-1 float-right" color="secondary" @click="clearDrawingsConfirmationModal = false">
              Cancel
            </CButton>
          </div>
        </CModal>
      </div>

      <div>
        <CModal title="Exit Separation Setting" :show.sync="exitSeperationSettingPopup" size="xl">
          <CRow>
            <CCol sm="12">
              <CForm>
                <!-- <CInput label="Id" v-model="obj.id" horizontal plaintext /> -->
                <CInput label="Code" horizontal v-model="exitSeperationSettingObj.code" readonly />
              </CForm>
            </CCol>
          </CRow>
          <div slot="footer" class="w-100">
            <CButton class="ml-1 mr-1 float-right" color="primary" @click="onExitSeperationSettingConfirmation">
              OK
            </CButton>
            <CButton class="ml-1 mr-1 float-right" color="danger" @click="onExitSeperationSettingDelete">
              Delete
            </CButton>
            <CButton class="ml-1 mr-1 float-right" color="secondary" @click="exitSeperationSettingPopup = false">
              Cancel
            </CButton>
          </div>
        </CModal>
      </div>

      <div>
        <CModal title="Diagonal Distance Setting" :show.sync="diagonalDistanceSettingPopup" size="xl">
          <CRow>
            <CCol sm="12">
              <CForm>
                <!-- <CInput label="Id" v-model="obj.id" horizontal plaintext /> -->
                <CInput label="Code" horizontal v-model="diagonalDistanceSettingObj.code" readonly />
              </CForm>
            </CCol>
          </CRow>
          <div slot="footer" class="w-100">
            <CButton class="ml-1 mr-1 float-right" color="primary" @click="onDiagonalDistanceSettingConfirmation">
              OK
            </CButton>
            <CButton class="ml-1 mr-1 float-right" color="danger" @click="onDiagonalDistanceSettingDelete">
              Delete
            </CButton>
            <CButton class="ml-1 mr-1 float-right" color="secondary" @click="diagonalDistanceSettingPopup = false">
              Cancel
            </CButton>
          </div>
        </CModal>
      </div>

      <div>
        <CModal :show.sync="occupantLoadFactorSearchPopup" size="xl">
          <template #header>
            <h6 class="modal-title">Select Occupant Load Factor</h6>
          </template>
          <template #footer>
            <CButton @click="occupantLoadFactorSearchPopup = false" color="secondary">Close</CButton>
            <CButton @click="addNewOccupantLoadFactor()" color="primary">Add Custom Occupant Load</CButton>
          </template>

          <CRow>
            <CCol sm="12">
              <div class="olf-ft-switch">
                <CSwitch color="primary" shape="pill" label="Default switch checkbox input" :checked.sync="olfIsFt" />
                Switch to {{ olfIsFt ? "m2/person" : "ft" }}
              </div>
              <CDataTable :items="computedOccupantLoadFactorList" :fields="computedOccupantLoadFactorFieldList"
                column-filter items-per-page-select :items-per-page="10" hover sorter pagination :loading="loading">
                <template #show_details="{ item, index }">
                  <td class="py-2">
                    <CButton color="primary" variant="outline" square size="sm"
                      @click="onOccupantLoadFactorSelected(item, index)"
                      :disabled="((obj.buildingCode && obj.buildingCode.code) !== item.buildingcode.code)">
                      Select
                    </CButton>
                  </td>
                </template>
              </CDataTable>
            </CCol>
          </CRow>

          <CModal :show.sync="insertGlaModal" size="sm" centered>
            <template #header>
              <h6 class="modal-title">Insert GLA</h6>
            </template>
            <template #footer>
              <CButton @click="insertGlaModal = false" color="secondary">Close</CButton>
              <CButton @click="handleGlaValue()" color="primary">OK</CButton>
            </template>

            <CRow>
              <CCol sm="12">
                <CInput label="GLA Value" placeholder="Please insert GLA value" v-model="obj.glaValue" type="number" />
              </CCol>
            </CRow>
          </CModal>
        </CModal>
      </div>

      <div>
        <CModal :show.sync="addNewOccupantLoadFactorPopup" size="lg">
          <template #header>
            <h6 class="modal-title">New Occupant Load Factor</h6>
          </template>
          <template #footer>
            <CButton @click="onNewOccupantLoadFactorConfirmation()" color="primary">OK</CButton>
            <CButton @click="addNewOccupantLoadFactorPopup = false" color="secondary">Cancel</CButton>
          </template>

          <CRow>
            <CCol sm="12">
              <CForm>
                <!-- <CInput
                  label="Building Code"
                  horizontal
                  readonly
                  v-model="selectedBuildingCode.name"
                >
                  <template #append>
                    <CButton color="primary" @click="onSearchBuildingCode()">
                      Search
                    </CButton>
                  </template>
                </CInput> -->
                <CInput label="Main Category" horizontal readonly v-model="selectedMainCategory.name">
                  <template #append>
                    <CButton color="primary" @click="onSearchMainCategory()">
                      Search
                    </CButton>
                  </template>
                </CInput>

                <CInput label="Sub Category" horizontal readonly v-model="selectedSubCategory.name">
                  <template #append>
                    <CButton color="primary" @click="onSearchSubCategory()">
                      Search
                    </CButton>
                  </template>
                </CInput>

                <CInput label="Number of occupants" horizontal v-model="newOccupantLoadFactorObj.olfValue" />

                <!-- <CInput
                  label="Area"
                  horizontal
                  readonly
                  v-model="selectedOlfArea.name"
                >
                  <template #append>
                    <CButton color="primary" @click="onSearchOlfArea()">
                      Search
                    </CButton>
                  </template>
                </CInput> -->
              </CForm>
            </CCol>
          </CRow>
        </CModal>
      </div>

      <div>
        <CModal :show.sync="mainCategorySearchPopup" size="xl">
          <template #header>
            <h6 class="modal-title">Select Main Category</h6>
          </template>
          <template #footer>
            <CButton @click="mainCategorySearchPopup = false" color="secondary">Close</CButton>
            <CButton @click="addNewMainCategory()" color="primary">Add New</CButton>
            <!-- <CButton @click="darkModal = false" color="success">Accept</CButton> -->
          </template>

          <CRow>
            <CCol sm="12">
              <CDataTable :items="mainCategoryList" :fields="mainCategoryFieldList" column-filter items-per-page-select
                :items-per-page="10" hover sorter pagination :loading="loading">
                <template #show_details="{ item, index }">
                  <td class="py-2">
                    <CButton color="primary" variant="outline" square @click="onMainCategorySelected(item, index)">
                      Select
                    </CButton>
                  </td>
                </template>
              </CDataTable>
            </CCol>
          </CRow>
        </CModal>
      </div>

      <div>
        <CModal :show.sync="subCategorySearchPopup" size="xl">
          <template #header>
            <h6 class="modal-title">Select Sub Category</h6>
          </template>
          <template #footer>
            <CButton @click="subCategorySearchPopup = false" color="secondary">Close</CButton>
            <CButton @click="addNewSubCategory()" color="primary">Add New</CButton>
            <!-- <CButton @click="darkModal = false" color="success">Accept</CButton> -->
          </template>

          <CRow>
            <CCol sm="12">
              <CDataTable :items="subCategoryList" :fields="subCategoryFieldList" column-filter items-per-page-select
                :items-per-page="10" hover sorter pagination :loading="loading">
                <template #show_details="{ item, index }">
                  <td class="py-2">
                    <CButton color="primary" variant="outline" square @click="onSubCategorySelected(item, index)">
                      Select
                    </CButton>
                  </td>
                </template>
              </CDataTable>
            </CCol>
          </CRow>
        </CModal>
      </div>

      <div>
        <CModal :show.sync="olfAreaSearchPopup" size="xl">
          <template #header>
            <h6 class="modal-title">Select Areas</h6>
          </template>
          <template #footer>
            <CButton @click="olfAreaSearchPopup = false" color="secondary">Close</CButton>
          </template>

          <CRow>
            <CCol sm="12">
              <CDataTable :items="olfAreaList" :fields="olfAreaFieldList" column-filter items-per-page-select
                :items-per-page="10" hover sorter pagination :loading="loading">
                <template #show_details="{ item, index }">
                  <td class="py-2">
                    <CButton color="primary" variant="outline" square @click="onOlfAreaSelected(item, index)">
                      Select
                    </CButton>
                  </td>
                </template>
              </CDataTable>
            </CCol>
          </CRow>
        </CModal>
      </div>

      <div>
        <CModal :show.sync="buildingCodeSearchPopup" size="xl">
          <template #header>
            <h6 class="modal-title">Select Building Code</h6>
            <!-- <CButtonClose @click="darkModal = false" class="text-white" /> -->
          </template>
          <template #footer>
            <CButton @click="buildingCodeSearchPopup = false" color="secondary">Close</CButton>
            <CButton @click="addNewBuildingCode()" color="primary">Add New</CButton>
            <!-- <CButton @click="darkModal = false" color="success">Accept</CButton> -->
          </template>

          <CRow>
            <CCol sm="12">
              <CDataTable :items="buildingCodeList" :fields="buildingCodeFieldList" column-filter items-per-page-select
                :items-per-page="10" hover sorter pagination :loading="loading">
                <template #show_details="{ item, index }">
                  <td class="py-2">
                    <CButton color="primary" variant="outline" square @click="onBuildingCodeSelected(item, index)">
                      Select
                    </CButton>
                  </td>
                </template>
              </CDataTable>
            </CCol>
          </CRow>
        </CModal>
      </div>

      <div>
        <CModal title="Add New Building Code" :show.sync="addNewBuildingCodePopup">
          <CForm>
            <CInput label="Code" horizontal v-model="newBuildingCodeObj.code" />
            <CInput label="Name" horizontal v-model="newBuildingCodeObj.name" />
            <CInput label="Description" horizontal v-model="newBuildingCodeObj.description" />
          </CForm>
          <div slot="footer" class="w-100">
            <CButton class="ml-1 mr-1 float-right" color="primary" @click="onNewBuildingCodeConfirmation">
              OK
            </CButton>
            <CButton class="ml-1 mr-1 float-right" color="secondary" @click="addNewBuildingCodePopup = false">
              Cancel
            </CButton>
          </div>
        </CModal>
      </div>

      <div>
        <CModal title="Add New Main Category" :show.sync="addNewMainCategoryPopup">
          <CForm>
            <CInput label="Code" horizontal v-model="newMainCategoryObj.code" />
            <CInput label="Name" horizontal v-model="newMainCategoryObj.name" />
            <CInput label="Description" horizontal v-model="newMainCategoryObj.description" />
          </CForm>
          <div slot="footer" class="w-100">
            <CButton class="ml-1 mr-1 float-right" color="primary" @click="onNewMainCategoryConfirmation">
              OK
            </CButton>
            <CButton class="ml-1 mr-1 float-right" color="secondary" @click="addNewMainCategoryPopup = false">
              Cancel
            </CButton>
          </div>
        </CModal>
      </div>

      <div>
        <CModal title="Add New Sub Category" :show.sync="addNewSubCategoryPopup">
          <CForm>
            <CInput label="Code" horizontal v-model="newSubCategoryObj.code" />
            <CInput label="Name" horizontal v-model="newSubCategoryObj.name" />
            <CInput label="Description" horizontal v-model="newSubCategoryObj.description" />
          </CForm>
          <div slot="footer" class="w-100">
            <CButton class="ml-1 mr-1 float-right" color="primary" @click="onNewSubCategoryConfirmation">
              OK
            </CButton>
            <CButton class="ml-1 mr-1 float-right" color="secondary" @click="addNewSubCategoryPopup = false">
              Cancel
            </CButton>
          </div>
        </CModal>
      </div>

      <SettingsPanel :isVisible="activePanel === 'roomDoorSearchPopup'" :title="'Add Doors'" :dataObj="roomSettingObj"
        :onDelete="null" :onCancel="() => (activePanel = null)">
        <template v-slot:formContent>
          <CDataTable :items="computedDoorList" :fields="doorFieldList" column-filter :items-per-page="5" border hover
            sorter pagination :loading="loading" clickable-rows @row-clicked="doorRowClicked">
            <template #select="{ item }">
              <td>
                <CInputCheckbox :checked="item._selected" @update:checked="() => selectDoor(item)" custom />
              </td>
            </template>
          </CDataTable>
        </template>

        <template v-slot:buttons>
          <CButton color="primary" size="sm" @click="onDoorConfirmation">OK</CButton>
        </template>
      </SettingsPanel>

      <SettingsPanel :isVisible="activePanel === 'roomSearchPopup'" :title="'Add Sub Rooms'" :dataObj="roomSettingObj"
        :onDelete="null" :onCancel="() => (activePanel = null)">
        <template v-slot:formContent>
          <CDataTable :items="computedRoomList" :fields="roomFieldList" column-filter :items-per-page="5" hover sorter
            pagination :loading="loading" clickable-rows @row-clicked="roomRowClicked">
            <template #select="{ item }">
              <td>
                <CInputCheckbox :checked="item._selected" @update:checked="() => selectRoom(item)" custom />
              </td>
            </template>
          </CDataTable>
        </template>

        <template v-slot:buttons>
          <CButton color="primary" size="sm" @click="onRoomConfirmation">OK</CButton>
        </template>
      </SettingsPanel>
    </div>

    <div>
      <CModal title="Generating Path" :show.sync="tdAutoModal" size="lg" :closeOnBackdrop="false" centered
        :show-close="false" class="scale-modal">
        <CProgress :value="tdAutoProgress" show-percentage animated>
        </CProgress>
        <br>
        <div class="elapsed-time-info">
          <p :class="{
            'text-success': tdAutoMessage.type === 'success',
            'text-danger': tdAutoMessage.type === 'danger'
          }">
            {{ tdAutoMessage.message }}
            <br>
            <span class="text-muted" v-if="tdAutoProgress === 100">
              {{ tdGenerated }}
              <br>
              {{ cpGenerated }}
              <br>
              {{ deGenerated }}
            </span>
          </p>
          <p>{{ elapsedTimeInfo }}</p>
        </div>
        <div slot="footer" class="w-100">
          <CButton class="ml-1 mr-1 float-right" color="primary" @click="() => { tdAutoModal = false }"
            :disabled="tdAutoProgress !== 100">
            OK
          </CButton>
          <CButton class="ml-1 mr-1 float-right" color="secondary" @click="onCancelTdAuto" v-if="tdAutoProgress !== 100">
            Cancel
          </CButton>
        </div>

        <CModal title="Cancel Generating Path" :show.sync="cancelTdAutoModal" size="sm" centered :show-close="false"
          class="scale-modal">
          <p>Are you sure you want to cancel?</p>
          <div slot="footer" class="w-100">
            <CButton class="ml-1 mr-1 float-right" color="primary" @click="onTerminatePathGeneratorWorker">
              OK
            </CButton>
            <CButton class="ml-1 mr-1 float-right" color="secondary" @click="() => { cancelTdAutoModal = false }">
              Cancel
            </CButton>
          </div>
        </CModal>
      </CModal>
    </div>
  </div>
</template>

<script>
import ContextMenu from "./ContextMenu.vue";
import WpQuickTool from "./WpQuickTool.vue";
import WpZoom from "./WpZoom.vue";

import WpBottomLeft from "@/components/WpBottomLeft.vue";
import WpBottomMid from "@/components/WpBottomMid.vue";
import WpMenuLeft from "@/components/WpMenuLeft.vue";
import WpTopRight from "@/components/WpTopRight.vue";
import DrawingInfo from "@/components/DrawingInfo.vue";
import WorkComponents from "./WorkComponents";
import SettingsPanel from "@/components/SettingsPanel.vue";
import CustomTable from "@/components/CustomTable.vue";

import FloorPlanApi from "@/lib/floorPlanApi";
import FireRatingApi from "@/lib/fireRatingApi";
import KreoAiApi from "@/lib/kreoAiApi";
import OlfMainCategoryApi from "@/lib/olfMainCategoryApi";
import OccupantLoadFactorApi from "@/lib/occupantLoadFactorApi";
import BuildingCodeApi from "@/lib/buildingCodeApi";
import OlfSubCategoryApi from "@/lib/olfSubCategoryApi";
import AreasApi from "@/lib/areasApi";
import DeepFloorPlanApi from "@/lib/deepFloorPlanApi";
import PathGenerator from "@/lib/pathGenerator";

import RoomApi from "@/lib/roomApi";
import DoorApi from "@/lib/doorApi";
import ObstacleApi from "@/lib/obstacleApi";
import CorridorEndApi from "@/lib/corridorEndApi";
import ExitStairAreaApi from "@/lib/exitStairAreaApi";
import ExitStairWidthApi from "@/lib/exitStairWidthApi";
import ExitStairDoorApi from "@/lib/exitStairDoorApi";
import DiagonalDistanceApi from "@/lib/diagonalDistanceApi";
import TravelDistanceApi from "@/lib/travelDistanceApi";
import CommonPathApi from "@/lib/commonPathApi";
import DeadEndApi from "@/lib/deadEndApi";
import LeftSwingDoorImg from "./../assets/images/new-door/swing_left_door.png";
import RightSwingDoorImg from "./../assets/images/new-door/swing_right_door.png";
import FoldingDoorImg from "./../assets/images/new-door/new_folding_door.png";
import OpenPathDoorImg from "./../assets/images/doors/open_path_square.png";
import SlidingDoorImg from "./../assets/images/new-door/new_sliding_door.png";
import ExitDoorImg from "./../assets/images/doors/exit-icon.png";
import DoubleDoorImg from "./../assets/images/new-door/new_double_door.png";

// Fire Rated Door Img
import Fr30Door from "./../assets/images/fire-rated-door/fd_30.png";
import Fr60Door from "./../assets/images/fire-rated-door/fd_60.png";
import Fr90Door from "./../assets/images/fire-rated-door/fd_90.png";
import Fr120Door from "./../assets/images/fire-rated-door/fd_120.png";
import Fr180Door from "./../assets/images/fire-rated-door/fd_180.png";
import Fr240Door from "./../assets/images/fire-rated-door/fd_240.png";


import PathGeneratorWorker from 'worker-loader!./../worker.js'; // Import the worker
var _ = require("lodash");
import moment from "moment";

const subRoomList = [];
const subRoomFieldList = [
  {
    key: "name",
    label: "Name",
  },
  {
    key: "show_remove",
    label: "",
    sorter: false,
    filter: false,
  },
];
const roomTravelDistanceList = [];
const roomTravelDistanceFieldList = [
  {
    key: "name",
    label: "Name",
  },
  {
    key: "show_remove",
    label: "",
    sorter: false,
    filter: false,
  },
];
const roomCommonPathList = [];
const roomCommonPathFieldList = [
  {
    key: "name",
    label: "Name",
  },
  {
    key: "show_remove",
    label: "",
    sorter: false,
    filter: false,
  },
];
const roomDoorList = [];
const roomDoorFieldList = [
  {
    key: "name",
    label: "Name",
  },
  {
    key: "doorType",
    label: "Type",
  },
  {
    key: "show_remove",
    label: "",
    sorter: false,
    filter: false,
  },
];
const roomList = [];
const roomFieldList = [
  {
    key: "select",
    label: "Select",
    _style: "width:5%",
    sorter: false,
    filter: false,
  },
  { key: "name" },
];
const travelDistanceList = [];
const travelDistanceFieldList = [
  {
    key: "select",
    label: "Select",
    _style: "width:5%",
    sorter: false,
    filter: false,
  },
  { key: "name" },
];
const commonPathList = [];
const commonPathFieldList = [
  {
    key: "select",
    label: "Select",
    _style: "width:5%",
    sorter: false,
    filter: false,
  },
  { key: "name" },
];
const doorList = [];
const doorFieldList = [
  {
    key: "select",
    label: "Select",
    _style: "min-width:1%",
    sorter: false,
    filter: false,
  },
  { key: "name" },
  { key: "doorType" },
];

const roomExitSeperationFieldList = [
  {
    key: "doorName",
    label: "From",
  },
  {
    key: "doorName2",
    label: "To",
  },
  {
    key: "distance",
    label: "Distance",
  },
];

const roomDiagonalFieldList = [
  {
    key: "point1",
    label: "Point 1",
  },
  {
    key: "point2",
    label: "Point 2",
  },
  {
    key: "distance",
    label: "Distance",
  },
];
const occupantLoadFactorList = [];
const occupantLoadFactorFieldList = [
  { key: "buildingCodeName", label: "Building Code" },
  { key: "mainCategoryName", label: "Occupancy Classification" },
  { key: "olfSubCategoryName", label: "Occupancy Classification Sub Category" },
  { key: "subCategoryName", label: "Area/space function" },
  { key: "olfValue", label: "OLF (m2/person)" },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];

const subCategoryList = [];
const subCategoryFieldList = [
  // { key: "id", _style: "min-width:50px" },
  { key: "code", _style: "min-width:200px;" },
  { key: "name", _style: "min-width:200px;" },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];
const olfAreaList = [];
const olfAreaFieldList = [
  // { key: "id", _style: "min-width:50px" },
  { key: "code", _style: "min-width:200px;" },
  { key: "name", _style: "min-width:200px;" },
  {
    key: "description",
    _style: "min-width:200px;",
    sorter: false,
    filter: false,
  },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];

const mainCategoryList = [];
const mainCategoryFieldList = [
  // { key: "id", _style: "min-width:50px" },
  { key: "code", _style: "min-width:200px;" },
  { key: "name", _style: "min-width:200px;" },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];

const buildingCodeList = [];
const buildingCodeFieldList = [
  // { key: "id", _style: "min-width:50px" },
  { key: "code", _style: "min-width:200px;" },
  { key: "name", _style: "min-width:200px;" },
  {
    key: "show_details",
    label: "",
    _style: "width:1%",
    sorter: false,
    filter: false,
  },
];

const widthValue = window.innerWidth;
const heightValue = window.innerHeight;

export default {
  name: "WpBoard",
  components: {
    ContextMenu,
    WpQuickTool,
    WpZoom,
    WpMenuLeft,
    WpBottomLeft,
    WpTopRight,
    DrawingInfo,
    WpBottomMid,
    WorkComponents,
    SettingsPanel,
    CustomTable
  },
  props: {
    obj: null,
    drawingMeta: null,
  },
  data() {
    return {
      markedGrid: [],
      doorPreviewElement: null,
      tdAutoMessage: {
        message: '',
        type: '',
      },
      tdGenerated: null,
      cpGenerated: null,
      deGenerated: null,
      elapsedTimeInfo: '',
      tdAiCountInterval: null,
      tdAutoModal: false,
      cancelTdAutoModal: false,
      tdAutoIntervalId: null,
      tdAutoProgress: 0,
      olfIsFt: false,
      kreoLoadingMessage: [
        "Loading data, please wait...",
        "Processing information...",
        "Creating walls and doors...",
        "Almost there, just a moment...",
        "Preparing visualization...",
        "Generating layout...",
        "Performing analysis...",
      ],
      currentLoadingMessage: "",
      intervalId: null,
      resizeLoading: false,
      activePanel: null,
      fireRatings: [],
      scaleLinePreview: false,
      zoomPercentage: 100,
      doorRotation: null,
      swingDoors: [],
      swingDoor: null,
      leftSwingDoor: null,
      rightSwingDoor: null,
      foldingDoor: null,
      openPathDoor: null,
      slidingDoor: null,
      exitDoorImg: null,
      doubleDoor: null,
      fireRatingDoor: null,

      fr30Door: null,
      fr60Door: null,
      fr90Door: null,
      fr120Door: null,
      fr180Door: null,
      fr240Door: null,

      showComponent: false,
      cellsProcessed: [],
      path: [],
      walls: [],
      showTravelDistancePage: false,
      startPosList: [],
      endPosList: [],
      deadEndStartPosList: [],
      map: null,
      cellSize: 10,
      grid: [],
      centerDoorPositions: [],
      roomStartPosition: [],
      deadEndStartPosition: [],
      detectDoors: [],
      pathDrawingType: "",
      travelDistanceSearchPopup: false,
      commonPathSearchPopup: false,
      subRoomList: subRoomList.map((item, index) => {
        return { ...item.index }
      }),
      roomDoorList: roomDoorList.map((item, index) => {
        return { ...item, index };
      }),
      roomTravelDistanceList: roomTravelDistanceList.map((item, index) => {
        return { ...item, index }
      }),
      roomCommonPathFieldList: roomCommonPathFieldList.map((item, index) => {
        return { ...item, index }
      }),
      roomCommonPathList,
      subRoomFieldList,
      roomDoorFieldList,
      roomTravelDistanceFieldList,
      roomCommonPathFieldList,
      pageLoading: true,
      loadingDeepFloorPlane: false,
      infoList: [],
      step: 0,
      newOccupantLoadFactorObj: {},
      buildingCodeList: buildingCodeList.map((item, id) => {
        return { ...item, id };
      }),
      buildingCodeFieldList,

      mainCategoryList: mainCategoryList.map((item, id) => {
        return { ...item, id };
      }),
      mainCategoryFieldList,

      subCategoryList: subCategoryList.map((item, id) => {
        return { ...item, id };
      }),
      olfAreaList: olfAreaList.map((item, id) => {
        return { ...item, id };
      }),
      olfAreaFieldList,
      subCategoryFieldList,
      buildingCodeSearchPopup: false,
      addNewBuildingCodePopup: false,
      mainCategorySearchPopup: false,
      addNewMainCategoryPopup: false,
      subCategorySearchPopup: false,
      addNewSubCategoryPopup: false,
      olfAreaSearchPopup: false,

      newBuildingCodeObj: {
        code: "",
        name: "",
        description: "",
      },
      newMainCategoryObj: {
        code: "",
        name: "",
        description: "",
      },
      newSubCategoryObj: {
        code: "",
        name: "",
        description: "",
      },
      addNewOccupantLoadFactorPopup: false,
      selectedSubCategory: {
        id: null,
        name: "",
        code: "",
      },
      selectedMainCategory: {
        id: null,
        name: "",
        code: "",
      },
      selectedBuildingCode: {
        id: null,
        name: "",
        code: "",
      },
      selectedOlfArea: {
        id: null,
        name: "",
        code: "",
      },
      api: new FloorPlanApi(),
      fireRatingApi: new FireRatingApi(),
      olfMainCategoryApi: new OlfMainCategoryApi(),
      occupantLoadFactorApi: new OccupantLoadFactorApi(),
      buildingCodeApi: new BuildingCodeApi(),
      olfSubCategoryApi: new OlfSubCategoryApi(),
      areasApi: new AreasApi(),
      deepFloorPlanApi: new DeepFloorPlanApi(),
      obstacleApi: new ObstacleApi(),
      corridorEndApi: new CorridorEndApi(),
      doorApi: new DoorApi(),
      roomApi: new RoomApi(),

      exitStairAreaApi: new ExitStairAreaApi(),
      exitStairWidthApi: new ExitStairWidthApi(),
      exitStairDoorApi: new ExitStairDoorApi(),
      diagonalDistanceApi: new DiagonalDistanceApi(),
      kreoAiApi: new KreoAiApi(),
      travelDistanceApi: new TravelDistanceApi(),
      commonPathApi: new CommonPathApi(),
      deadEndApi: new DeadEndApi(),

      occupantLoadFactorList: occupantLoadFactorList.map((item, id) => {
        return { ...item, id };
      }),

      roomSettingObj: {},
      kreoScale: {
        x: 1,
        y: 1,
      },

      exitAreaSettingObj: {},
      doorSettingObj: {},
      exitStairWidthSettingObj: {},
      exitDoorSettingObj: {},
      exitSeperationSettingObj: {},
      diagonalDistanceSettingObj: {},
      commonPathSettingObj: {},
      deadEndSettingObj: {},
      corridorEndSettingObj: {},
      travelDistanceSettingObj: {},
      obstacleSettingObj: {},

      roomSettingIndex: 0,
      roomSettingPopup: false,
      obstacleSettingPopup: false,

      doorSettingPopup: false,
      exitAreaSettingPopup: false,
      exitStairWidthSettingPopup: false,
      exitDoorSettingPopup: false,
      exitSeperationSettingPopup: false,
      diagonalDistanceSettingPopup: false,
      commonPathSettingPopup: false,
      deadEndSettingPopup: false,
      corridorEndSettingPopup: false,
      travelDistanceSettingPopup: false,

      loading: false,
      roomExitSeperationFieldList,
      occupantLoadFactorFieldList,
      occupantLoadFactorSearchPopup: false,
      insertGlaModal: false,
      scaleSettingPopup: false,
      aiConfirmationModal: false,
      clearDrawingsConfirmationModal: false,
      newScale: 0,
      image: null,
      showContextMenu: false,
      analysisColorCode: [0, 0, 0],
      analysisTemplateList: [],
      analysisMethod: "",
      toolTip: {
        x: 0,
        y: 0,
        text: "",
        visible: false,
      },
      originalStageSize: {
        width: 3000,
        height: 3000,
      },
      stageSize: {
        width: widthValue,
        height: heightValue,
        draggable: true,
        dragBoundFunc: (pos) => {
          this.obj.stagePosX = pos.x;
          this.obj.stagePosY = pos.y;
          return {
            x: pos.x,
            y: pos.y,
          };
        },
      },
      imageConfig: {
        x: 0,
        y: 0,
        image: null
      },
      stroke: "black",
      strokeWidth: 4,
      mouseClickPoint: [],
      selectedOccupantLoadFactor: null,
      glaObj: {},
      roomDoorList: roomDoorList.map((item, index) => {
        return { ...item, index };
      }),
      roomDoorFieldList,
      roomDiagonalFieldList,
      doorList: doorList.map((item, id) => {
        return { ...item, id };
      }),
      roomList: roomList.map((item, id) => {
        return { ...item, id };
      }),
      travelDistanceList: travelDistanceList.map((item, id) => {
        return { ...item, id };
      }),
      commonPathList: commonPathList.map((item, id) => {
        return { ...item, id };
      }),
      doorFieldList,
      roomFieldList,
      travelDistanceFieldList,
      commonPathFieldList,
      drawType: "",
      drawingState: "",
      //rooms: [],
      doors: [],
      exitStairAreas: [],
      exitStairDoors: [],
      exitStairWidths: [],
      exitSeperations: [],
      commonPaths: [],
      fireRating: [],
      travelDistances: [],
      diagonalDistances: [],
      obstacles: [],
      corridorEnds: [],
      deadEnds: [],
      roomListOptions: [
        {
          label: 'Please select room',
          value: null
        }
      ],
      corridorEndListOptions: [],
      // roomDiagonalDistances: [],
      //fslRooms: [],
      // anchors: [],
      stageHistory: [
        {
          drawType: "",
          drawingState: "",
          rooms: [],
          doors: [],
          exitStairAreas: [],
          exitStairDoors: [],
          exitStairWidths: [],
          exitSeperations: [],
          commonPaths: [],
          travelDistances: [],
          diagonalDistances: [],
          obstacles: [],
          corridorEnds: [],
          deadEnds: [],
        },
      ],
      isDrawingActive: false,
      doorTypeOptionsList: [
        { value: "swingIn", label: "Swing In" },
        { value: "swingOut", label: "Swing Out" },
        { value: "swingBoth", label: "Swing Both" },
        { value: "folding", label: "Folding" },
        { value: "openPath", label: "Open Path" },
        { value: "sliding", label: "Sliding" },
        { value: "doubleDoor", label: "Double Door" },
      ],
      worker: null,
    };
  },
  computed: {
    horizontalGridLines() {
      return Array.from({ length: this.originalStageSize.width / this.cellSize }, (_, i) => i);
    },
    verticalGridLines() {
      return Array.from({ length: this.originalStageSize.height / this.cellSize }, (_, j) => j);
    },
    computedDoorList() {
      var self = this;
      return self.doorList.map((item) => {
        return {
          ...item,
          doorType: item.doorType.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) { return str.toUpperCase(); }),
          _classes: [item._classes, item._selected ? "table-selected" : ""],
        };
      });
    },
    computedRoomList() {
      var self = this;

      return self.roomList.map((item) => {
        return {
          ...item
        }
      })
    },
    computedTravelDistanceList() {
      var self = this;

      return self.travelDistanceList.map((item) => {
        return {
          ...item
        }
      })
    },
    computedCommonPathList() {
      var self = this;

      return self.commonPathList.map((item) => {
        return {
          ...item
        }
      })
    },
    computedOccupantLoadFactorList() {
      if (!this.occupantLoadFactorList) {
        return [];
      }

      const squareMeterToSquareFoot = 10.764; // Conversion factor

      const mappedItems = this.occupantLoadFactorList.map((item) => {
        const commonProperties = {
          buildingCodeName: this.getBuildingCodeName(item),
          olfSubCategoryName: item.olfSubCategory
            ? item.olfSubCategory.name
            : "N/A",
          mainCategoryName: this.getMainCategoryName(item),
          subCategoryName: this.getSubCategoryName(item),
        };

        if (this.olfIsFt) {
          return {
            ...item,
            ...commonProperties,
            olfValue: (item.olfValue / squareMeterToSquareFoot).toFixed(2), // Conversion to square feet
          };
        }

        return {
          ...item,
          ...commonProperties,
        };
      });

      return mappedItems;
    },
    computedOccupantLoadFactorFieldList() {
      return this.occupantLoadFactorFieldList.map((field) => {
        if (field.key === "olfValue" && this.olfIsFt) {
          return { ...field, label: "OLF (ft)" };
        }
        return field;
      });
    },
    computedRoomDiagonalList() {
      var self = this;
      if (self.roomSettingObj.points == null) return;

      var diagonalPoints = drawing.getRoomDiagonalPoints(self.roomSettingObj);
      var distanceInPixel = drawing.getDistanceInPixel(
        diagonalPoints[0],
        diagonalPoints[1],
        diagonalPoints[2],
        diagonalPoints[3]
      );
      var distainceInMeter = drawing.convertDistancePixelToMeter(
        distanceInPixel,
        this.obj.scale.distanceInMeterForOnePixel
      );

      return [
        {
          point1:
            "(" +
            diagonalPoints[0].toFixed(2) +
            ", " +
            diagonalPoints[1].toFixed(2) +
            ")",
          point2:
            "(" +
            diagonalPoints[2].toFixed(2) +
            ", " +
            diagonalPoints[3].toFixed(2) +
            ")",
          distance: distainceInMeter.toFixed(2),
        },
      ];
    },
    computedRoomDoorList() {
      // if (!this.roomDoorList) {
      //   return [];
      // }

      return this.roomDoorList.map((item) => {
        return {
          ...item,
          name: item.doors.name,
          doorType: item.doors.doorType,
        };
      });
    },
    computedSubRoomList() {
      if (!this.subRoomList) {
        return []
      }
      return this.subRoomList.map((item) => {
        return {
          ...item,
          name: item.name
        };
      });
    },
    computedRoomTravelDistanceList() {
      return this.roomTravelDistanceList.map((item) => {
        return {
          ...item,
        };
      });
    },
    computedRoomCommonPathList() {
      return this.roomCommonPathList.map((item) => {
        return {
          ...item,
        };
      });
    },
    floorPlanUrl() {
      var fullUrl = "";
      if (this.obj) fullUrl = this.obj.documentUrl;

      return fullUrl;
    },

    computedRoomExitSeperationList() {
      var self = this;

      var exitDistanceList = [];
      for (let i = 0; i < self.computedRoomDoorList.length; i++) {
        for (let j = 0; j < self.computedRoomDoorList.length; j++) {
          if (i == j) continue;

          var isDuplicate = false;
          for (let k = 0; k < exitDistanceList.length; k++) {
            if (
              exitDistanceList[k].doorId == self.computedRoomDoorList[i].id &&
              exitDistanceList[k].doorId2 == self.computedRoomDoorList[j].id
            ) {
              isDuplicate = true;
            }
            if (
              exitDistanceList[k].doorId2 == self.computedRoomDoorList[i].id &&
              exitDistanceList[k].doorId == self.computedRoomDoorList[j].id
            ) {
              isDuplicate = true;
            }
          }
          if (isDuplicate) continue;

          exitDistanceList.push({
            doorId: self.computedRoomDoorList[i].id,
            doorName: self.computedRoomDoorList[i].name,
            doorId2: self.computedRoomDoorList[j].id,
            doorName2: self.computedRoomDoorList[j].name,
            distance: self.getDistanceFromPixelPointInMeter(
              self.getCenterPosition(self.computedRoomDoorList[i].door),
              self.getCenterPosition(self.computedRoomDoorList[j].door)
            ),
          });
        }
      }

      return exitDistanceList;
    },
    calculateDoorHeight() {
      const aspectRatio = 1 / 1

      // if (!this.doorSettingObj.height) return 83
      return (this.doorSettingObj.width * aspectRatio).toFixed(2);
    },
    // computedDoorRotation: {
    //   get() {
    //     return this.doorSettingObj.rotation;
    //   },
    //   set(value) {
    //     this.doorSettingObj.rotation = value;
    //   }
    // },
  },
  watch: {
    obj(newVal, oldVal) {
      if (this.obj) {
        this.exitSeperations = this.obj.exitSeperations;
        this.exitStairWidths = this.obj.exitStairWidths;
        this.exitStairDoors = this.obj.exitStairDoors;
        this.diagonalDistances = this.obj.diagonalDistances;
        this.travelDistances = this.obj.travelDistances;
        this.commonPaths = this.obj.commonPaths;
        // this.fireRating = this.obj.fireRating
        this.rooms = this.obj.rooms;
        this.obstacles = this.obj.obstacles;
        this.exitStairAreas = this.obj.exitStairAreas;
        this.corridorEnds = this.obj.corridorEnds;
        this.deadEnds = this.obj.deadEnds;

        if (this.obj.stagePosX === 0) {
          this.stageSize.x = 280;
        } else {
          this.stageSize.x = this.obj.stagePosX;
        }

        if (this.obj.stagePosY === 0) {
          this.stageSize.y = 80;
        } else {
          this.stageSize.y = this.obj.stagePosY;
        }

        this.autoDrawExitSeperation();
        this.autoDrawDiagonalDistance();
        this.refreshTableRoom();
        this.refreshTableCorridorEnd();
        this.initSaveHistory();
      }
    },
    drawingMeta(newVal, oldVal) { },

    floorPlanUrl(newVal, oldVal) {
      const image = new window.Image();
      image.src = this.floorPlanUrl;
      image.crossOrigin = "anonymous";
      image.onload = () => {
        this.image = image;

        this.originalStageSize.width = image.width;
        this.originalStageSize.height = image.height;

        this.stageSize.width = this.originalStageSize.width * 2;
        this.stageSize.height = this.originalStageSize.height * 2;
        this.imageConfig.image = image;
        this.changeRect();
      };
    },
  },
  methods: {
    changeRect() {
      const container = this.$refs.container;

      if (!container) {
        return;
      }

      const height = container.offsetHeight;
      const width = container.offsetWidth;

      this.stageSize.width = width;
      this.stageSize.height = height;
    },
    onKeyboardShortcutEvent(e) {
      // ESC
      if (e.keyCode === 27) {
        const supportedDrawTypes = [
          "travelDistance",
          "commonPath",
          "deadEnd",
          "room",
          "roomRect",
          "stairExit",
          "obstacle",
          "obstruction",
          "corridorEnd",
        ];

        if (supportedDrawTypes.includes(this.drawType)) {
          this.drawingState = "end";
          const handlerMap = {
            travelDistance: this.handleDrawTravelDistance,
            commonPath: this.handleDrawCommonPath,
            deadEnd: this.handleDrawDeadEnd,
            // room: this.handleDrawRoom,
            // roomRect: this.handleDrawRoomRect,
            stairExit: this.handleDrawStairExit,
            obstacle: this.handleDrawObstacle,
            obstruction: this.handleDrawObstacle,
            corridorEnd: this.handleDrawCorridorEnd,
          };

          const handlerFunction = handlerMap[this.drawType];

          if (handlerFunction) {
            handlerFunction();
          }
        }

        this.drawingState = null;
        this.isDrawingActive = false;
        this.handleDone();
        let checkDrawingType = "none";
        if (this.drawType == "room") {
          checkDrawingType = "room";
        }
        this.drawType = "";
        this.removeDoorPreview();
        this.toolClick("cancel");
        if (checkDrawingType == "room") {
          this.handleAutoDrawObstacleBasedOnRoom();
        }
      }

      // CTRL + Z
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 90) {
        this.handlePrevStep();
      }

      // CTRL + S
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 83) {
        this.handleSave();
        e.preventDefault();
      }

      // CTRL + Y
      if ((e.ctrlKey || e.metaKey) && e.keyCode === 89) {
        this.handleNextStep();
      }
    },
    onZoomEvent(e) {
      if (e.evt.wheelDelta > 0) {
        this.zoomIn();
      } else {
        this.zoomOut();
      }
    },
    async createAndHandleApiResponse(apiInstance, payload, silent = false) {
      try {
        const response = await apiInstance.create(payload);
        if (silent == false) {
          this.toast(
            "Info",
            `${response.name} is successfully created.`,
            "success"
          );
          // this.resetObj();
          const updateId = (array) => {
            const matchedItem = array.find((item) => item.code === response.code);
            if (matchedItem) {
              matchedItem._id = response.id;
            }
          };

          if (apiInstance === this.doorApi) {
            updateId(this.obj.doors);
          } else if (apiInstance === this.travelDistanceApi) {
            updateId(this.obj.travelDistances);
          } else if (apiInstance === this.roomApi) {
            updateId(this.obj.rooms);
          } else if (apiInstance === this.exitStairAreaApi) {
            updateId(this.obj.exitStairAreas)
          } else if (apiInstance === this.corridorEndApi) {
            updateId(this.obj.corridorEnds)
          } else if (apiInstance === this.commonPathApi) {
            updateId(this.obj.commonPaths)
          } else if (apiInstance === this.deadEndApi) {
            updateId(this.obj.deadEnds)
          } else if (apiInstance === this.exitStairWidthApi) {
            updateId(this.obj.exitStairWidths)
          }
        }
        this.handleSaveHistory();
        this.$parent.$refs.wpMenuMain.topBtnIsEnabled = false;
      } catch (err) {
        console.error(err)
        this.toast("Error", err.data.message, "danger");
      }
    },
    removeDoorPreview() {
      this.doorPreviewElement = null;
      this.obj.rooms.forEach((room) => {
        this.$set(room, 'fill', 'rgb(255,77,79,0.5)');
      });

      this.obj.corridorEnds.forEach((corridor) => {
        this.$set(corridor, 'fill', 'rgb(255,165,0,0.5)');
      });
    },
    onDoorSettingDelete() {
      var self = this;
      this.doorApi
        .delete(self.doorSettingObj._id)
        .then(() => {
          self.toast("Info", "Delete Success", "success");
          self.resetObj();
          self.activePanel = null;
          // this.doorSettingPopup = false;
        })
        .catch(({ data }) => {
          self.toast("Error", data.message, "danger");
        });
    },
    onDoorSettingConfirmation() {
      var self = this;

      // if (
      //   self.doorSettingObj.doorType == "" ||
      //   self.doorSettingObj.doorType == null
      // )
      //   self.doorSettingObj.doorType = "Swing In";
      let payload = { ...self.doorSettingObj };

      delete payload._id, delete payload.createdAt, delete payload.updatedAt;
      delete payload.__v;
      delete payload.image;
      delete payload.x;
      delete payload.y;
      delete payload.distance;
      delete payload.fireRatingDoorImg;

      this.doorApi
        .update(self.doorSettingObj, payload)
        .then((response) => {
          self.toast("Info", `Successfully update ${response.name}`, "success");
          self.resetObj();
          this.activePanel = null;
        })
        .catch(({ data }) => {
          self.toast("Error", data.message, "danger");
        });
    },

    onExitDoorSettingDelete() {
      var self = this;
      this.exitStairDoorApi
        .delete(self.exitDoorSettingObj._id)
        .then((response) => {
          self.toast("Info", "Delete Success", "success");
          self.resetObj();
          this.activePanel = null;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    // onExitDoorSettingConfirmation() {
    //   var self = this;

    //   this.exitStairDoorApi
    //     .update(self.exitDoorSettingObj)
    //     .then((response) => {
    //       self.toast("Info", "Update Success", "success");
    //       this.$router.go();
    //     })
    //     .catch(({ data }) => {
    //       self.toast("Error", helper.getErrorMessage(data), "danger");
    //     });
    // },

    onExitSeperationSettingDelete() {
      var self = this;
      this.exitSeperationApi
        .delete(self.exitSeperationSettingObj.id)
        .then((response) => {
          self.toast("Info", "Delete Success", "success");
          this.$router.go();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    onExitSeperationSettingConfirmation() {
      var self = this;

      this.exitSeperationApi
        .update(self.exitSeperationSettingObj)
        .then((response) => {
          self.toast("Info", "Update Success", "success");
          this.$router.go();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },

    onExitAreaSettingDelete() {
      var self = this;

      this.exitStairAreaApi
        .delete(self.exitAreaSettingObj._id)
        .then((response) => {
          self.toast("Info", "Delete Success", "success");
          self.resetObj();
          this.activePanel = null;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    onExitAreaSettingConfirmation() {
      var self = this;
      this.exitAreaApi
        .update(self.exitAreaSettingObj)
        .then((response) => {
          self.toast("Info", "Update Success", "success");
          this.$router.go();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },

    onExitStairWidthSettingDelete() {
      var self = this;
      this.exitStairWidthApi
        .delete(self.exitStairWidthSettingObj._id)
        .then((response) => {
          self.toast("Info", "Delete Success", "success");
          self.resetObj();
          this.activePanel = null;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    // onExitStairWidthSettingConfirmation() {
    //   var self = this;
    //   this.exitStairWidthApi
    //     .update(self.exitStairWidthSettingObj)
    //     .then((response) => {
    //       self.toast("Info", "Update Success", "success");
    //       this.$router.go();
    //     })
    //     .catch(({ data }) => {
    //       self.toast("Error", helper.getErrorMessage(data), "danger");
    //     });
    // },

    //
    onDiagonalDistanceSettingDelete() {
      var self = this;
      this.diagonalDistanceApi
        .delete(self.diagonalDistanceSettingObj.id)
        .then((response) => {
          self.toast("Info", "Delete Success", "success");
          this.$router.go();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    onDiagonalDistanceSettingConfirmation() {
      var self = this;

      this.diagonalDistanceApi
        .update(self.diagonalDistanceSettingObj)
        .then((response) => {
          self.toast("Info", "Update Success", "success");
          this.$router.go();
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },

    onCommonPathSettingDelete() {
      var self = this;
      this.commonPathApi
        .delete(self.commonPathSettingObj._id)
        .then((response) => {
          self.toast("Info", "Delete Success", "success");
          self.resetObj();
          this.activePanel = null;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },

    onDeadEndSettingDelete() {
      var self = this;
      this.deadEndApi
        .delete(self.deadEndSettingObj._id)
        .then((response) => {
          self.toast("Info", "Delete Success", "success");
          self.resetObj();
          this.activePanel = null;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },

    onTravelDistanceSettingDelete() {
      var self = this;
      this.travelDistanceApi
        .delete(self.travelDistanceSettingObj._id)
        .then((response) => {
          self.toast("Info", "Delete Success", "success");
          self.resetObj();
          this.activePanel = null;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    onUpdateTdCpConfirmation(apiInstance, settingObj) {
      var self = this;
      let { _id, createdAt, updatedAt, __v, room, ...payload } = settingObj;

      delete payload.corridorEnd;

      apiInstance
        .update(settingObj._id, payload)
        .then(() => {
          self.toast("Info", "Update successful", "success");
          self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", data.message || "Unknown error occurred", "danger");
        });
    },
    onTravelDistanceSettingConfirmation() {
      this.onUpdateTdCpConfirmation(this.travelDistanceApi, this.travelDistanceSettingObj)
      this.activePanel = null;
    },
    onCommonPathSettingConfirmation() {
      this.onUpdateTdCpConfirmation(this.commonPathApi, this.commonPathSettingObj)
      this.activePanel = null;
    },
    onDeadEndSettingConfirmation() {
      this.onUpdateTdCpConfirmation(this.deadEndApi, this.deadEndSettingObj)
      this.activePanel = null;
    },
    onCorridorEndSettingDelete() {
      var self = this;
      this.corridorEndApi
        .delete(self.corridorEndSettingObj._id)
        .then((response) => {
          self.toast("Info", "Delete Success", "success");
          self.resetObj();
          this.activePanel = null;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },

    getCoordinateFirst(obj) {
      if (obj.points == null) return "";
      if (obj.points.length < 2) return "";

      return (
        "(" + obj.points[0].toFixed(2) + ", " + obj.points[1].toFixed(2) + ")"
      );
    },
    getCoordinateSecond(obj) {
      if (obj.points == null) return "";
      if (obj.points.length < 4) return "";

      return (
        "(" + obj.points[2].toFixed(2) + ", " + obj.points[3].toFixed(2) + ")"
      );
    },

    dev(item) {
      this.showTravelDistancePage = true;
      this.drawingMeta.showGrid = true;
      switch (item) {
        case "detectObstacle":
          this.detectObstacle();
          break;
        case "detectWall":
          this.recognizeWalls();
          break;
        case "detectDoor":
          this.detectDoor();
          break;
        case "clearBlockedDoor":
          this.clearBlockedDoor();
          break;

        //     this.detectDoor();
        // this.clearBlockedDoor();
        default:
          break;
      }
    },
    showComponentList() {
      this.showComponent = !this.showComponent;
    },
    onMouseMoveTravelDistance(item) {
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      this.toolTip.x = mousePos.x + 5;
      this.toolTip.y = mousePos.y + 5;
      this.toolTip.text = this.getLineDetailInfo(item);
      this.toolTip.visible = true;

      const itemName = item.name.toLowerCase();
      let currentArray;
      // let strokeWidth = item.strokeWidth * 5;

      if (itemName.includes("travel distance")) {
        currentArray = this.obj.travelDistances;
      } else if (itemName.includes("common path")) {
        currentArray = this.obj.commonPaths;
      } else if (itemName.includes("dead end")) {
        currentArray = this.obj.deadEnds;
      }

      if (currentArray) {
        let hoveredArrow = currentArray.find(el => el.code === item.code);
        // hoveredArrow.strokeWidth = strokeWidth;
        hoveredArrow.shadowColor = 'black';
        hoveredArrow.shadowBlur = 10;
        hoveredArrow.shadowOffset = { x: 10, y: 10 };
        hoveredArrow.shadowOpacity = 0.5;
      }
    },
    getLineDetailInfo(item) {
      var code = item.name;
      var area = this.getDistanceInMeter(item);
      return code + "\n" + area + "m";
    },
    getDistanceInMeter(item) {
      var distanceInPixel = this.getDistance(item);
      var distainceInMeter =
        distanceInPixel * this.obj.scale.distanceInMeterForOnePixel;
      distainceInMeter = Math.round(distainceInMeter * 1000) / 1000;

      return distainceInMeter;
    },
    getDistance(item) {
      try {
        // var points = item.points;

        return drawing.getDistanceFromPoints(item.points);
      } catch (error) { }
      return 0;
    },

    showGridUpdateClick() {
      this.pageLoading = true;

      this.showTravelDistancePage = this.drawingMeta.showGrid;
      this.cellSize = parseInt(this.drawingMeta.gridSize);
      this.cellsProcessed = [];
      this.path = [];
      this.walls = [];
      this.startPosList = [];
      this.endPosList = [];
      // this.travelDistances = [];
      this.prepareGrid(true);
      this.pageLoading = false;
    },
    obstacleClearClick() {
      this.obstacles = [];
    },
    travelDistanceClearClick() {
      this.travelDistances = [];
      this.commonPaths = [];
      this.deadEnds = [];
      this.showTravelDistancePage = this.drawingMeta.showGrid;
      this.cellSize = parseInt(this.drawingMeta.gridSize);
    },
    gridSizeUpdateClick() {
      this.pageLoading = true;
      this.showTravelDistancePage = this.drawingMeta.showGrid;
      this.cellSize = parseInt(this.drawingMeta.gridSize);
      this.cellsProcessed = [];
      this.path = [];
      this.walls = [];
      this.startPosList = [];
      this.endPosList = [];
      this.prepareGrid(true);
      this.pageLoading = false;
    },
    strokeWidthUpdateClick() {
      const { strokeWidth } = this.drawingMeta;
      
      const updateStrokeWidth = (array) => {
        array.forEach(el => {
            el.strokeWidth = parseInt(strokeWidth);
        });
      };

      updateStrokeWidth(this.travelDistances);
      updateStrokeWidth(this.commonPaths);
      updateStrokeWidth(this.deadEnds);
    },
    getOccupantLoadFactorForDisplay(item) {
      if (!item) return "";
      const olfName = item.mainCategoryName ? item.mainCategoryName : item;
      return olfName;
    },
    getDistanceFromPixelPointInMeter(line1, line2) {
      // Check if line1 and line2 are valid objects
      if (
        line1 &&
        line2 &&
        typeof line1.x !== "undefined" &&
        typeof line1.y !== "undefined" &&
        typeof line2.x !== "undefined" &&
        typeof line2.y !== "undefined"
      ) {
        var distanceInPixel = drawing.getDistanceInPixel(
          line1.x,
          line1.y,
          line2.x,
          line2.y
        );
        var distainceInMeter = drawing.convertDistancePixelToMeter(
          distanceInPixel,
          this.obj.scale.distanceInMeterForOnePixel
        );

        return distainceInMeter;
      } else {
        // Handle the case where either line1 or line2 is not valid
        // You can return a default value or handle the error as appropriate
        return 0; // Default value, you can change this to fit your use case
      }
    },
    getCenterPositionForDisplay(obj) {
      // We need this - dixon
      // Temp commented
      // // console.warn('getCenterPositionForDisplay obj', obj)
      // var center = this.getCenterPosition(obj);
      // if (center == null) return "";
      // return "(" + center.x + "," + center.y + ")";
    },
    getCenterPosition(obj) {
      // Temp commet
      // console.log('getCenterPosition objh', obj)
      // if (!obj.points) {
      //   console.warn('not working')
      // }
      // if (obj.points == null) return null;
      // if (obj.points.length < 4) return null;
      // var minX = -1;
      // var minY = -1;
      // var maxX = -1;
      // var maxY = -1;
      // for (let j = 0; j < obj.points.length; j = j + 2) {
      //   var positionX = obj.points[j];
      //   var positionY = obj.points[j + 1];
      //   if (minX < 0) minX = positionX;
      //   if (positionX < minX) minX = positionX;
      //   if (maxX < 0) maxX = positionX;
      //   if (positionX > maxX) maxX = positionX;
      //   if (minY < 0) minY = positionY;
      //   if (positionY < minY) minY = positionY;
      //   if (maxY < 0) maxY = positionY;
      //   if (positionY > maxY) maxY = positionY;
      // }
      // return {
      //   x: Math.round((minX + maxX) / 2),
      //   y: Math.round((minY + maxY) / 2),
      // };
    },

    onDoorConfirmation() {
      var self = this;
      var tempList = [];
      for (let i = 0; i <= self.doorList.length; i++) {
        try {
          if (self.doorList[i]._selected == true) {
            tempList.push({
              doors: self.doorList[i],
            });
          }
        } catch (err) {
          console.error("Error:", err);
        }
      }
      self.activePanel = 'roomSettingPopup';
      self.roomDoorList = tempList;
    },
    onRoomConfirmation() {
      var self = this;
      const tempList = [];

      for (let i = 0; i <= self.roomList.length; i++) {
        const currentRoom = self.roomList[i];

        if (currentRoom && currentRoom._selected) {
          tempList.push(currentRoom);
        }
      }
      self.activePanel = 'roomSettingPopup';
      self.subRoomList = tempList;
    },
    onTravelDistanceConfirmation() {
      const tempList = [];

      for (let i = 0; i < this.travelDistanceList.length; i++) {
        const currentTravelDistance = this.travelDistanceList[i];

        if (currentTravelDistance && currentTravelDistance._selected) {
          tempList.push(currentTravelDistance);
        }
      }

      this.roomTravelDistanceList = tempList;
    },

    onCommonPathConfirmation() {
      const tempList = [];

      for (let i = 0; i < this.commonPathList.length; i++) {
        const currentTravelDistance = this.commonPathList[i];

        // Perform a null check before accessing properties
        if (currentTravelDistance && currentTravelDistance._selected) {
          tempList.push(currentTravelDistance);
        }
      }

      this.roomCommonPathList = tempList;
    },
    roomRowClicked(item, index, column, e) {
      if (!["INPUT", "LABEL"].includes(e.target.tagName)) {
        this.selectRoom(item);
      }
    },
    selectRoom(item) {
      var self = this;
      for (let i = 0; i <= self.roomList.length; i++) {
        try {
          if (self.roomList[i]._id == item._id) {
            const val = Boolean(self.roomList[i]._selected);
            self.$set(self.roomList[i], "_selected", !val);
          }
        } catch (err) { }
      }
    },
    doorRowClicked(item, index, column, e) {
      if (!["INPUT", "LABEL"].includes(e.target.tagName)) {
        this.selectDoor(item);
      }
    },
    selectDoor(item) {
      var self = this;
      for (let i = 0; i <= self.doorList.length; i++) {
        try {
          if (self.doorList[i].id == item.id) {
            const val = Boolean(self.doorList[i]._selected);
            self.$set(self.doorList[i], "_selected", !val);
          }
        } catch (err) { }
      }
    },
    travelDistanceRowClicked(item, index, column, e) {
      if (!["INPUT", "LABEL"].includes(e.target.tagName)) {
        this.selectTravelDistance(item);
      }
    },
    selectTravelDistance(selectedItem) {
      this.travelDistanceList.forEach((item) => {
        this.$set(item, "_selected", item._id === selectedItem._id);
      });
    },
    commonPathRowClicked(item, index, column, e) {
      if (!["INPUT", "LABEL"].includes(e.target.tagName)) {
        this.selectCommonPath(item);
      }
    },
    selectCommonPath(selectedItem) {
      this.commonPathList.forEach((item) => {
        this.$set(item, "_selected", item.id === selectedItem.id);
      });
    },
    getLongestPositionByGrid(currentRoom) {
      var minX = -1;
      var minY = -1;
      var maxX = -1;
      var maxY = -1;

      for (let j = 0; j < currentRoom.points.length; j = j + 2) {
        var positionX = currentRoom.points[j];
        var positionY = currentRoom.points[j + 1];

        if (minX == -1) {
          minX = positionX;
          maxX = positionX;
          minY = positionY;
          maxY = positionY;
          continue;
        }

        if (positionX < minX) minX = positionX;
        if (positionX > maxX) maxX = positionX;

        if (positionY < minY) minY = positionY;
        if (positionY > maxY) maxY > positionY;
      }
      for (let i = minX; i < maxX; i += this.cellSize) {
        for (let j = minY; j < maxY; j += this.cellSize) { }
      }

      var longestDistance = 0;
      var longestPosition = null;
      for (let i = 0; i < currentRoom.roomDoors.length; i++) {
        var currentDoor = currentRoom.roomDoors[i].doors;
        var centerDoorX = (currentDoor.points[0] + currentDoor.points[2]) / 2;
        var centerDoorY = (currentDoor.points[1] + currentDoor.points[3]) / 2;

        centerDoorX = Math.round(centerDoorX);
        centerDoorY = Math.round(centerDoorY);

        for (let j = 0; j < currentRoom.points.length; j = j + 2) {
          var positionX = currentRoom.points[j];
          var positionY = currentRoom.points[j + 1];

          var distanceInPixel = drawing.getDistanceInPixel(
            centerDoorX,
            centerDoorY,
            positionX,
            positionY
          );

          if (distanceInPixel > longestDistance) {
            longestDistance = distanceInPixel;
            var newPositionX = positionX;
            var newPositionY = positionY;

            if (centerDoorX > newPositionX)
              newPositionX = newPositionX + this.cellSize;
            else newPositionX = newPositionX - this.cellSize;

            if (centerDoorY > newPositionY)
              newPositionY = newPositionY + this.cellSize;
            else newPositionY = newPositionY - this.cellSize;

            longestPosition = {
              x: newPositionX,
              y: newPositionY,
            };
          }
        }
      }
      return longestPosition;
    },

    pointInRectangle(point, rectangle) {
      const [x, y] = point;
      const [x1, y1, x2, y2, x3, y3, x4, y4] = rectangle;

      const minX = Math.min(x1, x3);
      const maxX = Math.max(x1, x3);
      const minY = Math.min(y1, y3);
      const maxY = Math.max(y1, y3);

      return x >= minX && x <= maxX && y >= minY && y <= maxY;
    },


    adjustBoundary(bounds, direction) {
      let cell;
      if (direction === "min") {
        cell = this.getCellFromGrid(bounds.minX, bounds.minY);
        while (this.isWall(cell.i, cell.j)) {
          bounds.minX += this.cellSize;
          bounds.minY += this.cellSize;
          cell = this.getCellFromGrid(bounds.minX, bounds.minY);
        }
      } else {
        // direction === 'max'
        cell = this.getCellFromGrid(bounds.maxX, bounds.maxY);
        while (this.isWall(cell.i, cell.j)) {
          bounds.maxX -= this.cellSize;
          bounds.maxY -= this.cellSize;
          cell = this.getCellFromGrid(bounds.maxX, bounds.maxY);
        }
      }
    },
    getLongestPosition(currentRoom, lookByExitDoor = false) {
      let tdLongestPositionBy = this.drawingMeta.travelDistancePointStartAlgorithm;

      if (!currentRoom.roomDoors || currentRoom.roomDoors.length == 0)
        return null;

      let tilesShortestDistances = [];

      // Define bounds of the room
      const bounds = {
        minX: Math.min(...currentRoom.points.filter((_, idx) => idx % 2 === 0)),
        maxX: Math.max(...currentRoom.points.filter((_, idx) => idx % 2 === 0)),
        minY: Math.min(...currentRoom.points.filter((_, idx) => idx % 2 !== 0)),
        maxY: Math.max(...currentRoom.points.filter((_, idx) => idx % 2 !== 0)),
      };

      // Adjust the boundaries to avoid walls
      this.adjustBoundary(bounds, "min");
      this.adjustBoundary(bounds, "max");

      // Get all the final exit doors
      const exitDoors = this.obj.doors.filter(door => door.isFinalExitDoor);
      const roomDoors = currentRoom.roomDoors;

      if (lookByExitDoor) {
        // Loop through each cell in the room within the bounds
        for (let x = bounds.minX; x <= bounds.maxX; x += this.cellSize) {
          for (let y = bounds.minY; y <= bounds.maxY; y += this.cellSize) {

            // point
            let tileCenter = [Math.round(x + this.cellSize / 2), Math.round(y + this.cellSize / 2)];

            // Check if the point is inside the polygon that represents the room

            // point
            if (!this.pointInPolygon(tileCenter, currentRoom.points)) continue;

            // Retrieve the cell from the grid and skip if it's a wall
            let cell = this.getCellFromGrid(tileCenter[0], tileCenter[1]);
            if (this.isWall(cell.i, cell.j)) continue;

            let shortestDistanceToExit = Infinity;

            // Check distance to each exit door from the current tile
            for (let exitDoor of exitDoors) {

              // point
              let centerDoorX = (exitDoor.points[0] + exitDoor.points[2]) / 2;
              let centerDoorY = (exitDoor.points[1] + exitDoor.points[3]) / 2;

              let tileCenterCell = this.getCellFromGrid(tileCenter[0], tileCenter[1]);
              let centerDoorCell = this.getCellFromGrid(centerDoorX, centerDoorY);

              let distance = -Infinity
              if (tdLongestPositionBy == 'euclidean') {
                distance = drawing.getDistanceInPixel(
                  centerDoorX,
                  centerDoorY,
                  tileCenter[0],
                  tileCenter[1],
                );
              }
              else {
                let generator = new PathGenerator(
                  { x: parseInt(tileCenter[0] / this.cellSize), y: parseInt(tileCenter[1] / this.cellSize) },
                  { x: parseInt(centerDoorX / this.cellSize), y: parseInt(centerDoorY / this.cellSize) },
                  this.map
                );
                generator.generate();

                let generatorPath = generator.path.flat();
                const points = generatorPath
                  .map((p) => [this.cellSize * p.y, this.cellSize * p.x])
                  .flat();

                const pathObj = {
                  points: points,
                  generatorPath: generator.path,
                };

                distance = this.getDistanceInMeter(pathObj);
              }
              if (distance < shortestDistanceToExit) {
                shortestDistanceToExit = distance;
              }
            }

            // Push the tile and its shortest distance to an exit into the array
            tilesShortestDistances.push({
              x: tileCenter[0],
              y: tileCenter[1],
              distance: shortestDistanceToExit,
            });
          }
        }



        // Find the tile with the maximum shortest distance to an exit
        let worstCaseTile = tilesShortestDistances.reduce((acc, tile) =>
          tile.distance > acc.distance ? tile : acc
        );

        // Return the tile coordinates that represent the worst-case position
        return {
          x: worstCaseTile.x,
          y: worstCaseTile.y,
        };
      }
      else {
        for (let x = bounds.minX; x <= bounds.maxX; x += this.cellSize) {
          for (let y = bounds.minY; y <= bounds.maxY; y += this.cellSize) {

            // point
            let tileCenter = [Math.round(x + this.cellSize / 2), Math.round(y + this.cellSize / 2)];

            // Check if the point is inside the polygon that represents the room

            // point
            if (!this.pointInPolygon(tileCenter, currentRoom.points)) continue;

            // Retrieve the cell from the grid and skip if it's a wall
            let cell = this.getCellFromGrid(tileCenter[0], tileCenter[1]);
            if (this.isWall(cell.i, cell.j)) continue;

            let shortestDistanceToExit = Infinity;

            // Check distance to each exit door from the current tile
            for (let roomDoor of roomDoors) {
              let distance = Infinity;
              // point
              let centerDoorX = (roomDoor.doors.points[0] + roomDoor.doors.points[2]) / 2;
              let centerDoorY = (roomDoor.doors.points[1] + roomDoor.doors.points[3]) / 2;

              let tileCenterCell = this.getCellFromGrid(tileCenter[0], tileCenter[1]);
              let centerDoorCell = this.getCellFromGrid(centerDoorX, centerDoorY);

              if (tdLongestPositionBy == 'euclidean') {
                distance = drawing.getDistanceInPixel(
                  centerDoorX,
                  centerDoorY,
                  tileCenter[0],
                  tileCenter[1],
                );
              }
              else {
                let generator = new PathGenerator(
                  { x: parseInt(tileCenter[0] / this.cellSize), y: parseInt(tileCenter[1] / this.cellSize) },
                  { x: parseInt(centerDoorX / this.cellSize), y: parseInt(centerDoorY / this.cellSize) },
                  this.map
                );
                generator.generate();

                let generatorPath = generator.path.flat();
                const points = generatorPath
                  .map((p) => [this.cellSize * p.y, this.cellSize * p.x])
                  .flat();

                const pathObj = {
                  points: points,
                  generatorPath: generator.path,
                };

                distance = this.getDistanceInMeter(pathObj);

              }


              // console.log(centerDoorX,
              //   centerDoorY,
              //   tileCenter[0],
              //   tileCenter[1])
              // console.log(distance)

              // // let generator = new PathGenerator(
              // //   { x: parseInt(tileCenter[0] / this.cellSize), y: parseInt(tileCenter[1] / this.cellSize) },
              // //   { x: parseInt(centerDoorX / this.cellSize), y: parseInt(centerDoorY / this.cellSize) },
              // //   this.map
              // // );
              // // generator.generate();

              // // let generatorPath = generator.path.flat();
              // // const points = generatorPath
              // //   .map((p) => [this.cellSize * p.y, this.cellSize * p.x])
              // //   .flat();

              // // const pathObj = {
              // //   points: points,
              // //   generatorPath: generator.path,
              // // };

              // const distance = this.getDistanceInMeter(pathObj);

              // console.log("Distance: ", distance,"CenterTile: ",{ x: parseInt(tileCenter[0] / this.cellSize), y: parseInt(tileCenter[1] / this.cellSize) },'Door', JSON.stringify({ x: parseInt(centerDoorX / this.cellSize), y: parseInt(centerDoorY / this.cellSize) }))
              if (distance < shortestDistanceToExit) {
                shortestDistanceToExit = distance;
              }
            }

            // Push the tile and its shortest distance to an exit into the array
            tilesShortestDistances.push({
              x: tileCenter[0],
              y: tileCenter[1],
              distance: shortestDistanceToExit,
            });
          }
        }



        // Find the tile with the maximum shortest distance to an exit
        let worstCaseTile = tilesShortestDistances.reduce((acc, tile) =>
          tile.distance > acc.distance ? tile : acc
        );

        // Return the tile coordinates that represent the worst-case position
        return {
          x: worstCaseTile.x,
          y: worstCaseTile.y,
        };
      }
    },

    resetObj() {
      // var self = this;
      // if (self.obj._id) {
      //   self.api
      //     .get(self.$route.params.id)
      //     .then((response) => {
      //       self.obj = response;
      //       self.refreshTableCorridorEnd();
      //       if (
      //         self.obj.scale == null ||
      //         self.obj.scale.distanceInMeterForOnePixel == 0
      //       ) {
      //         self.toast(
      //           "Warning",
      //           "Please check your scale setting",
      //           "warning"
      //         );
      //       }

      //       if (this.obj.doors.length !== 0) {
      //         this.obj.doors.forEach((door) => {
      //           if (
      //             door.doorType === "swingIn" ||
      //             door.doorType === "swingOut" ||
      //             door.doorType === "swingBoth"
      //           ) {
      //             if (door.doorDirection === "swingLeft") {
      //               this.swingDoor = this.leftSwingDoor;
      //             } else {
      //               this.swingDoor = this.rightSwingDoor;
      //             }
      //           } else if (door.doorType === "folding") {
      //             this.swingDoor = this.foldingDoor;
      //           } else if (door.doorType === "openPath") {
      //             this.swingDoor = this.openPathDoor;
      //           } else if (door.doorType === "sliding") {
      //             this.swingDoor = this.slidingDoor;
      //           } else if (door.doorType === "doubleDoor") {
      //             this.swingDoor = this.doubleDoor;
      //           }

      //           if (door.fireRatingId) {
      //             const matchingFireRating = self.fireRatings.find(
      //               (fr) => fr.id === door.fireRatingId
      //             );
      //             if (matchingFireRating) {
      //               const fireRatingMinutes = matchingFireRating.minutes;
      //               const fireRatingImageKey = `fr${fireRatingMinutes}Door`;
      //               door.fireRatingDoorImg = this[fireRatingImageKey];
      //             }
      //           }

      //           door.image = this.swingDoor;
      //           door.x = door.posX;
      //           door.y = door.posY;
      //         });
      //       }
      //       setTimeout(() => {
      //         this.changeRect();
      //       }, 1000);
      //       this.kreoScale.x = response.kreoXScale ?? 1.352;
      //       this.kreoScale.y = response.kreoYScale ?? 1.352;

      //       this.$parent.$refs.wpMenuMain.topBtnIsEnabled = false;
      //     })
      //     .catch((err) => {
      //       self.toast("Error", err, "danger");
      //     });
      // }
      this.$parent.resetObj();
      this.$parent.$refs.wpMenuMain.topBtnIsEnabled = false;
    },

    onRemoveRoomDoor(item) {
      helper.removeObjFromArray(this.roomSettingObj.roomDoors, item);
      this.refreshTableDoor();
    },
    onRemoveSubRoom(item) {
      helper.removeObjFromArray(this.roomSettingObj.subRooms, item);
      this.refreshTableRoom();
    },
    onAddRoomDoor() {
      var self = this;
      self.refreshTableDoor();
      self.activePanel = 'roomDoorSearchPopup';
    },
    onAddSubRoom() {
      var self = this;
      self.refreshTableRoom();
      self.activePanel = 'roomSearchPopup';
    },
    onAddTravelDistance() {
      var self = this;
      self.refreshTableTravelDistance();
      self.travelDistanceSearchPopup = true;
    },
    onAddCommonPath() {
      var self = this;
      self.refreshTableCommonPath();
      self.commonPathSearchPopup = true;
    },
    onRoomDoorSelected(item, index) {
      var self = this;
      var selectedDoor = item;
    },

    refreshTableDoor() {
      var self = this;
      self.doorApi
        .getListByFloorPlanId(self.obj._id)
        .then((response) => {
          self.doorList = response.results;
          for (let i = 0; i < self.roomDoorList.length; i++) {
            const roomDoor = self.roomDoorList[i];
            const matchingDoor = self.doorList.find(
              (door) => door.id === roomDoor.doorId
            );

            if (matchingDoor) {
              self.$set(matchingDoor, "_selected", true);
            }
          }
        })
        .catch((err) => {
          self.toast("Error", err, "danger");
          console.error(err);
        });
    },
    refreshTableRoom() {
      var self = this;
      self.roomApi
        .getListByFloorPlanId(self.obj._id)
        .then((response) => {
          self.roomList = response.rooms;

          response.rooms.forEach((room) => {
            self.roomListOptions.push({
              value: room._id,
              label: room.name,
            })
          })

          // for (let i = 0; i < self.roomDoorList.length; i++) {
          //   const roomDoor = self.roomDoorList[i];
          //   const matchingDoor = self.doorList.find(
          //     (door) => door.id === roomDoor.doorId
          //   );

          //   if (matchingDoor) {
          //     self.$set(matchingDoor, "_selected", true);
          //   }
          // }
        })
        .catch((err) => {
          self.toast("Error", err, "danger");
          console.error(err);
        });
    },
    refreshTableTravelDistance() {
      var self = this;

      self.travelDistanceApi
        .getListByFloorPlanId(self.obj._id)
        .then((res) => {
          self.travelDistanceList = res
        })
        .catch((err) => {
          self.toast("Error", err.data.message, "danger");
          console.error(err);
        })
    },
    refreshTableCommonPath() {
      var self = this;

      self.commonPathApi
        .getListByFloorPlanId(self.obj._id)
        .then((res) => {
          self.commonPathList = res.results
        })
        .catch((err) => {
          self.toast("Error", err.data.message, "danger");
          console.error(err);
        })
    },
    refreshTableCorridorEnd() {
      var self = this;

      self.corridorEndListOptions = [
        {
          label: 'Please select corridor end',
          value: null
        }
      ];

      self.corridorEndApi
        .getListByFloorPlanId(self.obj._id)
        .then((response) => {
          response.results.forEach((corridorEnd) => {
            self.corridorEndListOptions.push({
              value: corridorEnd.id,
              label: corridorEnd.name,
            })
          })
        })
        .catch((err) => {
          self.toast("Error", err.data.message, "danger");
          console.error(err);
        })
    },
    zoomReset() {
      var stage = this.$refs.stage.getStage();
      stage.scale({ x: 0.8, y: 0.8 });
      this.zoomPercentage = 100;
    },
    zoomIn() {
      this.scaleBy(0.05);
    },
    zoomOut() {
      if (this.zoomPercentage > 5) {
        this.scaleBy(-0.05);
      }
    },
    scaleBy(scale) {
      var stage = this.$refs.stage.getStage();
      var oldScale = stage.scaleX();
      var newScale = oldScale + scale; // oldScale + scale;
      this.zoomPercentage = newScale * 100;
      stage.scale({ x: newScale, y: newScale });
    },
    toast(header, message, color) {
      var self = this;
      self.infoList.push({
        // id: moment(this.obj.paymentDate).format("YYYYMMDDTHHmmss.fff"),
        header: header,
        message: message,
        color: color,
      });
    },

    getAnchors(item) {
      var anchors = [];
      var room = item;
      for (let i = 0; i < room.points.length; i += 2) {
        var anchorCode = room.code + "_points_" + i;
        anchors.push({
          roomId: room.id,
          roomCode: room.code,
          code: anchorCode,
          pointFirstIndex: i,
          x: room.points[i],
          y: room.points[i + 1],
        });
      }
      return anchors;
    },

    updatePoly(event) {
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;
      const roomId = event.target.attrs.roomId;
      const pointFirstIndex = event.target.attrs.pointFirstIndex;
      const room = this.obj.rooms.find((i) => i.id === roomId);

      room.points[pointFirstIndex] = x;
      room.points[pointFirstIndex + 1] = y;
    },

    getLineAnchors(item) {
      var anchors = [];
      var line = item;
      for (let i = 0; i < line.points.length; i += 2) {
        var anchorCode = line.code + "_points_" + i;
        anchors.push({
          lineId: line._id,
          lineCode: line.code,
          code: anchorCode,
          pointFirstIndex: i,
          x: line.points[i],
          y: line.points[i + 1],
        });
      }
      return anchors;
    },

    updatePolyLine(type, event) {
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;
      const lineCode = event.target.attrs.lineCode;
      const pointFirstIndex = event.target.attrs.pointFirstIndex;
      const line = this[type].find((i) => i.code === lineCode);

      line.points[pointFirstIndex] = x;
      line.points[pointFirstIndex + 1] = y;
    },

    // handleClickOnPolygonNode(event) {
    //   // const id = event.target.id();
    //   // const item = this.computedRoomsAnchor.find((i) => i.id === id);
    //   // const index = this.computedRoomsAnchor.indexOf(item);
    //   // if (index === 0) {
    //   //   this.closed = true;
    //   //   this.isPolygonFinished = true;
    //   //   this.anchors[this.anchors.length - 1].stroke = "black";
    //   // }
    // },
    getLineInfo(item) {
      return item.name;
    },
    onNewOccupantLoadFactorConfirmation() {
      var self = this;
      console.warn(self.newOccupantLoadFactorObj);
      self.newOccupantLoadFactorObj.buildingCodeId = self.selectedBuildingCode
        .id
        ? self.selectedBuildingCode.id.toString()
        : "";
      self.newOccupantLoadFactorObj.olfMainCategoryId =
        self.selectedMainCategory.id.toString();
      self.newOccupantLoadFactorObj.olfSubCategoryId =
        self.selectedSubCategory.id.toString();
      self.newOccupantLoadFactorObj.ownerId = self.obj.owner;

      self.newOccupantLoadFactorObj.areaId = self.selectedOlfArea.id;
      if (!self.newOccupantLoadFactorObj.id) {
        this.occupantLoadFactorApi
          .create(self.newOccupantLoadFactorObj)
          .then((response) => {
            //self.$router.push({ path: "/tenant/occupantLoadFactorList" });
            // self.toast("Success", "Added", "success");
            self.addNewOccupantLoadFactorPopup = false;
            self.refreshTableOccupantLoadFactor();
          })
          .catch(({ data }) => {
            self.toast("Error", data.message, "danger");
          });
      } else {
        this.occupantLoadFactorApi
          .update(self.newOccupantLoadFactorObj)
          .then((response) => {
            //self.$router.push({ path: "/tenant/occupantLoadFactorList" });
            self.addNewOccupantLoadFactorPopup = false;
          })
          .catch(({ data }) => {
            self.toast("Error", data, "danger");
          });
      }
    },
    addNewMainCategory() {
      this.newMainCategoryObj.code = "";
      this.newMainCategoryObj.name = "";
      this.newMainCategoryObj.description = "";

      this.addNewMainCategoryPopup = true;
    },
    addNewSubCategory() {
      this.newSubCategoryObj.code = "";
      this.newSubCategoryObj.name = "";
      this.newSubCategoryObj.description = "";

      this.addNewSubCategoryPopup = true;
    },
    addNewBuildingCode() {
      this.newBuildingCodeObj.code = "";
      this.newBuildingCodeObj.name = "";
      this.newBuildingCodeObj.description = "";

      this.addNewBuildingCodePopup = true;
    },

    onNewMainCategoryConfirmation() {
      this.onSubmitMainCategory();
      this.addNewMainCategoryPopup = false;
    },

    onSubmitMainCategory() {
      var self = this;
      if (!self.newMainCategoryObj.id) {
        this.olfMainCategoryApi
          .create(self.newMainCategoryObj)
          .then((response) => {
            self.refreshTableMainCategory();
            // self.$router.push({ path: "/tenant/buildingRuleList" });
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      } else {
        this.api
          .update(self.newMainCategoryObj)
          .then((response) => {
            self.refreshTableMainCategory();

            // self.$router.push({ path: "/tenant/buildingRuleList" });
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
    },

    onNewSubCategoryConfirmation() {
      this.onSubmitSubCategory();
      this.addNewSubCategoryPopup = false;
    },

    onSubmitSubCategory() {
      var self = this;
      if (!self.newSubCategoryObj.id) {
        this.olfSubCategoryApi
          .create(self.newSubCategoryObj)
          .then((response) => {
            self.refreshTableSubCategory();
            // self.$router.push({ path: "/tenant/buildingRuleList" });
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      } else {
        this.api
          .update(self.newSubCategoryObj)
          .then((response) => {
            self.refreshTableSubCategory();

            // self.$router.push({ path: "/tenant/buildingRuleList" });
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
    },

    onNewBuildingCodeConfirmation() {
      this.onSubmitBuildingCode();
      this.addNewBuildingCodePopup = false;
    },

    onSubmitBuildingCode() {
      var self = this;
      if (!self.newBuildingCodeObj.id) {
        this.buildingCodeApi
          .create(self.newBuildingCodeObj)
          .then((response) => {
            self.refreshTableBuildingCode();
            // self.$router.push({ path: "/tenant/buildingRuleList" });
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      } else {
        this.api
          .update(self.newBuildingCodeObj)
          .then((response) => {
            self.refreshTableBuildingCode();

            // self.$router.push({ path: "/tenant/buildingRuleList" });
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
    },
    onSearchBuildingCode() {
      var self = this;
      self.refreshTableBuildingCode();
      self.buildingCodeSearchPopup = true;
    },
    onBuildingCodeSelected(item, index) {
      var self = this;
      self.selectedBuildingCode = item;
      self.obj.buildingCodeId = item.id.toString();
      self.buildingCodeSearchPopup = false;
    },
    refreshTableBuildingCode() {
      var self = this;
      self.loading = true;
      self.buildingCodeList = [];
      self.buildingCodeApi
        .getList()
        .then((response) => {
          self.buildingCodeList = response.results;
          self.loading = false;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },

    onSearchMainCategory() {
      var self = this;
      self.refreshTableMainCategory();
      self.mainCategorySearchPopup = true;
    },
    onMainCategorySelected(item, index) {
      var self = this;
      self.selectedMainCategory = item;
      self.obj.olfMainCategoryId = item.id.toString();
      //self.obj.map = self.mapList[index];

      // self.selectedLocation = {
      //   id: null,
      //   name: "",
      //   code: "",
      // };
      self.mainCategorySearchPopup = false;
    },
    refreshTableMainCategory() {
      var self = this;
      self.loading = true;
      self.mainCategoryList = [];
      self.olfMainCategoryApi
        .getList()
        .then((response) => {
          self.mainCategoryList = response.results;
          self.loading = false;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },

    onSearchSubCategory() {
      var self = this;
      self.refreshTableSubCategory();
      self.subCategorySearchPopup = true;
    },
    onSearchOlfArea() {
      var self = this;
      self.refreshTableOlfArea();
      self.olfAreaSearchPopup = true;
    },
    onSubCategorySelected(item, index) {
      var self = this;
      self.selectedSubCategory = item;
      self.obj.olfSubCategoryId = item.id.toString();
      self.subCategorySearchPopup = false;
    },
    onOlfAreaSelected(item, index) {
      var self = this;
      self.selectedOlfArea = item;
      self.olfAreaSearchPopup = false;
    },
    refreshTableSubCategory() {
      var self = this;
      self.loading = true;
      self.mainCategoryList = [];
      self.olfSubCategoryApi
        .getList()
        .then((response) => {
          self.subCategoryList = response.results;
          self.loading = false;
        })
        .catch(({ data }) => {
          self.toast("Error", data, "danger");
        });
    },
    refreshTableOlfArea() {
      var self = this;
      self.loading = true;
      self.areasApi
        .getList()
        .then((response) => {
          console.warn(response);
          self.olfAreaList = response.results;
          self.loading = false;
        })
        .catch(({ data }) => {
          self.toast("Error", data, "danger");
        });
    },

    getBuildingCodeName(item) {
      if (item.buildingcode) return item.buildingcode.code;
      else return "N/A";
    },
    addNewOccupantLoadFactor() {
      this.addNewOccupantLoadFactorPopup = true;
    },
    getMainCategoryName(item) {
      if (item.olfMainCategory) return item.olfMainCategory.name;
      return "N/A";
    },
    getSubCategoryName(item) {
      if (item.area) return item.area.name;
      return "N/A";
    },
    onSearchOccupantLoadFactor() {
      var self = this;
      if (this.obj.buildingCode) {
        self.refreshTableOccupantLoadFactor();
        self.occupantLoadFactorSearchPopup = true;
      } else {
        this.$parent.workSettingPopup = true;
        this.toast("Error", "Please select building code", "danger");
      }
    },
    // onOccupantLoadFactorSelected(item, index) {
    //   var self = this;
    //   alert('asds')
    //   self.selectedOccupantLoadFactor = item;
    //   self.roomSettingObj.occupantLoadFactorId = item.id.toString();

    //   self.occupantLoadFactorSearchPopup = false;
    // },
    refreshTableOccupantLoadFactor() {
      var self = this;
      self.occupantLoadFactorApi
        .getList()
        .then((response) => {
          self.occupantLoadFactorList = response.occupantLoadFactors;
        })
        .catch(({ data }) => {
          self.toast("Error", data, "danger");
        });
    },
    onOccupantLoadFactorSelected(item, index) {
      var self = this;
      if (item.olfValue) {
        self.selectedOccupantLoadFactor = item;
        self.roomSettingObj.occupantLoadFactorId = item._id;
        self.occupantLoadFactorSearchPopup = false;
      } else {
        self.insertGlaModal = true;
        self.glaObj = item;
      }
    },
    handleGlaValue() {
      const glaValue = parseFloat(this.obj.glaValue);

      if (isNaN(glaValue) || glaValue === '') {
        const errorMessage = 'Please enter a valid number for GLA value';
        this.toast("Error", errorMessage, "danger");
        return;
      }

      this.selectedOccupantLoadFactor = this.glaObj;
      this.roomSettingObj.occupantLoadFactorId = this.glaObj._id;
      this.obj.isGLA = true;
      this.obj.glaValue = glaValue;
      this.insertGlaModal = false;
      this.occupantLoadFactorSearchPopup = false;
      this.activePanel = null;
      this.handleSave();
    },
    obstacleLabelClick(item) {
      if (this.drawType == "") this.obstacleSettingClick(item);
    },
    obstacleSettingClick(item) {
      var self = this;
      self.obstacleSettingObj = item;
      this.obstacleSettingPopup = true;
    },
    onObstacleSettingDelete() {
      var self = this;

      this.obstacleApi
        .delete(self.obstacleSettingObj._id)
        .then((response) => {
          self.toast("Info", "Delete Success", "success");
          self.resetObj();
          this.activePanel = null;
        })
        .catch(({ data }) => {
          self.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    // onObstacleSettingConfirmation() {
    //   var self = this;
    //   this.obstacleApi
    //     .update(self.obstacleSettingObj)
    //     .then((response) => {
    //       self.toast("Info", "Update Success", "success");
    //       this.$router.go();
    //     })
    //     .catch(({ data }) => {
    //       self.toast("Error", helper.getErrorMessage(data), "danger");
    //     });
    // },

    roomLabelClick(item, index) {
      if (this.drawType == "") this.roomSettingClick(item, index);
    },
    doorLabelClick(item) {
      if (this.drawType == "") this.doorSettingClick(item);
    },
    exitAreaLabelClick(item) {
      if (this.drawType == "") this.exitAreaSettingClick(item);
    },
    exitStairWidthLabelClick(item) {
      if (this.drawType == "") this.exitStairWidthSettingClick(item);
    },

    diagonalDistanceLabelClick(item) {
      if (this.drawType == "") this.diagonalDistanceSettingClick(item);
    },
    commonPathLabelClick(item) {
      if (this.drawType == "") this.commonPathSettingClick(item);
    },
    deadEndLabelClick(item) {
      if (this.drawType == "") this.deadEndSettingClick(item);
    },
    corridorEndLabelClick(item) {
      if (this.drawType == "") this.corridorEndSettingClick(item);
    },
    travelDistanceLabelClick(item) {
      if (this.drawType == "") this.travelDistanceSettingClick(item);
    },
    obstacleLabelClick(item) {
      if (this.drawType == "") this.obstacleSettingClick(item);
    },

    exitSeperationLabelClick(item) {
      if (this.drawType == "") this.exitSeperationSettingClick(item);
    },
    exitDoorLabelClick(item) {
      if (this.drawType == "") this.exitDoorSettingClick(item);
    },

    doorSettingClick(item) {
      var self = this;
      self.doorSettingObj = item;
      self.activePanel = "doorSettingPopup";
    },
    exitAreaSettingClick(item) {
      var self = this;
      self.exitAreaSettingObj = item;
      self.activePanel = "exitAreaSettingPopup";
    },
    exitStairWidthSettingClick(item) {
      var self = this;
      self.exitStairWidthSettingObj = item;
      self.activePanel = "exitStairWidthSettingPopup";
    },

    exitSeperationSettingClick(item) {
      var self = this;
      self.exitSeperationSettingObj = item;
      this.exitSeperationSettingPopup = true;
    },
    diagonalDistanceSettingClick(item) {
      var self = this;
      self.diagonalDistanceSettingObj = item;
      this.diagonalDistanceSettingPopup = true;
    },
    commonPathSettingClick(item) {
      var self = this;
      self.commonPathSettingObj = item;
      self.activePanel = "commonPathSettingPopup";
    },
    deadEndSettingClick(item) {
      var self = this;
      self.deadEndSettingObj = item;
      self.activePanel = "deadEndSettingPopup";
    },
    corridorEndSettingClick(item) {
      var self = this;
      self.corridorEndSettingObj = item;
      self.activePanel = "corridorEndSettingPopup";
    },
    travelDistanceSettingClick(item) {
      var self = this;
      self.travelDistanceSettingObj = item;
      self.activePanel = "travelDistanceSettingPopup";
    },

    refreshTravelDistance() {
      this.resetObj();
    },

    obstacleSettingClick(item) {
      var self = this;
      self.obstacleSettingObj = item;
      self.activePanel = "obstacleSettingPopup";
    },

    exitDoorSettingClick(item, index) {
      var self = this;
      self.exitDoorSettingObj = item;
      self.activePanel = "exitDoorSetting";
    },
    roomSettingClick(item, index) {
      var self = this;
      self.roomSettingObj = item;
      self.roomSettingIndex = index;
      self.selectedOccupantLoadFactor = item.occupantLoadFactor?.olfMainCategory?.name || null;
      self.roomDoorList = self.roomSettingObj.roomDoors;
      self.subRoomList = self.roomSettingObj.subRooms;

      if (self.roomSettingObj.buildingCodeId) {
        self.olfMainCategoryApi
          .get(self.roomSettingObj.buildingCodeId)
          .then((response) => {
            self.selectedMainCategory = response.results;
          })
          .catch(({ data }) => {
            self.toast("Error", helper.getErrorMessage(data), "danger");
          });
      }
      self.activePanel = "roomSettingPopup";
    },
    onRoomSettingConfirmation(param) {
      const roomEffectivePercentage = parseFloat(param.effectivePercentage) ?? null;

      let payload = {
        points: param.points,
        closed: param.closed,
        draggable: param.draggable,
        pointerAtBeginning: param.pointerAtBeginning,
        pointerAtEnding: param.pointerAtEnding,
        floorPlanId: param.floorPlanId,
        code: param.code,
        name: param.name,
        stroke: param.stroke,
        strokeWidth: param.strokeWidth,
        fill: param.field,
        roomDoors: this.roomDoorList,
        subRoomId: (this.subRoomList?.map(subRoom => subRoom._id)) ?? [],
        // travelDistanceId: this.roomTravelDistanceList?.[0]?._id || null,
        // commonPathDistanceId: this.roomCommonPathList?.[0]?.id || null,
        effectivePercentage: roomEffectivePercentage,
        occupantLoadFactorId: param.occupantLoadFactorId
          ? param.occupantLoadFactorId
          : null,
      };

      var self = this;
      if (self.roomSettingObj.minimumNumberOfExitId == "")
        self.roomSettingObj.minimumNumberOfExitId = null;

      if (self.roomSettingObj.occupantLoadFactorId == "")
        self.roomSettingObj.occupantLoadFactorId = null;

      self.roomSettingObj.roomDoors = self.roomDoorList;

      this.roomApi
        .update(param, payload)
        .then((response) => {
          self.toast("Info", `Successfully update ${response.name}`, "success");
          self.resetObj();
          this.activePanel = null;
        })
        .catch(({ data }) => {
          self.toast("Error", data.message, "danger");
        });
    },
    onRoomSettingDelete() {
      var self = this;
      if (self.roomSettingObj.minimumNumberOfExitId == "")
        self.roomSettingObj.minimumNumberOfExitId = null;

      const roomId = self.roomSettingObj.code;
      this.roomApi
        .delete(self.roomSettingObj._id)
        .then(() => {
          self.toast("Info", "Delete Success", "success");
          self.resetObj();
          self.activePanel = null;
        })
        .catch(({ data }) => {
          self.toast("Error", data.message, "danger");
        });
      // self.resetObj();
      const obstacles = self.obstacles;
      for (let i = obstacles.length - 1; i >= 0; i--) {
        const obstacle = obstacles[i];
        if (obstacle.code.includes(`auto_gen_${roomId}`)) {
          this.obstacleApi
            .delete(obstacle._id)
            .then(() => {
              self.resetObj();
            })
            .catch(({ data }) => {
              self.toast("Error", data.message, "danger");
            });
          // Delete the obstacle from the array
        }
      }
      self.resetObj();
    },
    quickReload() {
      this.resetObj();
    },
    handleRun() {
      this.$parent.onRunAnalysis();
    },
    quickSave() {
      this.handleSave();
    },
    quickClear() {
      this.clearDrawingsConfirmationModal = true;
    },
    onClearDrawings() {
      this.handleClear();
      this.initSaveHistory();
      this.handleSave();
      this.clearDrawingsConfirmationModal = false;
    },
    settingClick() {
      alert("settingClick");
    },
    toolClick(item) {
      this.removeDoorPreview();
      switch (item) {
        case "roomAuto":
        case "obstacleAuto":
          {
            this.processRoomAuto();
          }
          break;
        case "travelDistanceAuto":
          // this.pageLoading = true;

          this.processTravelDistanceAuto();
          // this.pageLoading = false;
          break;
        case "wallAi":
          this.toolAIClick();
          break;
        case "cancel":
          if (
            this.drawType === "travelDistance" ||
            this.drawType === "commonPath" ||
            this.drawType === "deadEnd"
          ) {
            this.drawingState = "end";
          }
          this.drawTypeSelect(item);
          this.removeDoorPreview();
          this.drawingState = "";
          break;
        default:
          this.drawTypeSelect(item);
          break;
      }
    },
    onCancelTdAuto() {
      this.cancelTdAutoModal = true;
    },
    onTerminatePathGeneratorWorker() {
      if (this.worker) {
        this.worker.terminate();
        this.worker = null;
      }
      this.elapsedTimeInfo = '';
      clearInterval(this.tdAiCountInterval);
      this.cancelTdAutoModal = false;
      this.tdAutoModal = false;
    },
    formatElapsedTime(totalSeconds) {
      const hours = Math.floor(totalSeconds / 3600);
      const minutes = Math.floor((totalSeconds % 3600) / 60);
      const seconds = Math.floor(totalSeconds % 60);

      let formattedTime = '';
      if (hours > 0) {
        formattedTime += `${hours} hours `;
      }
      if (minutes > 0 || hours > 0) {
        formattedTime += `${minutes} minutes `;
      }
      if (seconds > 0 || minutes > 0 || hours === 0) {
        formattedTime += `${seconds} seconds`;
      }

      return formattedTime.trim(); // Trim to remove extra spaces
    },
    startProgressIncrement() {
      const totalObjects = this.grid
        .map((row) => row.length)
        .reduce((acc, count) => acc + count, 0);

      const delayMap = {
        10: 1000,     // < 10% - 1 second delay
        15: 10000,    // < 15% - 10 seconds delay
        20: 20000,    // < 20% - 20 seconds delay
        25: 30000,    // < 25% - 30 seconds delay
        30: 40000,    // < 30% - 40 seconds delay
        35: 50000,    // < 35% - 50 seconds delay
        40: 60000,    // < 40% - 60 seconds (1 minute) delay
        45: 70000,    // < 45% - 70 seconds delay
        50: 80000,    // < 50% - 80 seconds delay
        55: 90000,    // < 55% - 90 seconds delay
        60: 100000,   // < 60% - 100 seconds delay
        65: 110000,   // < 65% - 110 seconds delay
        70: 120000,   // < 70% - 120 seconds (2 minutes) delay
        75: 180000,   // < 75% - 180 seconds (3 minutes) delay
        80: 2400000,   // < 80% - 240 seconds (40 minutes) delay
        85: 3000000,   // < 85% - 300 seconds (50 minutes) delay
        90: 3600000,   // < 90% - 360 seconds (60 minutes) delay
        95: 4200000,   // < 95% - 420 seconds (70 minutes) delay
        99: 490000    // < 99% - Adjust accordingly for the final delay
      };

      const incrementProgress = () => {
        let delay = 1000; // Default delay in milliseconds

        for (const percentage in delayMap) {
          if (this.tdAutoProgress < parseInt(percentage)) {
            delay = delayMap[percentage];
            break;
          }
        }

        if (this.tdAutoProgress < 99) {
          this.tdAutoProgress += 1;
        } else {
          delay = 0; // No delay if 99% completed
        }

        if (this.tdAutoProgress === 100) {
          return; // Stop when progress reaches 100%
        }

        setTimeout(incrementProgress, delay);
      };

      this.tdAutoProgress = 0;
      incrementProgress();

      let elapsedTimeInMs = 0; // Initialize elapsed time counter in milliseconds

      this.tdAiCountInterval = setInterval(() => {
        elapsedTimeInMs += 100; // Increment elapsed time by 100 milliseconds

        const hours = Math.floor(elapsedTimeInMs / 3600000);
        const minutes = Math.floor((elapsedTimeInMs % 3600000) / 60000);
        const seconds = Math.floor((elapsedTimeInMs % 60000) / 1000);

        let formattedTime = '';

        if (hours > 0) {
          formattedTime += `${hours} hour${hours > 1 ? 's' : ''} `;
        }
        if (minutes > 0 || hours > 0) {
          formattedTime += `${minutes} minute${minutes > 1 ? 's' : ''} `;
        }
        formattedTime += `${seconds}.${Math.floor(elapsedTimeInMs % 1000 / 100)} seconds`;

        this.elapsedTimeInfo = `Elapsed time: ${formattedTime}`;

        if (this.tdAutoProgress === 100) {
          clearInterval(this.tdAiCountInterval); // Stop updating time when progress reaches 100%
        }
      }, 100); // Update every 100 milliseconds
    },


    stopProgressIncrement() {
      clearInterval(this.tdAutoIntervalId);
      this.tdAutoProgress = 100; // Set progress to 100% when the worker is done
    },
    processTravelDistanceAuto() {
      this.showTravelDistancePage = this.drawingMeta.showGrid;
      this.cellSize = parseInt(this.drawingMeta.gridSize);
      const totalObjects = this.grid
        .map((row) => row.length)
        .reduce((acc, count) => acc + count, 0);
      console.warn("total objects,", totalObjects);
      const { rooms, doors, corridorEnds } = this.obj;
      const toastTitle = "Unable to Start Travel Distance AI";
      const toastType = "danger";

      if (rooms.length === 0) {
        this.toast(toastTitle, "Room does not exist", toastType);
        return;
      }

      if (corridorEnds.length === 0 && this.drawingMeta.pathOptions.deadEnd) {
        this.toast(toastTitle, "Unable to generate Dead End: Corridor end does not exist", toastType);
        return;
      }

      if (doors.length === 0) {
        this.toast(toastTitle, "Room door does not exist", toastType);
        return;
      }

      let hasRoomWithDoors = false;

      for (const room of this.obj.rooms) {
        if (room.roomDoors.length > 0) {
          hasRoomWithDoors = true;
          break; // Exit the loop once you find a room with doors
        }
      }

      if (!hasRoomWithDoors) {
        this.toast(
          toastTitle,
          "At least one room should have room doors assigned",
          toastType
        );
        return;
      }

      this.tdAutoMessage.message = 'Generating path. Please wait for completion.'
      this.tdAutoMessage.type = '';
      this.tdGenerated = null;
      this.cpGenerated = null;
      this.deGenerated = null;
      this.tdAutoModal = true;
      this.worker = new PathGeneratorWorker(); // Create a new instance of the worker
      this.startProgressIncrement(); // Start the progress bar increment
      const start = performance.now();

      this.worker.onmessage = (event) => {
        this.markedGrid = [];
        const { travelDistances, commonPaths, deadEnds, markedGrid, centerDoorPositions, walls } = event.data

        this.tdGenerated = `Travel Distances Generated: ${travelDistances.length}`
        this.cpGenerated = `Common Paths Generated: ${commonPaths.length}`
        this.deGenerated = `Dead Ends Generated: ${deadEnds.length}`

        this.travelDistances.push(...travelDistances);
        this.commonPaths.push(...commonPaths);
        this.deadEnds.push(...deadEnds);
        this.markedGrid.push(...markedGrid);
        this.centerDoorPositions.push(...centerDoorPositions);
        this.walls.push(...walls);
        
        if (
          travelDistances.length === 0 &&
          commonPaths.length === 0 &&
          deadEnds.length === 0
        ) {
          this.tdAutoMessage = {
            message: `No Path Generated. Please check your drawing and try again.`,
            type: 'danger'
          }
        } else {
          this.tdAutoMessage = {
            message: `Path Successfully Generated`,
            type: 'success'
          }
        }

        this.stopProgressIncrement();
      };


      this.worker.onerror = (event) => {
        let message = "There is an error with your worker! " + event.message;
        console.log(message);
        this.tdAutoMessage = {
          message: `No Path Generated. Please check your drawing and try again.`,
          type: 'danger'
        };
        this.stopProgressIncrement();
      };


      let doorsParam = [...this.obj.doors]
      doorsParam.forEach((door) => {
        delete door.imageURL;
        delete door.image;
      })

      // temp comment this line below
      // this.checkGrid()
      this.worker.postMessage({ 
        action: 'start', 
        isUpdateColor: true,
        floorPlanId: this.obj._id,
        obstaclesData: this.obstacles,
        doorsData: doorsParam,
        roomsData: this.obj.rooms,
        stageWidth: this.originalStageSize.width,
        stageHeight: this.originalStageSize.height,
        cellSizeData: this.cellSize,
        wallsData: this.walls,
        mapperData: this.map,
        travelDistancesData: this.travelDistances,
        commonPathsData: this.commonPaths,
        corridorEndsData: this.corridorEnds,
        drawingMetaData: this.drawingMeta,
        tdLoopByData: this.drawingMeta.tdLoopBy,
        travelDistancePointStartAlgorithmData: this.drawingMeta.travelDistancePointStartAlgorithm,
        travelDistanceAlgorithmData: this.drawingMeta.travelDistanceAlgorithm,
        distanceInMeterForOnePixelData: this.obj.scale.distanceInMeterForOnePixel,
      });

      // this.processTravelPathAndCommonPath(); // <--- pass this function inside worker
      // this.processDeadEnd();
    },
    checkGrid() {
      this.prepareGrid(true);
      this.detectObstacle();
      this.detectWallBasedOnDeadEnd();
      this.recognizeWalls();
      this.detectDoor();
      this.clearBlockedDoor();
      this.recognizeEndPos();
    },
    processDeadEnd() {
      this.prepareGrid(true);
      this.detectObstacle();
      this.recognizeWalls();
      this.recognizeCorridorStartPos();
      this.recognizeEndPos();
      this.recognizeDeadEndStartPos();
    },
    processTravelPathAndCommonPath() {
      let aiOptions = this.drawingMeta.pathOptions
      this.prepareGrid(true);
      // this.detectDoor();
      // this.clearBlockedDoor();

      this.detectObstacle();
      this.detectWallBasedOnDeadEnd();
      this.recognizeWalls();
      this.detectDoor();
      this.clearBlockedExitDoor();
      this.clearBlockedDoor();
      this.recognizeEndPos();
      if (aiOptions.travelDistance || aiOptions.commonPath) {
        this.recognizeRoomStartPos();
      }
      if (aiOptions.travelDistance) {
        this.recognizeAllTravelPath();
      }

      if (aiOptions.commonPath) {
        this.recognizeAllCommonPath();
      }
      if (aiOptions.deadEnd) {
        this.detectWallBasedOnDeadEnd();
        this.recognizeWalls();
        this.detectDoor();
        this.removeBlockedRoomDoor();
        this.recognizeDeadEndStartPos();
        this.clearBlockedExitDoor();
        this.clearBlockedDoor();
        this.recognizeAllDeadEndPath();
      }
    },
    startSearchForPath(isUpdateColor) {
      this.processing = true;
      this.travelPathResult = [];
      this.commonPathResult = [];
      let travelDsitancePathFindingAlgo = this.drawingMeta.travelDistanceAlgorithm;

      console.log("Travel Distance Path Finding Searching: ", travelDsitancePathFindingAlgo)
      for (let start = 0; start < this.startPosList.length; start++) {
        let shortestDistance = Infinity;
        let shortestPathForThisStart = null;

        for (let end = 0; end < this.endPosList.length; end++) {
          let generator = new PathGenerator(
            this.startPosList[start],
            this.endPosList[end],
            this.map
          );

          if (travelDsitancePathFindingAlgo == 'aManhattan') {
            generator.setPathFindingAlgo('MANHATTAN')
          }
          if (travelDsitancePathFindingAlgo == 'aTrace') {
            generator.setPathFindingAlgo('TRACE')
          }
          if (travelDsitancePathFindingAlgo == 'breadthFirstSearch') {
            generator.setPathFindingAlgo('BFS')
          }


          try {
            generator.generate();
          }
          catch (error) {
            continue;
          }

          let generatorPath = generator.path.flat();
          const points = generatorPath
            .map((p) => [this.cellSize * p.y, this.cellSize * p.x])
            .flat();

          const pathObj = {
            code: `path${this.travelPathResult.length + 1}`,
            name: `Path ${this.travelPathResult.length + 1}`,
            points: points,
            roomId: this.startPosList[start].roomId,
            generatorPath: generator.path,
          };

          const distance = this.getDistanceInMeter(pathObj);

          if (shortestDistance > distance) {
            shortestDistance = distance;
            shortestPathForThisStart = pathObj;
          }
        }

        // After checking all end positions for this start, save the longest path
        if (shortestPathForThisStart !== null) {
          this.travelPathResult.push(shortestPathForThisStart);
        }

        if (isUpdateColor && shortestPathForThisStart) {
          for (let k = 0; k < shortestPathForThisStart.length; k++) {
            let i = shortestPathForThisStart[k].y;
            let j = shortestPathForThisStart[k].x;
            this.grid[i][j] = {
              code: "spot_" + i + "_" + j,
              name: "spot_" + i + "_" + j,
              width: this.cellSize,
              height: this.cellSize,
              x: this.cellSize * i,
              y: this.cellSize * j,
              stroke: "black",
              strokeWidth: 2,
              closed: true,
              fill: "rgb(0, 0, 255)",
            };
          }
        }
      }
      this.processing = false;
    },


    startSearchForCommonPath(isUpdateColor) {
      this.commonPathResult = []
      let commonPathDistancePathFindingAlgo = this.drawingMeta.commonPathAlgorithm;


      for (let start = 0; start < this.startPosList.length; start++) {
        let shortestDistance = Infinity;
        let shortestPathForThisStart = null;

        for (let end = 0; end < this.endPosList.length; end++) {
          let generator = new PathGenerator(
            this.startPosList[start],
            this.endPosList[end],
            this.map
          );

          if (commonPathDistancePathFindingAlgo == 'aManhattan') {
            generator.setPathFindingAlgo('MANHATTAN')
          }
          if (commonPathDistancePathFindingAlgo == 'aTrace') {
            generator.setPathFindingAlgo('TRACE')
          }
          if (commonPathDistancePathFindingAlgo == 'breadthFirstSearch') {
            generator.setPathFindingAlgo('BFS')
          }


          generator.generate();

          let generatorPath = generator.path.flat();
          const points = generatorPath
            .map((p) => [this.cellSize * p.y, this.cellSize * p.x])
            .flat();

          const pathObj = {
            code: `path${this.commonPathResult.length + 1}`,
            name: `Path ${this.commonPathResult.length + 1}`,
            points: points,
            roomId: this.startPosList[start].roomId,
            generatorPath: generator.path,
          };

          const distance = this.getDistanceInMeter(pathObj);

          if (shortestDistance > distance) {
            shortestDistance = distance;
            shortestPathForThisStart = pathObj;
          }
        }

        // After checking all end positions for this start, save the longest path
        if (shortestPathForThisStart !== null) {
          this.commonPathResult.push(shortestPathForThisStart);
        }

        if (isUpdateColor && shortestPathForThisStart) {
          for (let k = 0; k < shortestPathForThisStart.length; k++) {
            let i = shortestPathForThisStart[k].y;
            let j = shortestPathForThisStart[k].x;
            this.grid[i][j] = {
              code: "spot_" + i + "_" + j,
              name: "spot_" + i + "_" + j,
              width: this.cellSize,
              height: this.cellSize,
              x: this.cellSize * i,
              y: this.cellSize * j,
              stroke: "black",
              strokeWidth: 2,
              closed: true,
              fill: "rgb(0, 0, 255)",
            };
          }
        }
      }

    },


    startSearchForDeadEndPath(isUpdateColor) {
      this.deadEndPathResult = [];
      let deadEndPathFindingAlgo = this.drawingMeta.deadEndAlgorithm;
      for (let start = 0; start < this.deadEndStartPosList.length; start++) {
        let shortestDistance = Infinity;
        let shortestPathForThisStart = null;
        const allPathObj = []
        for (let end = 0; end < this.endPosList.length; end++) {
          let generator = new PathGenerator(
            this.deadEndStartPosList[start],
            this.endPosList[end],
            this.map,
            'BFS'
          );

          if (deadEndPathFindingAlgo == 'aManhattan') {
            generator.setPathFindingAlgo('MANHATTAN')
          }
          if (deadEndPathFindingAlgo == 'aTrace') {
            generator.setPathFindingAlgo('TRACE')
          }
          if (deadEndPathFindingAlgo == 'breadthFirstSearch') {
            generator.setPathFindingAlgo('BFS')
          }



          generator.generate();

          let generatorPath = generator.path.flat();
          const points = generatorPath
            .map((p) => [this.cellSize * p.y, this.cellSize * p.x])
            .flat();

          const pathObj = {
            code: `path${this.deadEndPathResult.length + 1}`,
            name: `Path ${this.deadEndPathResult.length + 1}`,
            points: points,
            generatorPath: generator.path,
            distance: Infinity,
            corridorEndId: this.deadEndStartPosList[start]['corridorEndId']
          };


          let distance = 0
          try {
            distance = this.getDistanceInMeter(pathObj);
            pathObj.distance = distance;
          }
          catch (error) {
            continue;
          }


          allPathObj.push(pathObj)
          // this.deadEndPathResult.push(pathObj);

          // if (shortestDistance > distance) {
          //   shortestDistance = distance;
          //   shortestPathForThisStart = pathObj;
          // }
        }

        const shortestPath = allPathObj.reduce((prev, curr) =>
          (prev.distance < curr.distance ? prev : curr)
        );


        // Filter out paths other than the shortest path
        const otherPaths = allPathObj.filter(path => path !== shortestPath);

        // Find overlapping coordinates in the generatorPath
        const overlappingGeneratorPath = shortestPath.generatorPath.filter(generator =>
          otherPaths.every(path =>
            path.generatorPath.some(gen =>
              gen.x === generator.x && gen.y === generator.y
            )
          )
        );
        // const overlappingGeneratorPath = shortestPath.generatorPath;


        const resultPath = {
          ...shortestPath,
          points: shortestPath.points.filter(point =>
            allPathObj.every(path => path.points.includes(point))
          ),
          generatorPath: overlappingGeneratorPath,
          corridorEndId: this.deadEndStartPosList[start]['corridorEndId']
        };




        shortestDistance = resultPath.distance;
        shortestPathForThisStart = resultPath;


        // After checking all end positions for this start, save the longest path
        if (allPathObj.length >= 1) {
          if (shortestDistance !== Infinity) {
            _ = ''
            this.deadEndPathResult.push(resultPath);
          }

          if (isUpdateColor && shortestPathForThisStart) {
            for (let k = 0; k < shortestPathForThisStart.length; k++) {
              let i = shortestPathForThisStart[k].y;
              let j = shortestPathForThisStart[k].x;
              this.grid[i][j] = {
                code: "spot_" + i + "_" + j,
                name: "spot_" + i + "_" + j,
                width: this.cellSize,
                height: this.cellSize,
                x: this.cellSize * i,
                y: this.cellSize * j,
                stroke: "black",
                strokeWidth: 2,
                closed: true,
                fill: "rgb(0, 0, 255)",
              };
            }
          }
        }
      }
    },
    recognizeDeadEndStartPos() {
      var self = this;
      this.startSearchForDeadEndStartPos(false);
      var prevCommonPath = [];
    },

    getNeighbors(cell) {
      const neighbors = [];
      const possibleMoves = [
        { x: 0, y: -1 },  // Up
        { x: 0, y: 1 },   // Down
        { x: -1, y: 0 },  // Left
        { x: 1, y: 0 },   // Right
      ];

      for (const move of possibleMoves) {
        const newX = cell.j + move.x;
        const newY = cell.i + move.y;


        if (newX >= 0 && newX < this.map[0].length && newY >= 0 && newY < this.map.length) {
          if (this.map[newY][newX] === 0) {

            neighbors.push({ i: newY, j: newX });
          }
          else {
            continue;
          }
        }
      }
      return neighbors;
    },

    checkIfAdjunct(neighborsOfTarget) {
      if (neighborsOfTarget.length === 2) {
        const n1 = neighborsOfTarget[0];
        const n2 = neighborsOfTarget[1];
        const dx = Math.abs(n1.j - n2.j);
        const dy = Math.abs(n1.i - n2.i);

        if (dx === 2 || dy === 2) {
          return True;
        }
        return False;
      }
      return False;
    },

    detectDeadEnds(corridor) {
      const deadEnds = [];

      // Define bounds of the corridor
      const bounds = {
        minX: Math.min(...corridor.filter((_, idx) => idx % 2 === 0)),
        maxX: Math.max(...corridor.filter((_, idx) => idx % 2 === 0)),
        minY: Math.min(...corridor.filter((_, idx) => idx % 2 !== 0)),
        maxY: Math.max(...corridor.filter((_, idx) => idx % 2 !== 0)),
      };

      for (let x = bounds.minX; x <= bounds.maxX; x += this.cellSize) {
        for (let y = bounds.minY; y <= bounds.maxY; y += this.cellSize) {
          // let tileCenter = [x + this.cellSize / 2, y + this.cellSize / 2];

          let tileCenter = [Math.round(x + this.cellSize / 2), Math.round(y + this.cellSize / 2)];

          if (!this.pointInPolygon(tileCenter, corridor)) continue;


          let cell = this.getCellFromGrid(tileCenter[0], tileCenter[1]);

          try {
            if (this.isWall(cell.i, cell.j)) continue;
          } catch (Exception) {
            continue;
          }


          const neighbors = this.getNeighbors(cell);

          if (neighbors.length === 1) {
            deadEnds.push(cell);
            continue;
          }


          if (neighbors.length === 2) {

            const n1 = neighbors[0];
            const n2 = neighbors[1];

            // Calculate vectors to the neighbors
            const v1 = { x: n1.j - cell.j, y: n1.i - cell.i };
            const v2 = { x: n2.j - cell.j, y: n2.i - cell.i };

            // Check if vectors are in opposite directions
            if ((v1.x === -v2.x && v1.y === -v2.y) ||
              (Math.abs(v1.x + v2.x) < 2 && Math.abs(v1.y + v2.y) < 2)) {
              deadEnds.push(cell);
              continue;
            }

            // Check if neighbors form a straight line and there are walls in between
            if ((v1.x === v2.x && v1.y === v2.y) && this.allWallsInBetween(n1, n2)) {
              deadEnds.push(cell);
              continue;
            }
          }
          else {
            _ = ''
          }
        }
      }

      return deadEnds;
    },
    checkNeighborsIsLessThanByFull(startPoint, endPoint, grid, corridorPoints, direction) {
      const cellSize = this.cellSize;

      const isWithinCorridor = (pt) => {
        const translatedPt = [pt.i * cellSize, pt.j * cellSize];
        return this.pointInPolygon(translatedPt, corridorPoints);
      };

      const hasFourOpenNeighbors = (pt) => {
        if (!isWithinCorridor(pt)) return false;
        const neighbors = this.getNeighbors({ i: pt.i, j: pt.j });

        // return true if four open space
        return neighbors.filter(pt => grid[pt.i][pt.j] === 0).length === 4
        // return neighbors.length === 4;
      };

      // Determine if horizontal or vertical path

      if (direction == 'vertical') {
        // Horizontal check
        for (let j = Math.min(startPoint.j, endPoint.j); j <= Math.max(startPoint.j, endPoint.j); j++) {
          if (hasFourOpenNeighbors({ i: startPoint.i, j: j })) {
            return false;
          }
        }

      } else {
        // Vertical check
        for (let i = Math.min(startPoint.i, endPoint.i); i <= Math.max(startPoint.i, endPoint.i); i++) {
          if (hasFourOpenNeighbors({ i: i, j: startPoint.j })) {
            return false;
          }
        }
      }

      return true;
    },

    checkNeighborsIsLessThan3ByDirection(point, grid, direction, N, corridorPoints) {
      const cellSize = this.cellSize;
      const isWithinCorridor = (pt) => {
        const translatedPt = [pt.i * cellSize, pt.j * cellSize];
        const isInPolygon = this.pointInPolygon(translatedPt, corridorPoints);
        return isInPolygon;
      };

      const getNeighbors = (pt) => {
        return [
          { i: pt.i - 1, j: pt.j },     // top
          { i: pt.i + 1, j: pt.j },     // bottom
          { i: pt.i, j: pt.j - 1 },     // left
          { i: pt.i, j: pt.j + 1 },     // right
        ];
      };

      if (direction === 'horizontal') {
        for (let offset = 1; offset <= N; offset++) {
          let above = { i: point.i, j: point.j - offset };
          let below = { i: point.i, j: point.j + offset };

          let aboveNeighbors = getNeighbors(above);
          let belowNeighbors = getNeighbors(below);


          if (isWithinCorridor(above) && aboveNeighbors.filter(pt => grid[pt.i][pt.j] === 0).length === 4) {
            return false;   // Not a dead-end
          }
          else {
            try {
              console.log('Not within top corridor', 'reason: ', isWithinCorridor(above), 'Not wall length: ', aboveNeighbors.filter(pt => grid[pt.i][pt.j] === 0).length);
            }
            catch (err) {
              console.log('err', err)
            }
          }

          if (isWithinCorridor(below) && belowNeighbors.filter(pt => grid[pt.i][pt.j] === 0).length === 4) {
            return false;   // Not a dead-end
          }
          else {
            try {
              console.log('Not within bottom corridor', 'reason: ', isWithinCorridor(below), 'Not wall length: ', belowNeighbors.filter(pt => grid[pt.i][pt.j] === 0).length);
            } catch (err) {
              console.log('err', err)
            }
          }
        }
      } else if (direction === 'vertical') {
        for (let offset = 1; offset <= N; offset++) {

          let left = { i: point.i - offset, j: point.j };
          let right = { i: point.i + offset, j: point.j };

          let leftNeighbors = getNeighbors(left);
          let rightNeighbors = getNeighbors(right);



          if (isWithinCorridor(left) && leftNeighbors.filter(pt => grid[pt.i][pt.j] === 0).length === 4) {
            return false;   // Not a dead-end
          }
          else {
            try {
              console.log('Not within left corridor', 'reason: ', isWithinCorridor(left), 'Not wall length: ', leftNeighbors.filter(pt => grid[pt.i][pt.j] === 0).length);
            }
            catch (err) {
              console.log('err', err)
            }
          }

          if (isWithinCorridor(right) && rightNeighbors.filter(pt => grid[pt.i][pt.j] === 0).length === 4) {
            return false;   // Not a dead-end
          }
          else {
            try {
              console.log('Not within right corridor', 'reason: ', isWithinCorridor(right), 'Not wall length: ', rightNeighbors.filter(pt => grid[pt.i][pt.j] === 0).length);
            } catch (err) {
              console.log('err', err)
            }
          }
        }
      }
      return true;    // It's a dead-end
    },
    isOpposite(p1, p2, N = 3, corridorPoints, full = false) {
      if (full) {
        let fullCheck = []
        if (p1.i === p2.i) { // Horizontal

          fullCheck.push(this.checkNeighborsIsLessThanByFull(p1, p2, this.map, corridorPoints, 'vertical'))
          // fullCheck.push(this.checkNeighborsIsLessThan3ByDirection(p1, this.map, 'vertical', N, corridorPoints) &&
          //   this.checkNeighborsIsLessThan3ByDirection(p2, this.map, 'vertical', N, corridorPoints));

          return fullCheck.every(Boolean);
        } else if (p1.j === p2.j) { // Vertical

          fullCheck.push(this.checkNeighborsIsLessThanByFull(p1, p2, this.map, corridorPoints, 'horizontal'))
          // fullCheck.push(this.checkNeighborsIsLessThan3ByDirection(p1, this.map, 'horizontal', N, corridorPoints) &&
          //   this.checkNeighborsIsLessThan3ByDirection(p2, this.map, 'horizontal', N, corridorPoints))

          return fullCheck.every(Boolean);
        }
        return false;
      }
      else {
        if (p1.i === p2.i) { // Horizontal
          return this.checkNeighborsIsLessThan3ByDirection(p1, this.map, 'vertical', N, corridorPoints) &&
            this.checkNeighborsIsLessThan3ByDirection(p2, this.map, 'vertical', N, corridorPoints);
        } else if (p1.j === p2.j) { // Vertical
          return this.checkNeighborsIsLessThan3ByDirection(p1, this.map, 'horizontal', N, corridorPoints) &&
            this.checkNeighborsIsLessThan3ByDirection(p2, this.map, 'horizontal', N, corridorPoints);
        }
        return false;
      }
    },

    detectWallBasedOnDeadEnd() {
      for (let corridorEnd of this.corridorEnds) {
        const points = corridorEnd.points.flat();

        for (let i = 0; i < points.length; i += 2) {
          const x1 = points[i];
          const y1 = points[i + 1];
          let x2, y2;

          // If it's the last point, pair it with the first point to close the loop.
          if (i === points.length - 2) {
            x2 = points[0];
            y2 = points[1];
          } else {
            x2 = i + 2;
            y2 = i + 3;
            x2 = points[x2];
            y2 = points[y2];
          }

          const obstacle = {
            points: [x1, y1, x2, y2],
            fill: this.drawingMeta.fillColor,
          };
          this.processObstaclePoints(obstacle.points, "green");

        }
      }
    },
    crossCheckDeadEnds(deadEnds, corridorPoints) {
      const validDeadEnds = [];

      const isWithinCorridor = (pt) => {
        const translatedPt = [pt.i * this.cellSize, pt.j * this.cellSize];
        const isInPolygon = this.pointInPolygon(translatedPt, corridorPoints);
        return isInPolygon;
      };


      for (let i = 0; i < deadEnds.length; i++) {
        const current = deadEnds[i];

        for (let j = i + 1; j < deadEnds.length; j++) {
          const potentialOpposite = deadEnds[j];

          if (this.isOpposite(current, potentialOpposite, 3, corridorPoints, false)) {
            // validDeadEnds.push(current);
            // validDeadEnds.push(potentialOpposite);

            let points = [current.i, current.j, potentialOpposite.i, potentialOpposite.j];
            let centerOfPoints = this.getCenterOfShape(points)
            points = { i: parseInt(centerOfPoints[0]), j: parseInt(centerOfPoints[1]) }
            if (isWithinCorridor(points)) {
              validDeadEnds.push(points)
            }

            // Once found, you can break out of the inner loop if desired
            // break; 
          }
        }
      }
      return validDeadEnds;
    },

    startSearchForDeadEndStartPos(isUpdateColor) {
      this.processing = true;
      // this.travelPathResult = [];
      let deadEndCells = []
      for (let corridorEnd of this.corridorEnds) {
        const corridorPoints = corridorEnd.points.flat();
        let deadEndCell = this.detectDeadEnds(corridorPoints);
        deadEndCell = this.crossCheckDeadEnds(deadEndCell, corridorPoints);
        deadEndCells.push({ cell: deadEndCell, corridorEndId: corridorEnd._id });
      }


      // deadEndCells = deadEndCells.filter((v, i, a) => a.findIndex(v2 => ['i', 'j'].every(k => v2[k] === v[k])) === i)

      let uniqueDeadEndCells = deadEndCells.filter((v, i, a) =>
        a.findIndex(t => t.cell.i === v.cell.i && t.cell.j === v.cell.j) === i
      );
      for (let item of uniqueDeadEndCells) {
        let { cell: cells, corridorEndId } = item;

        // Process each cell in the cells array
        for (let cell of cells) {
          let { i, j } = cell;

          let spotCode = "spot_" + i + "_" + j;
          this.grid[i][j] = {
            i, j,
            code: spotCode,
            name: spotCode,
            width: this.cellSize,
            height: this.cellSize,
            x: this.cellSize * j,
            y: this.cellSize * i,
            stroke: "purple",
            strokeWidth: 10,
            closed: true,
            fill: "purple"
          };

          // Add the deadEnd to the list with the corridorEndId
          this.deadEndStartPosList.push({ x: j, y: i, corridorEndId });
        }
      }

      this.refreshStage();
      this.processing = false;
    },

    recognizeAllTravelPath() {
      this.startSearchForPath(false);

      const allCommonPathPoints = [];
      const commonPathMap = new Map();
      const prevCommonPaths = []; // This will hold all previous paths for comparison

      this.travelDistances = this.travelDistances || [];
      this.commonPaths = this.commonPaths || [];

      this.travelPathResult.forEach((paths) => {
        let path = paths.generatorPath;
        let reducedPoints = []
        const points = path.map((p) =>
          [this.cellSize * p.y, this.cellSize * p.x].flat()
        );

        if (points.length > 0) {
          // const randomColor = Math.floor(Math.random() * 16777215).toString(16);
          reducedPoints = this.simplifyPoints(points);
          const travelDistanceObj = {
            code: `travelDistance${this.travelDistances.length + 1}`,
            name: `Travel Distance ${this.travelDistances.length + 1}`,
            points: reducedPoints.flat(),
            stroke: '#289600',
            strokeWidth: this.strokeWidth,
            closed: false,
            pointerAtBeginning: false,
            pointerAtEnding: true,
            floorPlanId: this.obj._id,
            roomId: paths.roomId,
          };
          this.travelDistances.push(travelDistanceObj);

          const pathPoints = path.map((p) =>
            [this.cellSize * p.y, this.cellSize * p.x].flat()
          );
          let pointsNotInsideRooms = [...pathPoints];

          this.rooms.forEach((anotherRoom) => {
            const anotherRoomPoints = JSON.parse(
              JSON.stringify(anotherRoom.points)
            );
            const pointsInsideAnotherRoom = pathPoints.filter((point) =>
              this.pointInPolygon(point, anotherRoomPoints)
            );
            pointsNotInsideRooms = pointsNotInsideRooms.filter(
              (point) => !pointsInsideAnotherRoom.includes(point)
            );

            if (anotherRoom.roomDoors && anotherRoom.roomDoors.length == 1) {
              if (pointsInsideAnotherRoom.length > 0) {
                // reducedPoints = this.simplifyPoints(pointsInsideAnotherRoom);
                // // This portion of the path extends into another room which has only one door
                // // So, mark this portion as commonPath for that room
                // const commonPathObjForAnotherRoom = {
                //   ...travelDistanceObj,
                //   stroke: '#0ED8D4',
                //   points: reducedPoints.flat(),
                //   code: `commonPaths${this.commonPaths.length + 1}`,
                //   name: `Common Path ${this.commonPaths.length + 1}`,
                // };
                // this.commonPaths.push(commonPathObjForAnotherRoom);
              }
            }

            // if (
            //   !isPathInsideAnyRoom &&
            //   this.exitStairDoors &&
            //   this.exitStairDoors.length == 1
            // ) {
            //   const commonPathObj = {
            //     ...travelDistanceObj,
            //     code: `commonPaths${this.commonPaths.length + 1}`,
            //     name: `CommonPath ${this.commonPaths.length + 1}`,
            //   };
            //   this.commonPaths.push(commonPathObj);
            // }
          });
          if (
            this.exitStairDoors &&
            this.exitStairDoors.length == 1 &&
            pointsNotInsideRooms.length > 0
          ) {
            reducedPoints = this.simplifyPoints(pointsNotInsideRooms);

            const commonPathObj = {
              ...travelDistanceObj,
              points: reducedPoints.flat(),
              code: `commonPaths${this.commonPaths.length + 1}`,
              name: `Common Path ${this.commonPaths.length + 1}`,
            };
            this.commonPaths.push(commonPathObj);
          }
        }
      });



      this.commonPathResult.forEach((paths) => {
        let path = paths.generatorPath;
        let reducedPoints = []
        const points = path.map((p) =>
          [this.cellSize * p.y, this.cellSize * p.x].flat()
        );
        const travelDistanceObj = {
          code: `travelDistance${this.travelDistances.length + 1}`,
          name: `Travel Distance ${this.travelDistances.length + 1}`,
          points: reducedPoints.flat(),
          stroke: '#289600',
          strokeWidth: this.strokeWidth,
          closed: false,
          pointerAtBeginning: false,
          pointerAtEnding: true,
          floorPlanId: this.obj._id,
          roomId: paths.roomId,
        };

        if (points.length > 0) {
          // const randomColor = Math.floor(Math.random() * 16777215).toString(16);
          reducedPoints = this.simplifyPoints(points);
          const pathPoints = path.map((p) =>
            [this.cellSize * p.y, this.cellSize * p.x].flat()
          );
          let pointsNotInsideRooms = [...pathPoints];

          this.rooms.forEach((anotherRoom) => {
            const anotherRoomPoints = JSON.parse(
              JSON.stringify(anotherRoom.points)
            );
            const pointsInsideAnotherRoom = pathPoints.filter((point) =>
              this.pointInPolygon(point, anotherRoomPoints)
            );
            pointsNotInsideRooms = pointsNotInsideRooms.filter(
              (point) => !pointsInsideAnotherRoom.includes(point)
            );

            if (anotherRoom.roomDoors && anotherRoom.roomDoors.length == 1) {
              if (pointsInsideAnotherRoom.length > 0) {
                // reducedPoints = this.simplifyPoints(pointsInsideAnotherRoom);
                // // This portion of the path extends into another room which has only one door
                // // So, mark this portion as commonPath for that room
                const commonPathObjForAnotherRoom = {
                  ...travelDistanceObj,
                  stroke: '#0ED8D4',
                  points: reducedPoints.flat(),
                  code: `commonPaths${this.commonPaths.length + 1}`,
                  name: `Common Path ${this.commonPaths.length + 1}`,
                };
                // this.commonPaths.push(commonPathObjForAnotherRoom);
              }
            }

            // if (
            //   !isPathInsideAnyRoom &&
            //   this.exitStairDoors &&
            //   this.exitStairDoors.length == 1
            // ) {
            //   const commonPathObj = {
            //     ...travelDistanceObj,
            //     code: `commonPaths${this.commonPaths.length + 1}`,
            //     name: `CommonPath ${this.commonPaths.length + 1}`,
            //   };
            //   this.commonPaths.push(commonPathObj);
            // }
          });
          if (
            this.exitStairDoors &&
            this.exitStairDoors.length == 1 &&
            pointsNotInsideRooms.length > 0
          ) {
            reducedPoints = this.simplifyPoints(pointsNotInsideRooms);

            const commonPathObj = {
              ...travelDistanceObj,
              points: reducedPoints.flat(),
              code: `commonPaths${this.commonPaths.length + 1}`,
              name: `Common Path ${this.commonPaths.length + 1}`,
            };
            // this.commonPaths.push(commonPathObj);
          }
        }
      });
    },
    recognizeAllDeadEndPath() {
      this.startSearchForDeadEndPath(false);
      this.deadEndDistances = this.deadEndDistances || [];
      this.deadEndPathResult.forEach((paths) => {
        let path = paths.generatorPath;
        const points = path.map((p) =>
          [Math.round(this.cellSize * p.y), Math.round(this.cellSize * p.x)].flat()
        );
        let reducedPoints = this.simplifyPoints(points);
        if (points.length > 0) {
          // const randomColor = Math.floor(Math.random() * 16777215).toString(16);
          const deadEndDistanceObj = {
            code: `deadEndDistance${this.deadEnds.length + 1}`,
            name: `Dead End ${this.deadEnds.length + 1}`,
            points: reducedPoints.flat(),
            stroke: '#8C1AFF',
            strokeWidth: this.strokeWidth,
            closed: false,
            pointerAtBeginning: false,
            pointerAtEnding: true,
            floorPlanId: this.obj._id,
            // corridorEndId: null,
            corridorEndId: paths.corridorEndId,
          };
          const distance = this.getDistanceInMeter(deadEndDistanceObj);

          if (distance >= 1.5) {
            this.deadEndDistances.push(deadEndDistanceObj);
            this.deadEnds.push(deadEndDistanceObj);
          }
        }
      });

    },
    isPointOnLineSegment(px, py, x1, y1, x2, y2) {
      const tolerance = 1e-10; // Tolerance for floating point comparison
      const onLine = (py - y1) * (x2 - x1) === (y2 - y1) * (px - x1);
      const withinSegmentX = Math.min(x1, x2) - tolerance <= px && px <= Math.max(x1, x2) + tolerance;
      const withinSegmentY = Math.min(y1, y2) - tolerance <= py && py <= Math.max(y1, y2) + tolerance;
      return onLine && withinSegmentX && withinSegmentY;
    },
    doPolygonsIntersect(polygon1, polygon2) {
      // Check if any vertex of polygon1 is inside polygon2
      for (let i = 0; i < polygon1.length; i += 2) {
        if (this.pointInPolygonEasy([polygon1[i], polygon1[i + 1]], polygon2)) {
          return true;
        }
      }

      // Check if any vertex of polygon2 is inside polygon1
      for (let i = 0; i < polygon2.length; i += 2) {
        if (this.pointInPolygonEasy([polygon2[i], polygon2[i + 1]], polygon1)) {
          return true;
        }
      }

      // Check for edge intersections
      for (let i = 0; i < polygon1.length; i += 2) {
        let ax1 = polygon1[i], ay1 = polygon1[i + 1];
        let ax2 = polygon1[(i + 2) % polygon1.length], ay2 = polygon1[(i + 3) % polygon1.length];

        for (let j = 0; j < polygon2.length; j += 2) {
          let bx1 = polygon2[j], by1 = polygon2[j + 1];
          let bx2 = polygon2[(j + 2) % polygon2.length], by2 = polygon2[(j + 3) % polygon2.length];

          if (this.isPointOnLineSegment(ax1, ay1, ax2, ay2, bx1, by1, bx2, by2)) {
            return true;
          }
        }
      }

      return false;
    },
    pointInPolygonEasy(point, polygon) {
      const [x, y] = point;
      let intersections = 0;

      for (let i = 0; i < polygon.length; i += 2) {
        const x1 = polygon[i];
        const y1 = polygon[i + 1];
        const x2 = polygon[(i + 2) % polygon.length];
        const y2 = polygon[(i + 3) % polygon.length];

        // Check if the point is exactly on an edge
        if (this.isPointOnLineSegment(x, y, x1, y1, x2, y2)) {
          return true;
        }

        const minY = Math.min(y1, y2);
        const maxY = Math.max(y1, y2);

        if (y <= minY || y > maxY) continue;

        const intersectionX = ((y - y1) * (x2 - x1)) / (y2 - y1) + x1;

        if (x <= intersectionX) intersections++;
      }

      return intersections % 2 !== 0;
    },
    pointInPolygon(point, polygon) {
      const [x, y] = point;
      let intersections = 0;

      for (let i = 0; i < polygon.length; i += 2) {
        const x1 = polygon[i];
        const y1 = polygon[i + 1];
        // Wrap around the polygon array by using modulus (%).
        const x2 = polygon[(i + 2) % polygon.length];
        const y2 = polygon[(i + 3) % polygon.length];

        const minY = Math.min(y1, y2);
        const maxY = Math.max(y1, y2);

        // Check if the point is between minY and maxY, if not, no need to calculate intersection.
        if (y <= minY || y > maxY) continue;

        // Calculate intersection X-coordinate.
        const intersectionX = ((y - y1) * (x2 - x1)) / (y2 - y1) + x1;

        // If the X-coordinate of the point is less than the X-coordinate of intersection, increment intersections.
        if (x < intersectionX) intersections++;
      }

      // If the number of intersections is odd, the point is inside the polygon.
      return intersections % 2 !== 0;
    },

    isCollinear(A, B, C) {
      let slopeAB = (B[1] - A[1]) / (B[0] - A[0]);
      let slopeBC = (C[1] - B[1]) / (C[0] - B[0]);
      return slopeAB === slopeBC;
    },
    simplifyPoints(points) {
      let simplified = [points[0]];

      for (let i = 1; i < points.length - 1; i++) {
        if (!this.isCollinear(points[i - 1], points[i], points[i + 1])) {
          simplified.push(points[i]);
        }
      }

      simplified.push(points[points.length - 1]);
      return simplified;
    },
    recognizeAllCommonPath() {
      this.startSearchForCommonPath(false);

      const allCommonPathPoints = [];
      const commonPathMap = new Map();
      const prevCommonPaths = []; // This will hold all previous paths for comparison
      const exitDoor = [];
      const nonRoomCommonPaths = [];

      this.obj.doors.forEach((item) => {
        if (item.isFinalExitDoor) {
          exitDoor.push(item);
        }
      });


      this.travelDistances = this.travelDistances || [];
      this.commonPaths = this.commonPaths || [];

      this.commonPathResult.forEach((paths) => {
        let path = paths.generatorPath;
        let reducedPoints = []
        const points = path.map((p) =>
          [this.cellSize * p.y, this.cellSize * p.x].flat()
        );
        const pathPoints = points;

        if (points.length > 0) {
          // const randomColor = Math.floor(Math.random() * 16777215).toString(16);
          reducedPoints = this.simplifyPoints(points);
          const commonPathDistanceObj = {
            code: `commonPath${this.commonPaths.length + 1}`,
            name: `Common Path ${this.commonPaths.length + 1}`,
            points: reducedPoints.flat(),
            stroke: '#289600',
            strokeWidth: this.strokeWidth,
            closed: false,
            pointerAtBeginning: false,
            pointerAtEnding: true,
            floorPlanId: this.obj._id,
            roomId: paths.roomId,
          };
          let totalRoomPoints = []

          // if only one exitDoor, all path is commonPath
          if (exitDoor.length == 1) {
            this.commonPaths.push(commonPathDistanceObj);
          }

          else {
            // check eachRoom's path
            this.rooms.forEach((anotherRoom) => {
              const anotherRoomPoints = JSON.parse(
                JSON.stringify(anotherRoom.points)
              );

              const pointsInsideAnotherRoom = pathPoints.filter((point) =>
                this.pointInPolygon(point, anotherRoomPoints)
              );
              if (pointsInsideAnotherRoom.length > 0) {
                totalRoomPoints.push(JSON.parse(JSON.stringify(pointsInsideAnotherRoom)))
              }

              // if the room have only one door
              if (anotherRoom.roomDoors && anotherRoom.roomDoors.length == 1) {
                // if there is any  point in room
                if (pointsInsideAnotherRoom.length > 0) {
                  reducedPoints = this.simplifyPoints(pointsInsideAnotherRoom);
                  // // This portion of the path extends into another room which has only one door
                  // // So, mark this portion as commonPath for that room
                  const commonPathObjForAnotherRoom = {
                    code: `commonPath${this.commonPaths.length + 1}`,
                    name: `Common Path ${this.commonPaths.length + 1}`,
                    stroke: '#0ED8D4',
                    points: reducedPoints.flat(),
                    stroke: '#289600',
                    strokeWidth: this.strokeWidth,
                    closed: false,
                    pointerAtBeginning: false,
                    pointerAtEnding: true,
                    floorPlanId: this.obj._id,
                    roomId: paths.roomId,
                  };
                  this.commonPaths.push(commonPathObjForAnotherRoom);
                }
              }
            });
          }
          if (totalRoomPoints.length >= 1) {
            const flattenedTotalRoomPoints = totalRoomPoints.flat();

            // Function to check if a point exists in an array of points
            const pointExists = (point, pointsArray) => {
              return pointsArray.some(p => p[0] === point[0] && p[1] === point[1]);
            };

            // Filter out points in pathPoints that are in flattenedTotalRoomPoints
            const uniquePathPoints = pathPoints.filter(point => !pointExists(point, flattenedTotalRoomPoints));
            nonRoomCommonPaths.push(uniquePathPoints);
          }
        }
      });


      if (nonRoomCommonPaths.length >= 2) {
        // Function to convert a point array to a string key
        const pointKey = point => point.join(',');

        // Map to count occurrences of each point
        const pointCounts = new Map();

        // Count occurrences of each point
        nonRoomCommonPaths.flat().forEach(point => {
          const key = pointKey(point);
          pointCounts.set(key, (pointCounts.get(key) || 0) + 1);
        });

        // Filter to keep only points that occur more than once
        const overlappingPoints = Array.from(pointCounts)
          .filter(([key, count]) => count > 1)
          .map(([key, count]) => key.split(',').map(Number));

        // Convert to the desired format
        const overlappingNonRoomCommonPaths = Array.from(new Set(overlappingPoints.map(JSON.stringify)), JSON.parse);
        const nonRoomCommonPathsSplitted = this.splitNonRoomCommonPathIntoArrays(overlappingNonRoomCommonPaths);

        if (nonRoomCommonPathsSplitted.length >= 2) {
          nonRoomCommonPathsSplitted.forEach((paths) => {
            if (paths.length >= 5) {
              const reducedPoints = this.simplifyPoints(paths);
              // // This portion of the path extends into another room which has only one door
              // // So, mark this portion as commonPath for that room
              const commonPathObjForAnotherRoom = {
                code: `commonPath${this.commonPaths.length + 1}`,
                name: `Common Path ${this.commonPaths.length + 1}`,
                stroke: '#0ED8D4',
                points: reducedPoints.flat(),
                stroke: '#289600',
                strokeWidth: this.strokeWidth,
                closed: false,
                pointerAtBeginning: false,
                pointerAtEnding: true,
                floorPlanId: this.obj._id,
                // roomId: paths.roomId,
              };
              this.commonPaths.push(commonPathObjForAnotherRoom);
            }
          })
        }
      }
    },
    splitNonRoomCommonPathIntoArrays(points) {
      const isNear = (point1, point2) => {
        return Math.abs(point1[0] - point2[0]) <= gridSize ||
          Math.abs(point1[1] - point2[1]) <= gridSize;
      };

      const gridSize = parseInt(this.drawingMeta.gridSize);

      let splitArrays = [];
      let currentArray = [points[0]];



      for (let i = 1; i < points.length; i++) {
        if (isNear(points[i], currentArray[currentArray.length - 1])) {
          currentArray.push(points[i]);
        } else {
          splitArrays.push(currentArray);
          currentArray = [points[i]];
        }
      }

      // Add the last sub-array if it's not empty
      if (currentArray.length > 0) {
        splitArrays.push(currentArray);
      }
      return splitArrays
    },
    recognizeTravelPathForAllRooms() {
      var self = this;
      for (let i = 0; i < self.obj.rooms.length; i++) {
        var currentRoom = self.obj.rooms[i];
        let cellStart = this.getCellFromGrid(
          currentRoom.longestPoints[0],
          currentRoom.longestPoints[1]
        );
        var lowestCellCount = [];
        var start = {
          x: cellStart.j,
          y: cellStart.i,
        };
        for (let j = 0; j < self.endPosList.length; j++) {
          var currentEndPos = self.endPosList[j];
          var end = {
            x: currentEndPos.y,
            y: currentEndPos.x,
          };
          var cellPaths = this.recognizeTravelPath(start, end);
          if (lowestCellCount.length == 0) lowestCellCount = cellPaths;
          if (cellPaths.length < lowestCellCount.length)
            lowestCellCount = cellPaths;
        }
        if (lowestCellCount.length == 0) continue;
        var points = [];
        for (let k = 0; k < lowestCellCount.length; k++) {
          let i = lowestCellCount[k].y;
          let j = lowestCellCount[k].x;
          this.grid[i][j] = {
            code: "spot_" + i + "_" + j,
            name: "spot_" + i + "_" + j,
            width: this.cellSize,
            height: this.cellSize,
            x: this.cellSize * i,
            y: this.cellSize * j,
            stroke: "black",
            strokeWidth: 2,
            closed: true,
            fill: "rgb(255, 255, 0, 0.3)",
          };
          points.push(this.cellSize * i, this.cellSize * j);
        }

        this.stroke = "green";
        if (this.travelDistances == null) this.travelDistances = [];

        this.travelDistances.push({
          code: "travelDistance" + (this.travelDistances.length + 1),
          name: "Travel Distance " + (this.travelDistances.length + 1),
          points: points,
          stroke: this.stroke,
          strokeWidth: this.strokeWidth,
          closed: false,
          floorPlanId: this.obj._id,
          // lineCap: "round",
          // lineJoin: "round",
          //draggable: true,
        });
      }
    },
    recognizeTravelPath(startPos, endPos) {
      this.processing = true;
      this.cleanPath();
      let generator = new PathGenerator(startPos, endPos, this.map);
      generator.generate();
      this.cellsProcessed = generator.cells;
      var path = generator.path;

      this.processing = false;
      return path;
    },
    detectDoor() {
      var self = this;
      self.centerDoorPositions = [];
      for (let k = 0; k < self.obj.doors.length; k++) {
        var currentDoor = self.obj.doors[k];
        if (currentDoor.points == null) continue;

        var centerDoorPos = this.getCenterOfShape(currentDoor.points);
        self.centerDoorPositions.push({
          id: "centerDoorPosition_" + self.centerDoorPositions.length,
          x: centerDoorPos[0],
          y: centerDoorPos[1],
        });

        this.detectDoors.push({
          id: "detectDoors_" + this.detectDoors.length,
          x: centerDoorPos[0],
          y: centerDoorPos[1],
          stroke: "red",
        });

        continue;

        // var currentPointX = currentDoor.points[0];
        // var currentPointY = currentDoor.points[1];
        // var nextPointX = currentDoor.points[2];
        // var nextPointY = currentDoor.points[3];

        // var distanceInPixel = drawing.getDistanceInPixel(
        //   currentDoor.points[0],
        //   currentDoor.points[1],
        //   currentDoor.points[2],
        //   currentDoor.points[3]
        // );
        // var totalPoint = Math.round(distanceInPixel / this.cellSize);
        // totalPoint = totalPoint + 2;

        // for (let j = 0; j < totalPoint; j++) {
        //   var distancePerPointX =
        //     (currentDoor.points[currentDoor.points.length - 2] -
        //       currentDoor.points[0]) /
        //     totalPoint;
        //   var distancePerPointY =
        //     (currentDoor.points[currentDoor.points.length - 1] -
        //       currentDoor.points[1]) /
        //     totalPoint;

        //   var pointX = currentDoor.points[0] + distancePerPointX * (j + 1);
        //   var pointY = currentDoor.points[1] + distancePerPointY * (j + 1);

        //   this.detectDoors.push({
        //     id: "detectDoors_" + this.detectDoors.length,
        //     x: pointX,
        //     y: pointY,
        //     stroke: "red",
        //   });
        // }
      }
    },
    removeBlockedExitDoor() {
      var self = this;

      const exitDoor = [];
      this.obj.doors.forEach((item) => {
        if (item.isFinalExitDoor) {
          exitDoor.push(item);
        }
      });

      // Check if we have any room doors to clear
      if (exitDoor.length === 0) {
        self.toast(
          "Unable to Start Travel Distance AI",
          "Exit door does not exist",
          "danger"
        );
        return;
      }

      // Loop through each room door and clear it
      for (let door of exitDoor) {
        let minX = Math.min(
          door.points[0],
          door.points[2],
          door.points[4],
          door.points[6]
        );
        let maxX = Math.max(
          door.points[0],
          door.points[2],
          door.points[4],
          door.points[6]
        );
        let minY = Math.min(
          door.points[1],
          door.points[3],
          door.points[5],
          door.points[7]
        );
        let maxY = Math.max(
          door.points[1],
          door.points[3],
          door.points[5],
          door.points[7]
        );

        try {
          for (let x = minX; x <= maxX; x += this.cellSize) {
            for (let y = minY; y <= maxY; y += this.cellSize) {
              // Check if (x, y) is within the actual door shape.
              // If your door is always a rectangle, you don't need this step.
              let cell = this.getCellFromGrid(x, y);
              var cellI = cell.i;
              var cellJ = cell.j;

              this.map[cellI][cellJ] = 1;
              this.grid[cellI][cellJ] = {
                i: cellI,
                j: cellJ,
                code: "spot_" + cellI + "_" + cellJ,
                name: "spot_" + cellI + "_" + cellJ,
                width: this.cellSize,
                height: this.cellSize,
                x: this.cellSize * cellI,
                y: this.cellSize * cellJ,
                closed: true,
                fill: "blue",
              };
            }
          }
        }
        catch (err) {
          // console.log('err')
        }

      }
    },
    clearBlockedExitDoor() {
      var self = this;

      const exitDoor = [];
      this.obj.doors.forEach((item) => {
        if (item.isFinalExitDoor) {
          exitDoor.push(item);
        }
      });

      // Check if we have any room doors to clear
      if (exitDoor.length === 0) {
        self.toast(
          "Unable to Start Travel Distance AI",
          "Exit door does not exist",
          "danger"
        );
        return;
      }

      // Loop through each room door and clear it
      for (let door of exitDoor) {
        let minX = Math.min(
          door.points[0],
          door.points[2],
          door.points[4],
          door.points[6]
        );
        let maxX = Math.max(
          door.points[0],
          door.points[2],
          door.points[4],
          door.points[6]
        );
        let minY = Math.min(
          door.points[1],
          door.points[3],
          door.points[5],
          door.points[7]
        );
        let maxY = Math.max(
          door.points[1],
          door.points[3],
          door.points[5],
          door.points[7]
        );

        try {
          for (let x = minX; x <= maxX; x += this.cellSize) {
            for (let y = minY; y <= maxY; y += this.cellSize) {
              // Check if (x, y) is within the actual door shape.
              // If your door is always a rectangle, you don't need this step.
              let cell = this.getCellFromGrid(x, y);
              var cellI = cell.i;
              var cellJ = cell.j;

              this.map[cellI][cellJ] = 0;
              this.grid[cellI][cellJ] = {
                i: cellI,
                j: cellJ,
                code: "spot_" + cellI + "_" + cellJ,
                name: "spot_" + cellI + "_" + cellJ,
                width: this.cellSize,
                height: this.cellSize,
                x: this.cellSize * cellI,
                y: this.cellSize * cellJ,
                closed: true,
                fill: "blue",
              };
            }
          }
        }
        catch (err) {
          console.log('hello')
        }
      }
    },
    removeBlockedRoomDoor() {
      var self = this;

      // First, get all room doors from all rooms
      let allRoomDoors = [];
      for (let room of self.obj.rooms) {
        if (room.roomDoors && room.roomDoors.length) {
          for (let doorObj of room.roomDoors) {
            // Push the associated door object to allRoomDoors
            allRoomDoors.push(doorObj.doors);
          }
        }
      }

      // Check if we have any room doors to clear
      if (allRoomDoors.length === 0) {
        self.toast(
          "Unable to Start Travel Distance AI",
          "Room door does not exist",
          "danger"
        );
        return;
      }

      // Loop through each room door and clear it
      for (let door of allRoomDoors) {
        let minX = Math.min(
          door.points[0],
          door.points[2],
          door.points[4],
          door.points[6]
        );
        let maxX = Math.max(
          door.points[0],
          door.points[2],
          door.points[4],
          door.points[6]
        );
        let minY = Math.min(
          door.points[1],
          door.points[3],
          door.points[5],
          door.points[7]
        );
        let maxY = Math.max(
          door.points[1],
          door.points[3],
          door.points[5],
          door.points[7]
        );

        for (let x = minX; x <= maxX; x += this.cellSize) {
          for (let y = minY; y <= maxY; y += this.cellSize) {
            // Check if (x, y) is within the actual door shape.
            // If your door is always a rectangle, you don't need this step.
            let cell = this.getCellFromGrid(x, y);
            try {
              var cellI = cell.i;
              var cellJ = cell.j;

              this.map[cellI][cellJ] = 1;
              this.grid[cellI][cellJ] = {
                i: cellI,
                j: cellJ,
                code: "spot_" + cellI + "_" + cellJ,
                name: "spot_" + cellI + "_" + cellJ,
                width: this.cellSize,
                height: this.cellSize,
                x: this.cellSize * cellI,
                y: this.cellSize * cellJ,
                closed: true,
                fill: "blue",
              };
            } catch (err) {
              // console.log("ERROR")
            }
          }
        }
        // let doorCenterX = (door.points[0] + door.points[2]) / 2;
        // let doorCenterY = (door.points[1] + door.points[3]) / 2;

        // let cell = this.getCellFromGrid(doorCenterX, doorCenterY);
        // if (cell) {

        //     var cellI = cell.i;
        //     var cellJ = cell.j;

        //     this.map[cellI][cellJ] = 0;
        //     this.grid[cellI][cellJ] = {
        //         i: cellI,
        //         j: cellJ,
        //         code: "spot_" + cellI + "_" + cellJ,
        //         name: "spot_" + cellI + "_" + cellJ,
        //         width: this.cellSize,
        //         height: this.cellSize,
        //         x: this.cellSize * cellI,
        //         y: this.cellSize * cellJ,
        //         closed: true,
        //         fill: "blue",
        //     };
        // } else {
        //     console.log("error !");
        // }
      }
    },
    clearBlockedDoor() {
      var self = this;

      // First, get all room doors from all rooms
      let allRoomDoors = [];
      
      for (let room of self.obj.rooms) {
        if (room.roomDoors && room.roomDoors.length) {
          for (let doorObj of room.roomDoors) {
            // Push the associated door object to allRoomDoors
            allRoomDoors.push(doorObj.doors);
          }
        }
      }

      self.obj.doors.forEach((item) => {
        if (item.isRoomDoor) {
          allRoomDoors.push(item);
        }
      });

      // Check if we have any room doors to clear
      if (allRoomDoors.length === 0) {
        self.toast(
          "Unable to Start Travel Distance AI",
          "Room door does not exist",
          "danger"
        );
        return;
      }

      self.obj.doors.forEach((item) => {
        if (item.isRoomDoor) {
          allRoomDoors.push(item);
        }
      });

      // Loop through each room door and clear it
      for (let door of allRoomDoors) {
        let minX = Math.min(
          door.points[0],
          door.points[2],
          door.points[4],
          door.points[6]
        );
        let maxX = Math.max(
          door.points[0],
          door.points[2],
          door.points[4],
          door.points[6]
        );
        let minY = Math.min(
          door.points[1],
          door.points[3],
          door.points[5],
          door.points[7]
        );
        let maxY = Math.max(
          door.points[1],
          door.points[3],
          door.points[5],
          door.points[7]
        );

        for (let x = minX; x <= maxX; x += this.cellSize) {
          for (let y = minY; y <= maxY; y += this.cellSize) {
            // Check if (x, y) is within the actual door shape.
            // If your door is always a rectangle, you don't need this step.
            let cell = this.getCellFromGrid(x, y);
            try {
              var cellI = cell.i;
              var cellJ = cell.j;

              this.map[cellI][cellJ] = 0;
              this.grid[cellI][cellJ] = {
                i: cellI,
                j: cellJ,
                code: "spot_" + cellI + "_" + cellJ,
                name: "spot_" + cellI + "_" + cellJ,
                width: this.cellSize,
                height: this.cellSize,
                x: this.cellSize * cellI,
                y: this.cellSize * cellJ,
                closed: true,
                fill: "blue",
              };
              this.clearObstacleNearEndPos(cellI, cellJ, parseInt(door.rotation));
            } catch (err) {
              // console.log("ERROR")
            }
          }
        }
        // let doorCenterX = (door.points[0] + door.points[2]) / 2;
        // let doorCenterY = (door.points[1] + door.points[3]) / 2;

        // let cell = this.getCellFromGrid(doorCenterX, doorCenterY);
        // if (cell) {

        //     var cellI = cell.i;
        //     var cellJ = cell.j;

        //     this.map[cellI][cellJ] = 0;
        //     this.grid[cellI][cellJ] = {
        //         i: cellI,
        //         j: cellJ,
        //         code: "spot_" + cellI + "_" + cellJ,
        //         name: "spot_" + cellI + "_" + cellJ,
        //         width: this.cellSize,
        //         height: this.cellSize,
        //         x: this.cellSize * cellI,
        //         y: this.cellSize * cellJ,
        //         closed: true,
        //         fill: "blue",
        //     };
        // } else {
        //     console.log("error !");
        // }
      }
    },
    recognizeEndPos() {
      var self = this;
      self.endPosList = [];
      const exitDoor = [];
      this.obj.doors.forEach((item) => {
        if (item.isFinalExitDoor) {
          exitDoor.push(item);
        }
      });
      for (let i = 0; i < exitDoor.length; i++) {
        var currentDoor = exitDoor[i];
        let door = currentDoor;
        var centerDoorPos = this.getCenterOfShape(currentDoor.points);

        var centerDoorX = centerDoorPos[0]; // (currentDoor.points[0] + currentDoor.points[2]) / 2;
        var centerDoorY = centerDoorPos[1]; // (currentDoor.points[1] + currentDoor.points[3]) / 2;
        let cell = this.getCellFromGrid(centerDoorX, centerDoorY);

        let minX = Math.min(
          door.points[0],
          door.points[2],
          door.points[4],
          door.points[6]
        );
        let maxX = Math.max(
          door.points[0],
          door.points[2],
          door.points[4],
          door.points[6]
        );
        let minY = Math.min(
          door.points[1],
          door.points[3],
          door.points[5],
          door.points[7]
        );
        let maxY = Math.max(
          door.points[1],
          door.points[3],
          door.points[5],
          door.points[7]
        );

        for (let x = minX; x <= maxX; x += this.cellSize) {
          for (let y = minY; y <= maxY; y += this.cellSize) {
            // Check if (x, y) is within the actual door shape.
            // If your door is always a rectangle, you don't need this step.
            let cell = this.getCellFromGrid(x, y);
            try {
              var cellI = cell.i;
              var cellJ = cell.j;

              this.map[cellI][cellJ] = 0;
              this.grid[cellI][cellJ] = {
                i: cellI,
                j: cellJ,
                code: "spot_" + cellI + "_" + cellJ,
                name: "spot_" + cellI + "_" + cellJ,
                width: this.cellSize,
                height: this.cellSize,
                x: this.cellSize * cellI,
                y: this.cellSize * cellJ,
                closed: true,
                fill: "rgb(255, 0, 0)",
              };
              this.clearObstacleNearEndPos(cellI, cellJ, parseInt(door.rotation));
            } catch (err) {
              // console.log("ERROR")
            }
          }
        }
      }
    },
    fillDoorOnGrid(door) {
      // 1. Determine Door Boundaries
      const minX = Math.min(...door.points.filter((_, index) => index % 2 === 0));
      const maxX = Math.max(...door.points.filter((_, index) => index % 2 === 0));
      const minY = Math.min(...door.points.filter((_, index) => index % 2 !== 0));
      const maxY = Math.max(...door.points.filter((_, index) => index % 2 !== 0));
      for (let y = minY; y <= maxY; y += this.cellSize) {
          for (let x = minX; x <= maxX; x += this.cellSize) {
            let cell = this.getCellFromGrid(x, y);
            this.map[cell.i][cell.j] = 0;
            this.grid[cell.i][cell.j] = {
              i: cell.i,
              j: cell.j,
              code: "spot_" + cell.i + "_" + cell.j,
              name: "spot_" + cell.i + "_" + cell.j,
              width: this.cellSize,
              height: this.cellSize,
              x: this.cellSize * cell.i,
              y: this.cellSize * cell.j,
              closed: true,
              fill: "rgb(255, 0, 0)",
            };
          }
      }
        
      // 2. Calculate Offsets based on Rotation
      console.log('door name: ' + door.name + 'door rotation: '+ door.rotation)
      const offsets = this.calculateOffsets(parseInt(door.rotation));
      console.log('offsets'+ offsets)

      // 3. Iterate Along Door Edges with Offsets 
      for (let x = minX; x <= maxX; x += this.cellSize) {
        this.applyOffsetsAndFill(x, minY, offsets); // Fill top edge outward
        this.applyOffsetsAndFill(x, maxY, offsets); // Fill bottom edge outward
      }
      for (let y = minY; y <= maxY; y += this.cellSize) {
        this.applyOffsetsAndFill(minX, y, offsets); // Fill left edge outward 
        this.applyOffsetsAndFill(maxX, y, offsets); // Fill right edge outward
      }
    },
    calculateOffsets(rotation) {
      let offsets = [];
      console.log(typeof(rotation))
      if (rotation % 90 === 0) {
        switch (rotation) {
          case 0:
            offsets = [[0, -1], [0, 1]]; // Up and down
            break;
          case 90:
            offsets = [[-1, 0], [1, 0]]; // Left and right
            break;
          case 180:
            offsets = [[0, -1], [0, 1]]; // Up and down (same as 0)
            break;
          case 270:
            offsets = [[-1, 0], [1, 0]]; // Left and right (same as 90)
            break;
        }
      } else {
        // Handle diagonal by extending outwards in both directions of the diagonal
        const diagonalStep = (rotation < 90 || (rotation > 180 && rotation < 270)) ? 1 : -1;
        offsets = [[-diagonalStep, -diagonalStep], [diagonalStep, diagonalStep]];
      }
      return offsets;
    },
    applyOffsetsAndFill(x, y, offsets) {
      for (const offset of offsets) {
        let currentX = x + offset[0] * this.cellSize;
        let currentY = y + offset[1] * this.cellSize;

        // Boundary Checks:
        if (currentX < 0 || currentX >= this.grid[0].length * this.cellSize ||
          currentY < 0 || currentY >= this.grid.length * this.cellSize) {
          continue; // Skip if out of bounds
        }

        let cell = this.getCellFromGrid(currentX, currentY);
        if (cell) {
          this.map[cell.i][cell.j] = 0;
          this.grid[cell.i][cell.j] = {
            i: cell.i,
            j: cell.j,
            code: "spot_" + cell.i + "_" + cell.j,
            name: "spot_" + cell.i + "_" + cell.j,
            width: this.cellSize,
            height: this.cellSize,
            x: this.cellSize * cell.i,
            y: this.cellSize * cell.j,
            closed: true,
            fill: "rgb(255, 0, 0)",
          };

          // mapperVal[cell.i][cell.j] = 0;
          // Update grid[cell.i][cell.j] properties as needed.
        }
      }
    },
    clearObstacleNearEndPos(i, j, rotation) {
    let offsets = [];
    if (rotation % 90 === 0) {
      // Standard directions (0, 90, 180, 270 degrees)
      switch (rotation) {
        case 0:
        case 180:
          offsets = [[0, -1], [0, 1], [0, -2], [0, 2]]; // Extend two tiles in vertical directions
          break;
        case 90:
        case 270:
          offsets = [[-1, 0], [1, 0], [-2, 0], [2, 0]]; // Extend two tiles in horizontal directions
          break;
      }
    } else {
      // Diagonal directions (like 45, 135, 225, 315 degrees)
      const diagonalStep = (rotation < 90 || (rotation > 180 && rotation < 270)) ? 1 : -1;
      offsets = [
        [-diagonalStep, -diagonalStep], [diagonalStep, diagonalStep],
        [-2*diagonalStep, -2*diagonalStep], [2*diagonalStep, 2*diagonalStep]
      ]; // Extend two tiles in diagonal directions
    }

    offsets.forEach(offset => {
      try {
        var cell_i = i + offset[0];
        var cell_j = j + offset[1];
        if (cell_i < 0 || cell_j < 0 || cell_i >= this.grid.length || cell_j >= this.grid[cell_i].length) {
          return; // Check boundaries to avoid errors
        }
        this.map[cell_i][cell_j] = 0; // Clear obstacle
        // Update grid cell as necessary, considering it's now two tiles away
        this.grid[cell_i][cell_j] = {
          i: cell_i,
          j: cell_j,
          code: "spot_" + cell_i + "_" + cell_j,
          name: "spot_" + cell_i + "_" + cell_j,
          width: this.cellSize,
          height: this.cellSize,
          x: this.cellSize * cell_i,
          y: this.cellSize * cell_j,
          closed: true,
          fill: "white",
        };
        console.log('offsetting', cell_i, cell_j)
      } catch (err) {
        console.log(err);
        // Handle exceptions or logging here
      }
    });
  },
    recognizeCorridorStartPos() {
      var self = this;
      self.startPosList = [];
      self.deadEndStartPosition = [];

      for (let k = 0; k < self.corridorEnds.length; k++) {
        var current = self.corridorEnds[k];
        if (current.points == null) continue;

        var longestPosition = this.getCenterPosition(current);
        if (longestPosition) {
          self.deadEndStartPosition.push({
            id: "deadEndStartPosition_" + self.deadEndStartPosition.length,
            x: longestPosition.x,
            y: longestPosition.y,
          });

          current.longestPoints = [longestPosition.x, longestPosition.y];

          let cell = this.getCellFromGrid(longestPosition.x, longestPosition.y);

          this.startPosList.push({
            x: cell.j,
            y: cell.i,
            corridorEndId: current.id,
          });

          this.map[cell.i][cell.j] = 0;
          this.grid[cell.i][cell.j] = {
            i: cell.i,
            j: cell.j,
            code: "spot_" + cell.i + "_" + cell.j,
            name: "spot_" + cell.i + "_" + cell.j,
            width: this.cellSize,
            height: this.cellSize,
            x: this.cellSize * cell.i,
            y: this.cellSize * cell.j,
            closed: true,
            fill: "rgb(0, 255, 0)",
          };
        }
      }
    },
    recognizeRoomStartPos() {
      var self = this;
      self.startPosList = [];
      self.roomStartPosition = [];

      for (let k = 0; k < self.obj.rooms.length; k++) {
        var currentRoom = self.obj.rooms[k];
        if (currentRoom.points == null) continue;
        var longestPosition
        if (this.drawingMeta.tdLoopBy == 'roomDoor') {
          longestPosition = this.getLongestPosition(currentRoom, false);
        }
        else {
          longestPosition = this.getLongestPosition(currentRoom, true);
        }

        if (longestPosition) {
          let cell = this.getCellFromGrid(longestPosition.x, longestPosition.y);

          // Check if the cell position is a wall, and skip if it is

          self.roomStartPosition.push({
            id: "roomStartPosition_" + self.roomStartPosition.length,
            x: longestPosition.x,
            y: longestPosition.y,
          });

          currentRoom.longestPoints = [longestPosition.x, longestPosition.y];

          this.startPosList.push({
            x: cell.j,
            y: cell.i,
            roomId: currentRoom._id,
          });

          this.map[cell.i][cell.j] = 0;
          this.grid[cell.i][cell.j] = {
            i: cell.i,
            j: cell.j,
            code: "spot_" + cell.i + "_" + cell.j,
            name: "spot_" + cell.i + "_" + cell.j,
            width: this.cellSize,
            height: this.cellSize,
            x: this.cellSize * cell.i,
            y: this.cellSize * cell.j,
            closed: true,
            fill: "green",
            stroke: "green",
          };
        }
      }
    },

    allWallsInBetween(n1, n2) {
      const isVertical = n1.j === n2.j; // if the two cells are vertically aligned
      const isHorizontal = n1.i === n2.i; // if the two cells are horizontally aligned

      if (isVertical) {
        const startY = Math.min(n1.i, n2.i);
        const endY = Math.max(n1.i, n2.i);

        // Check cells between n1 and n2 vertically
        for (let y = startY + 1; y < endY; y++) {
          if (!this.isWall(n1.j, y)) {
            return false;
          }
        }
      } else if (isHorizontal) {
        const startX = Math.min(n1.j, n2.j);
        const endX = Math.max(n1.j, n2.j);

        // Check cells between n1 and n2 horizontally
        for (let x = startX + 1; x < endX; x++) {
          if (!this.isWall(x, n1.i)) {
            return false;
          }
        }
      } else {
        // If not vertically or horizontally aligned, return false
        return false;
      }

      return true;
    },

    // Helper function to check if a cell is a wall
    isWall(i, j) {
      return this.map[i][j] === 1; // Change this condition based on your wall representation
    },

    recognizeWalls() {
      let prevCellI = 0;
      let prevCellJ = 0;

      this.walls.forEach((wall) => {
        const cell = this.getCellFromGrid(wall.x, wall.y);

        if (cell !== undefined) {
          const cellI = cell.i
          const cellJ = cell.j
          this.setCellAsWall(cellI, cellJ, "black");

          if (
            prevCellI &&
            prevCellJ &&
            prevCellI !== cellI &&
            prevCellJ !== cellJ
          ) {
            this.setCellAsWall(prevCellI, prevCellJ, "red");
            this.setCellAsWall(cellI, cellJ, "red");
            this.expandWallAroundCell(cellI, cellJ, "yellow");
          }

          prevCellI = cellI;
          prevCellJ = cellJ;
        }
      });

      this.refreshStage();
    },

    setCellAsWall(cellI, cellJ, fill) {
      this.map[cellI][cellJ] = 1;
      this.grid[cellI][cellJ] = {
        i: cellI,
        j: cellJ,
        code: `spot_${cellI}_${cellJ}`,
        name: `spot_${cellI}_${cellJ}`,
        width: this.cellSize,
        height: this.cellSize,
        x: this.cellSize * cellI,
        y: this.cellSize * cellJ,
        closed: true,
        fill: fill,
      };
    },

    expandWallAroundCell(cellI, cellJ, fill) {
      for (let x = -1; x <= 1; x++) {
        for (let y = -1; y <= 1; y++) {
          const cellIExpand = cellI + x;
          const cellJExpand = cellJ + y;
          this.setCellAsWall(cellIExpand, cellJExpand, fill);
        }
      }
    },

    refreshStage() {
      const pathFinderLayer = this.$refs.pathFinderLayer.getStage();
      pathFinderLayer.draw();
      const stage = this.$refs.stage.getStage();
      stage.draw();
    },

    getCellFromGrid(x, y) {
      // alert(x,y)
      let row = Math.round(this.originalStageSize.width / this.cellSize);
      let col = Math.round(this.originalStageSize.height / this.cellSize);

      for (var i = 0; i < row; i++) {
        for (var j = 0; j < col; j++) {
          try {
            if (
              x >= this.grid[i][j].x &&
              x <= this.grid[i][j].x + this.grid[i][j].width
            ) {
              if (
                y >= this.grid[i][j].y &&
                y <= this.grid[i][j].y + this.grid[i][j].height
              ) {
                return this.grid[i][j];
              }
            }
          } catch (err) {
            console.log("getCellFromGrid", err);
          }
        }
      }
    },
    prepareGrid(isUpdateColor,cleanPath=true) {
      let totalGrid = this.horizontalGridLines.length * this.verticalGridLines.length
      console.log('🚀 ~ totalGrid:', totalGrid.toLocaleString());

      // Temp, will comment this line
      // if (cleanPath){
      //   this.cleanPath();
      // }
      // const row = Math.round(this.originalStageSize.width / this.cellSize);
      // const col = Math.round(this.originalStageSize.height / this.cellSize);
      // // console.log("Total number of grid: ", row * col, "Row: ", row, "Col: ", col)
      // // // Initialize this.map using Array.from()
      // this.map = Array.from({ length: row }, () => Array(col).fill(0));

      // if (isUpdateColor) {
      //   // Initialize this.grid and this.map in the same loop to avoid redundancy
      //   this.grid = Array.from({ length: row }, (_, i) => {
      //     return Array.from({ length: col }, (_, j) => {
      //       return {
      //         i,
      //         j,
      //         code: `spot_${i}_${j}`,
      //         name: `spot_${i}_${j}`,
      //         width: this.cellSize,
      //         height: this.cellSize,
      //         x: this.cellSize * i,
      //         y: this.cellSize * j,
      //         stroke: "black",
      //         strokeWidth: 1,
      //         closed: true,
      //       };
      //     });
      //   });
      // }
    },
    gridClick(item) {
      console.log("clicked on i,j:", item.i, item.j);
    },

    cleanPath() {
      this.cellsProcessed = [];
      this.path = [];
    },
    detectObstacle() {
      for (let k = 0; k < this.obstacles.length; k++) {
        const obstacle = this.obstacles[k];
        if (!obstacle.points) continue;

        this.processObstaclePoints(obstacle.points, "green");
      }
    },

    processObstaclePoints(points, strokeColor) {
      for (let i = 0; i < points.length; i += 2) {
        let currentPointX = points[i];
        let currentPointY = points[i + 1];

        this.addWall(currentPointX, currentPointY, strokeColor);

        if (i + 3 < points.length) {
          this.addIntermediateWalls(
            points[i],
            points[i + 1],
            points[i + 2],
            points[i + 3],
            "yellow"
          );
        }
      }
    },

    addIntermediateWalls(x1, y1, x2, y2, strokeColor) {
      const distanceInPixel = drawing.getDistanceInPixel(x1, y1, x2, y2);
      const totalPoint = Math.round(distanceInPixel / this.cellSize) + 2;
      const distancePerPointX = (x2 - x1) / totalPoint;
      const distancePerPointY = (y2 - y1) / totalPoint;

      for (let j = 0; j < totalPoint; j++) {
        const pointX = x1 + distancePerPointX * (j + 1);
        const pointY = y1 + distancePerPointY * (j + 1);
        this.addWall(pointX, pointY, strokeColor);
      }
    },

    addWall(x, y, stroke) {
      this.walls.push({
        id: "walls_" + this.walls.length,
        x: x,
        y: y,
        stroke: stroke,
      });
    },

    generateWallBasedOnRoom() {
      var self = this;
      // var pointList = [];
      for (let k = 0; k < self.obj.rooms.length; k++) {
        var currentRoom = self.obj.rooms[k];
        if (currentRoom.points == null) continue;

        var obstaclePoints = _.cloneDeep(currentRoom.points);
        obstaclePoints.push(obstaclePoints[0], obstaclePoints[1]);

        if (this.obstacles == null) this.obstacles = [];
        this.obstacles.push({
          code: "obstacle" + (this.obstacles.length + 1),
          name: "Obstacle " + (this.obstacles.length + 1),
          points: obstaclePoints,
          stroke: this.drawingMeta.strokeColor,
          strokeWidth: this.drawingMeta.strokeWidth,
          floorPlanId: this.obj._id,
        });

        // for (let i = 0; i < currentRoom.points.length; i = i + 2) {
        //   var currentPointX = currentRoom.points[i];
        //   var currentPointY = currentRoom.points[i + 1];

        //   // pointList.push(currentPointX);
        //   // pointList.push(currentPointY);
        // }
      }
    },
    processRoomAuto() {
      this.pageLoading = true;
      var analysisMethod = "color";
      switch (this.drawingMeta.analysisMethod) {
        case "Color":
          analysisMethod = "color";
          break;
        case "Thickness":
          analysisMethod = "thickness";
          break;
        case "ML/DL":
          analysisMethod = "ml";
          break;
        case "Room":
          this.generateWallBasedOnRoom();
          this.pageLoading = false;
          return;
        default:
          break;
      }
      var color_code = drawing.hexToRgbArray(
        this.drawingMeta.analysisColorCode
      );

      var data = {
        uri: this.floorPlanUrl,
        method: analysisMethod,
        category: this.drawingMeta.analysisCategory,
        output: "/tmp",
        //color
        color_code: color_code,
        //template
        template_threshold: 0.05,
        template_list: [],
        //deeplearning
        colorize: 1,
        postprocess: 0,
      };
      this.loadingDeepFloorPlane = true;
      this.deepFloorPlanApi
        .analyze(data)
        .then((response) => {
          var points = response.data.points;
          for (let i = 0; i < points.length; i++) {
            var currentRoom = points[i];
            var roomPoints = [];
            for (let j = 0; j < currentRoom.length; j++) {
              roomPoints.push(currentRoom[j][0], currentRoom[j][1]);
            }
            if (response.data.category == "room") {
              this.obj.rooms.push({
                code: "room" + (this.obj.rooms.length + 1),
                name: "Room " + (this.obj.rooms.length + 1),
                points: roomPoints,
                stroke: this.drawingMeta.strokeColor,
                strokeWidth: this.drawingMeta.strokeWidth,
                closed: true,
                fill: helper.hexToRgbA(
                  this.drawingMeta.fillColor,
                  this.drawingMeta.fillOpacity
                ),
                floorPlanId: this.obj._id,
              });
            } else if (response.data.category == "wall") {
              this.obstacles.push({
                code: "obstacle" + (this.obstacles.length + 1),
                name: "Obstacle " + (this.obstacles.length + 1),
                points: roomPoints,
                stroke: this.drawingMeta.strokeColor,
                strokeWidth: this.drawingMeta.strokeWidth,
                closed: true,
                fill: helper.hexToRgbA(
                  this.drawingMeta.fillColor,
                  this.drawingMeta.fillOpacity
                ),
                floorPlanId: this.obj._id,
              });
            }
          }

          this.loadingDeepFloorPlane = false;
          this.pageLoading = false;

          this.autoDrawDiagonalDistance();
        })
        .catch(({ data }) => {
          this.loadingDeepFloorPlane = false;
          this.pageLoading = false;
          this.toast("Error", helper.getErrorMessage(data), "danger");
        });
    },
    toolScaleClick() {
      this.drawTypeSelect("scale");
      this.toast(
        "Instruction",
        "Please click two points on the drawing and insert your scale in meter",
        "primary"
      );
    },
    toolAIClick() {
      this.drawTypeSelect("aiSuggest");
      this.activePanel = "aiConfirmationModal";
      this.aiConfirmationModal = true;
    },
    onScaleSettingConfirmation() {
      var self = this;
      self.obj.scale.lengthInMeter = parseFloat(self.newScale);
      delete this.obj.scale.dash;
      this.api
        .updateScale(self.obj)
        .then(() => {
          self.toast("Success", "Update Success", "success");
          self.scaleSettingPopup = false;
          self.scaleLinePreview = false;
          self.resetObj();
          self.toolClick("cancel");
        })
        .catch(({ data }) => {
          self.toast("Error", data.message, "danger");
        });
    },
    onScaleSettingCancel() {
      this.scaleSettingPopup = false;
      this.scaleLinePreview = false;
      this.toolClick("cancel");
      this.resetObj();
    },
    async onAiConfirmation() {
      const floorPlanId = this.obj._id;
      const self = this;
      const kreoScaleObj = {
        kreoXScale: this.kreoScale.x,
        kreoYScale: this.kreoScale.x,
      };
      const userData = JSON.parse(window.localStorage.getItem('user'));

      if (!self.obj.isKreoAIReady && !userData.creditBalance) {
        let errorMessage = 'Insufficient AI credit balance. Please topup and try again';
        self.toast("Error", errorMessage, "danger");
        self.activePanel = null;
        self.updateCursor("");
        return;
      }


      self.resizeLoading = true;
      this.currentLoadingMessage = this.kreoLoadingMessage[0];

      let messageIndex = 0;

      await self.makeKreoAiApiCall(floorPlanId, kreoScaleObj, self, messageIndex);
      this.updateCursor("");
    },
    async makeKreoAiApiCall(floorPlanId, kreoScaleObj, self, messageIndex) {
      try {
        const response = await this.kreoAiApi.autoMeasure(floorPlanId, kreoScaleObj);

        if (!response.isKreoAIReady) {
          setTimeout(async () => {
            messageIndex = (messageIndex + 1) % this.kreoLoadingMessage.length;
            self.currentLoadingMessage = this.kreoLoadingMessage[messageIndex];
            await self.makeKreoAiApiCall(floorPlanId, kreoScaleObj, self, messageIndex);
          }, 5000);
        } else {
          // self.activePanel = null;
          // self.currentLoadingMessage = "Process complete";
          self.resizeLoading = false;
          self.resetObj();
        }
      } catch (error) {
        console.error("API call failed with error:", error);
        setTimeout(async () => {
          messageIndex = (messageIndex + 1) % this.kreoLoadingMessage.length;
          self.currentLoadingMessage = this.kreoLoadingMessage[messageIndex];
          await self.makeKreoAiApiCall(floorPlanId, kreoScaleObj, self, messageIndex);
        }, 5000);
      }
    },
    onCancelAIConfirmation() {
      this.activePanel = null;
      // this.updateCursor("");
      this.drawingState = null;
      this.isDrawingActive = false;
      this.handleDone();
      this.drawType = "";
      this.toolClick("cancel");
      this.$refs.wpMenuLeftRef.switchMenu('');
      this.resizeLoading = false;
      clearInterval(this.intervalId);
      // this.drawTypeSelect = "";
    },
    drawTypeSelect(draw) {
      if (draw == "cancel") {
        this.handleDone();
        this.drawType = "";
        return;
      }
      this.drawType = draw;
      this.drawingState = "";

      this.analysisMethod = {
        category: "",
        method: "",
      };

      this.updateCursor("crosshair");
      this.stroke = "yellow";
    },
    updateCursor(cursor) {
      let stage = this.$refs.stage.getStage();
      stage.container().style.cursor = cursor;
    },
    handleClear() {
      this.exitSeperations = [];
      this.exitStairWidths = [];
      this.diagonalDistances = [];
      this.travelDistances = [];
      this.commonPaths = [];
      this.deadEnds = [];
      this.obj.rooms = [];
      this.obj.doors = [];
      this.obstacles = [];
      this.exitStairAreas = [];
      this.scales = [];
      this.exitStairDoors = [];
      this.corridorEnds = [];
      this.deadEnds = [];
    },
    findAvailableNumber(drawingArray) {
      const existingNumbers = new Set(
        drawingArray.map((item) => parseInt(item.name.replace(/\D/g, ''), 10))
      );

      let availableNumber = 1;

      while (existingNumbers.has(availableNumber)) {
        availableNumber++;
      }

      return availableNumber;
    },
    handleDrawRoom() {
      this.isDrawingActive = true;
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;
      const newRoomNumber = this.findAvailableNumber(this.obj.rooms);

      if (this.drawingState === "") {
        this.obj.rooms.push({
          floorPlanId: this.obj._id,
          code:
            "room_" +
            moment().format("YYMMDDHHmmss") +
            "_" +
            Math.floor(Math.random() * 16777215).toString(),
          name: "Room " + newRoomNumber,
          points: [x, y],

          stroke: this.drawingMeta.strokeColor,
          strokeWidth: this.drawingMeta.strokeWidth,
          closed: true,
          fill: helper.hexToRgbA(
            this.drawingMeta.fillColor,
            this.drawingMeta.fillOpacity
          ),
          roomDoors: [],
        });
        this.drawingState = "progress";
      } else if (this.drawingState === "progress") {
        this.obj.rooms[this.obj.rooms.length - 1].points.push(x, y);
      } else if (this.drawingState == "end") {
        let payload = { ...this.obj.rooms[this.obj.rooms.length - 1] };
        delete payload.roomDoors;

        this.createAndHandleApiResponse(this.roomApi, payload);
        this.drawingState = "";
      }
      // this.handleSaveHistory();
      this.autoDrawDiagonalDistance();
    },
    handleDrawScale() {
      this.isDrawingActive = true;
      this.scaleLinePreview = true;
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;
      if (this.drawingState === "") {
        this.mouseClickPoint.push({
          x: x,
          y: y,
        });
        this.obj.scale = {
          code: "scale",
          name: "scale",
          points: [x, y],
          stroke: "#FF4D4F",
          strokeWidth: 3,
          lineCap: "round",
          lineJoin: "round",
          draggable: true,
          fill: "rgb(255,77,79,0.5)",
          dash: [5, 5],
        };
        this.drawingState = "end";
      } else if (this.drawingState === "end") {
        // if (this.anchors.length > 0) {
        //   this.anchors[this.anchors.length - 1].stroke = "black";
        // }
        // this.anchors.push({
        //   id: Math.round(Math.random() * 10000).toString(),
        //   x: x,
        //   y: y,
        //   stroke: "green",
        // });

        this.obj.scale.points = [
          this.mouseClickPoint[0].x,
          this.mouseClickPoint[0].y,
          x,
          y,
        ];

        // this.anchors = [];
        this.mouseClickPoint = [];
        this.drawingState = "";

        this.scaleSettingPopup = true;
        // this.scaleLinePreview = false;
      }
    },

    async handleDrawStairExit() {
      this.isDrawingActive = true;
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;
      const newNumber = this.findAvailableNumber(this.exitStairAreas);

      if (this.drawingState === "") {
        this.exitStairAreas.push({
          code:
            "stairExit" +
            (this.exitStairAreas.length + 1) +
            "_" +
            Math.floor(Math.random() * 16777215).toString(),
          name: "Exit " + newNumber,
          points: [x, y],
          stroke: this.drawingMeta.strokeColor,
          strokeWidth: this.drawingMeta.strokeWidth,
          closed: true,
          fill: helper.hexToRgbA(
            this.drawingMeta.fillColor,
            this.drawingMeta.fillOpacity
          ),
        });
        this.drawingState = "progress";
      } else if (this.drawingState === "progress") {
        this.exitStairAreas[this.exitStairAreas.length - 1].points.push(x, y);
      } else if (this.drawingState == "end") {
        let payload = {
          ...this.exitStairAreas[this.exitStairAreas.length - 1],
        };
        await this.createAndHandleApiResponse(this.exitStairAreaApi, payload);
        this.drawingState = "";
      }
      // this.handleSaveHistory();
    },

    async handleDrawStairExitRect() {
      this.isDrawingActive = true;
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;
      const newNumber = this.findAvailableNumber(this.exitStairAreas);

      if (this.drawingState === "") {
        this.exitStairAreas.push({
          floorPlanId: this.obj._id,
          code:
            "stairExit" +
            newNumber +
            "_" +
            Math.floor(Math.random() * 16777215).toString(),
          name: "Exit " + newNumber,
          points: [x, y],
          stroke: this.drawingMeta.strokeColor,
          strokeWidth: this.drawingMeta.strokeWidth,
          closed: true,
          fill: helper.hexToRgbA(
            this.drawingMeta.fillColor,
            this.drawingMeta.fillOpacity
          ),
        });
        this.drawingState = "end";
      } else if (this.drawingState == "end") {
        let payload = {
          ...this.exitStairAreas[this.exitStairAreas.length - 1],
        };
        await this.createAndHandleApiResponse(this.exitStairAreaApi, payload);
        this.drawingState = "";
      }
      // this.handleSaveHistory();
    },
    handleDrawDoorDirection(direction) {
      this.isDrawingActive = true;
      this.addImage();

      // this.handleSaveHistory();
    },
    handleDrawDoorPolygon() {
      this.isDrawingActive = true;
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;
      if (this.drawingState === "") {
        this.obj.doors.push({
          code:
            "door" +
            (this.obj.doors.length + 1) +
            "_" +
            Math.floor(Math.random() * 16777215).toString(),
          name: "Door " + (this.obj.doors.length + 1),
          doorType: "Swing In",
          points: [x, y],
          stroke: this.drawingMeta.strokeColor,
          strokeWidth: this.drawingMeta.strokeWidth,
          closed: true,
          fill: helper.hexToRgbA(
            this.drawingMeta.fillColor,
            this.drawingMeta.fillOpacity
          ),
          lineCap: "Bevel",
          lineJoin: "Bevel",
          draggable: true,
          pointerAtBeginning: true,
          pointerAtEnding: true,
        });
        this.drawingState = "progress";
      } else if (this.drawingState === "progress") {
        this.obj.doors[this.obj.doors.length - 1].points.push(x, y);
      } else if (this.drawingState == "end") {
        this.drawingState = "";
      }
      // this.handleSaveHistory();
    },

    async handleDrawObstacle() {
      this.isDrawingActive = true;
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;
      if (this.drawingState === "") {
        this.drawingState = "progress";
      } else if (this.drawingState === "progress") {
        this.obstacles[this.obstacles.length - 1].points.push(x, y);
      } else if (this.drawingState == "end") {
        let payload = { ...this.obstacles[this.obstacles.length - 1] };
        const response = await this.obstacleApi.create(payload)
        if (response) {
          const matchedItem = this.obstacles.find((item) => item.code === response.code);
          if (matchedItem) {
            matchedItem._id = response.id;
          }
        }
        this.drawingState = "";
      }
      // this.handleSaveHistory();
    },

    async handleDrawObstacleRect() {
      this.isDrawingActive = true;
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;

      const codePrefix =
        this.drawType === "obstructionRect" ? "obstruction_" : "obstacle_";
      const namePrefix =
        this.drawType === "obstructionRect" ? "Obstruction " : "Obstacle ";
      const newNumber = this.findAvailableNumber(this.obstacles);


      if (this.drawingState === "") {
        let numericValue = parseInt(this.drawingMeta.fireRating, 10); // Convert the string to a number
        let fireRatingId = !isNaN(numericValue)
          ? this.fireRatings.find((el) => el.minutes === numericValue)?.id
          : null;

        this.obstacles.push({
          floorPlanId: this.obj._id,
          fireRatingId: fireRatingId,
          code:
            codePrefix +
            moment().format("YYMMDDHHmmss") +
            "_" +
            Math.floor(Math.random() * 16777215).toString(),
          name: namePrefix + newNumber,
          points: [x, y],
          stroke: this.drawingMeta.strokeColor,
          strokeWidth: this.drawingMeta.strokeWidth,
          closed: true,
          fill: this.drawingMeta.fillColor,
        });
        this.drawingState = "end";
      } else if (this.drawingState == "end") {
        let payload = { ...this.obstacles[this.obstacles.length - 1] };
        const response = await this.obstacleApi.create(payload)
        if (response) {
          const matchedItem = this.obstacles.find((item) => item.code === response.code);
          if (matchedItem) {
            matchedItem._id = response.id;
          }
        }
        this.drawingState = "";
      }
      // this.handleSaveHistory();
    },

    async handleDrawCorridorEnd() {
      this.isDrawingActive = true;
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;
      const newNumber = this.findAvailableNumber(this.corridorEnds);

      if (this.drawingState === "") {
        this.corridorEnds.push({
          floorPlanId: this.obj._id,
          code:
            "corridorEnd_" +
            moment().format("YYMMDDHHmmss") +
            "_" +
            Math.floor(Math.random() * 16777215).toString(),
          name: "Corridor Ends " + newNumber,
          points: [x, y],
          stroke: this.drawingMeta.strokeColor,
          strokeWidth: this.drawingMeta.strokeWidth,
          closed: true,
          fill: helper.hexToRgbA(
            this.drawingMeta.fillColor,
            this.drawingMeta.fillOpacity
          ),
        });
        this.drawingState = "progress";
      } else if (this.drawingState === "progress") {
        this.corridorEnds[this.corridorEnds.length - 1].points.push(x, y);
      } else if (this.drawingState == "end") {
        let payload = { ...this.corridorEnds[this.corridorEnds.length - 1] };
        await this.createAndHandleApiResponse(this.corridorEndApi, payload);
        this.drawingState = "";
      }
      // this.handleSaveHistory();
    },

    async handleDrawCorridorEndRect() {
      this.isDrawingActive = true;
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;
      const newNumber = this.findAvailableNumber(this.corridorEnds);

      if (this.drawingState === "") {
        this.corridorEnds.push({
          floorPlanId: this.obj._id,
          code: "corridorEnd_" + moment().format("YYMMDDHHmmss"),
          name: "Corridor Ends " + newNumber,
          points: [x, y],
          stroke: this.drawingMeta.strokeColor,
          strokeWidth: this.drawingMeta.strokeWidth,
          closed: true,
          fill: helper.hexToRgbA(
            this.drawingMeta.fillColor,
            this.drawingMeta.fillOpacity
          ),
        });
        this.drawingState = "end";
      } else if (this.drawingState == "end") {
        var startX = this.corridorEnds[this.corridorEnds.length - 1].points[0];
        var startY = this.corridorEnds[this.corridorEnds.length - 1].points[1];

        // this.corridorEnds[this.corridorEnds.length - 1].points.push(startX, y);
        // this.corridorEnds[this.corridorEnds.length - 1].points.push(x, y);
        // this.corridorEnds[this.corridorEnds.length - 1].points.push(x, startY);

        let payload = { ...this.corridorEnds[this.corridorEnds.length - 1] };
        await this.createAndHandleApiResponse(this.corridorEndApi, payload);
        this.drawingState = "";
      }
      // this.handleSaveHistory();
    },
    async handleAutoDrawObstacleBasedOnRoomRect() {
      const lastRoom = this.obj.rooms[this.obj.rooms.length - 1];

      const sidesMapping = {
        left: [0, 1, 0, 1, 6, 7, 6, 7],
        top: [0, 1, 2, 3, 2, 3, 0, 1],
        right: [2, 3, 4, 5, 4, 5, 2, 3],
        bottom: [4, 5, 6, 7, 6, 7, 4, 5],
      };

      // Loop through the sidesMapping to create each side's obstacle
      for (const side in sidesMapping) {
        const obstacle = {
          floorPlanId: this.obj._id,
          code:
            `auto_gen_${lastRoom.code}_room_obstacle_` +
            moment().format("YYMMDDHHmmss") +
            "_" +
            Math.floor(Math.random() * 16777215).toString(),
          name: "Obstacle " + (this.obstacles.length + 1),
          points: sidesMapping[side].map((idx) => lastRoom.points[idx]),
          stroke: this.drawingMeta.strokeColor,
          strokeWidth: this.drawingMeta.strokeWidth * 3,
          closed: true,
          fill: this.drawingMeta.fillColor,
        };
        this.obstacles.push(obstacle);
        let payload = { ...this.obstacles[this.obstacles.length - 1] };
        const response = await this.obstacleApi.create(payload)
        if (response) {
          const matchedItem = this.obstacles.find((item) => item.code === response.code);
          if (matchedItem) {
            matchedItem._id = response.id;
          }
        }
      }
    },

    async handleAutoDrawObstacleBasedOnRoom() {
      let payload = { ...this.obj.rooms[this.obj.rooms.length - 1] };
      delete payload.roomDoors;

      await this.createAndHandleApiResponse(this.roomApi, payload)

      const lastRoom = this.obj.rooms[this.obj.rooms.length - 1];

      for (let i = 0; i < lastRoom.points.length; i += 2) {
        const x1 = lastRoom.points[i];
        const y1 = lastRoom.points[i + 1];
        let x2, y2;

        // If it's the last point, pair it with the first point to close the loop.
        if (i === lastRoom.points.length - 2) {
          x2 = lastRoom.points[0];
          y2 = lastRoom.points[1];
        } else {
          x2 = i + 2;
          y2 = i + 3;
          x2 = lastRoom.points[x2];
          y2 = lastRoom.points[y2];
        }
        const obstacle = {
          points: [x1, y1, x2, y2],
          floorPlanId: this.obj._id,
          // code: `auto_gen_${this.obj._id}_room_obstacle_` + moment().format("YYMMDDHHmmss"),
          code:
            `auto_gen_${lastRoom.code}_room_obstacle_` +
            moment().format("YYMMDDHHmmss") +
            "_" +
            Math.floor(Math.random() * 16777215).toString(),
          name: "Obstacle " + (this.obstacles.length + 1),
          stroke: this.drawingMeta.strokeColor,
          strokeWidth: this.drawingMeta.strokeWidth * 2,
          closed: true,
          fill: this.drawingMeta.fillColor,
        };
        this.obstacles.push(obstacle);
        let payload = { ...this.obstacles[this.obstacles.length - 1] };
        const response = await this.obstacleApi.create(payload)
        if (response) {
          const matchedItem = this.obstacles.find((item) => item.code === response.code);
          if (matchedItem) {
            matchedItem._id = response.id;
          }
        }
      }
    },

    async handleDrawRoomRect() {
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;
      const newRoomNumber = this.findAvailableNumber(this.obj.rooms);

      if (this.drawingState === "") {
        this.obj.rooms.push({
          floorPlanId: this.obj._id,
          code:
            "room_" +
            moment().format("YYMMDDHHmmss") +
            "_" +
            Math.floor(Math.random() * 16777215).toString(),
          name: "Room " + newRoomNumber,
          points: [x, y],
          stroke: this.drawingMeta.strokeColor,
          strokeWidth: this.drawingMeta.strokeWidth,
          closed: true,
          fill: helper.hexToRgbA(
            this.drawingMeta.fillColor,
            this.drawingMeta.fillOpacity
          ),
          roomDoors: [],
          draggable: false,
          subRooms: [],
        });
        this.drawingState = "end";
      } else if (this.drawingState == "end") {
        var startX = this.obj.rooms[this.obj.rooms.length - 1].points[0];
        var startY = this.obj.rooms[this.obj.rooms.length - 1].points[1];
        console.warn(this.obj.rooms[this.obj.rooms.length - 1]);

        let payload = { ...this.obj.rooms[this.obj.rooms.length - 1] };
        delete payload.roomDoors;

        await this.createAndHandleApiResponse(this.roomApi, payload);
        this.drawingState = "";
        this.handleAutoDrawObstacleBasedOnRoomRect();
      }
      // this.handleSaveHistory();
      this.autoDrawDiagonalDistance();
    },

    async handleDrawCommonPath() {
      this.isDrawingActive = true;
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;
      const newCommonPathNumber = this.findAvailableNumber(this.commonPaths);


      if (this.drawingState === "") {
        this.commonPaths.push({
          floorPlanId: this.obj._id,
          code:
            "commonPath_" +
            moment().format("YYMMDDHHmmss") +
            "_" +
            Math.floor(Math.random() * 16777215).toString(),
          name: "Common Path " + newCommonPathNumber,
          points: [x, y],
          stroke: this.drawingMeta.strokeColor,
          strokeWidth: this.drawingMeta.strokeWidth,
          fill: this.drawingMeta.strokeColor,
          closed: false,
          pointerAtBeginning: false,
          pointerAtEnding: true,
          roomId: null,
        });
        const lastCommonPath = this.commonPaths[this.commonPaths.length - 1];
        lastCommonPath.points.push(x, y);
        this.drawingState = "progress";
      } else if (this.drawingState === "progress") {
        const lastCommonPath = this.commonPaths[this.commonPaths.length - 1];
        lastCommonPath.points.push(x, y);
      } else if (this.drawingState === "end") {
        let payload = { ...this.commonPaths[this.commonPaths.length - 1] };
        payload.points.splice(-2);
        await this.createAndHandleApiResponse(this.commonPathApi, payload);
      }
      // this.handleSaveHistory();
    },
    handleDrawFireRating() {
      this.isDrawingActive = true;
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;

      if (this.drawingState === "") {
        this.fireRating.push({
          floorPlanId: this.obj._id,
          code:
            "fireRating" +
            moment().format("YYMMDDHHmmss") +
            "_" +
            Math.floor(Math.random() * 16777215).toString(),
          name: "Fire Rating " + (this.fireRating.length + 1),
          points: [x, y],
          stroke: this.drawingMeta.strokeColor,
          strokeWidth: this.drawingMeta.strokeWidth,
          fill: this.drawingMeta.strokeColor,
          closed: false,
          lineCap: "round", // Set line cap to round for a smooth line
        });
        this.drawingState = "progress";
      } else if (this.drawingState === "progress") {
        const lastFireRating = this.fireRating[this.fireRating.length - 1];
        lastFireRating.points.push(x, y);
      }

      // this.handleSaveHistory();
    },
    async handleDrawDeadEnd() {
      this.isDrawingActive = true;
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;
      const newNumber = this.findAvailableNumber(this.deadEnds);

      if (this.drawingState === "") {
        this.deadEnds.push({
          floorPlanId: this.obj._id,
          code:
            "deadEnd_" +
            moment().format("YYMMDDHHmmss") +
            "_" +
            Math.floor(Math.random() * 16777215).toString(),
          name: "Dead End " + newNumber,
          points: [x, y],
          stroke: this.drawingMeta.strokeColor,
          strokeWidth: this.drawingMeta.strokeWidth,
          fill: this.drawingMeta.strokeColor,
          closed: false,
          pointerAtBeginning: false,
          pointerAtEnding: true,
          corridorEndId: null,
        });
        const lastDeadEnd = this.deadEnds[this.deadEnds.length - 1];
        lastDeadEnd.points.push(x, y);
        this.drawingState = "progress";
      } else if (this.drawingState === "progress") {
        const lastDeadEnd = this.deadEnds[this.deadEnds.length - 1];
        lastDeadEnd.points.push(x, y);
      } else if (this.drawingState === "end") {
        let payload = { ...this.deadEnds[this.deadEnds.length - 1] };
        payload.points.splice(-2);
        await this.createAndHandleApiResponse(this.deadEndApi, payload);
        this.drawingState = "";
      }
      // this.handleSaveHistory();
    },

    async handleDrawTravelDistance() {
      this.isDrawingActive = true;
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;
      const newNumber = this.findAvailableNumber(this.travelDistances);

      if (this.drawingState === "") {
        if (this.travelDistances == null) this.travelDistances = [];
        this.travelDistances.push({
          floorPlanId: this.obj._id,
          code:
            "travelDistance_" +
            moment().format("YYMMDDHHmmss") +
            "_" +
            Math.floor(Math.random() * 16777215).toString(),
          name: "Travel Distance " + newNumber,
          points: [x, y],
          stroke: this.drawingMeta.strokeColor,
          strokeWidth: this.drawingMeta.strokeWidth,
          fill: this.drawingMeta.strokeColor,
          closed: false,
          pointerAtBeginning: false,
          pointerAtEnding: true,
          roomId: null,
        });

        const lastTravelDistances = this.travelDistances[this.travelDistances.length - 1];
        lastTravelDistances.points.push(x, y);
        this.drawingState = "progress";
      } else if (this.drawingState === "progress") {
        const lastTravelDistances =
          this.travelDistances[this.travelDistances.length - 1];
        lastTravelDistances.points.push(x, y);
      } else if (this.drawingState === "end") {
        let payload = {
          ...this.travelDistances[this.travelDistances.length - 1],
        };
        payload.points.splice(-2);
        await this.createAndHandleApiResponse(this.travelDistanceApi, payload);
      }
      // this.handleSaveHistory();
    },

    handleDrawDiagonalDistance() {
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;
      if (this.drawingState === "") {
        this.mouseClickPoint.push({
          x: x,
          y: y,
        });
        this.diagonalDistances.push({
          floorPlanId: this.obj._id,
          code:
            "diagonalDistance_" +
            moment().format("YYMMDDHHmmss") +
            "_" +
            Math.floor(Math.random() * 16777215).toString(),
          name: "Diagonal " + +(this.co.length + 1),
          points: [x, y],
          stroke: this.stroke,
          strokeWidth: this.strokeWidth,
          lineCap: "round",
          lineJoin: "round",
          //draggable: true,
        });

        this.drawingState = "end";
      } else if (this.drawingState === "end") {
        this.diagonalDistances[this.diagonalDistances.length - 1].points = [
          this.mouseClickPoint[0].x,
          this.mouseClickPoint[0].y,
          x,
          y,
        ];

        // this.anchors = [];
        this.mouseClickPoint = [];
        this.drawingState = "";
      }

      // this.handleSaveHistory();
    },
    async handleDrawExitStairWidth() {
      this.isDrawingActive = true;
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;
      const newNumber = this.findAvailableNumber(this.exitStairWidths);

      if (this.drawingState === "") {
        this.mouseClickPoint.push({
          x: x,
          y: y,
        });
        this.exitStairWidths.push({
          floorPlanId: this.obj._id,
          code: "exitStairWidth_" + newNumber + '_' + moment().format("YYMMDDHHmmss"),
          name: "Stair " + newNumber,
          points: [x, y],
          lineCap: "round",
          lineJoin: "round",
          stroke: this.drawingMeta.strokeColor,
          strokeWidth: this.drawingMeta.strokeWidth,
          closed: true,
        });

        this.drawingState = "end";
      } else if (this.drawingState === "end") {
        this.exitStairWidths[this.exitStairWidths.length - 1].points = [
          this.mouseClickPoint[0].x,
          this.mouseClickPoint[0].y,
          x,
          y,
        ];

        this.mouseClickPoint = [];
        let payload = {
          ...this.exitStairWidths[this.exitStairWidths.length - 1],
        };
        await this.createAndHandleApiResponse(this.exitStairWidthApi, payload);
        this.drawingState = "";
      }
      // this.handleSaveHistory();
    },
    handleDrawDoorExit() {
      this.isDrawingActive = true;
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();

      const aspectRatio = 1;

      var width = parseInt(this.drawingMeta.width);
      var depth = parseInt(this.drawingMeta.width * aspectRatio);
      var height = depth;

      const x = mousePos.x - width / 2;
      const y = mousePos.y - depth / 2;

      // Determine the door orientation based on drawType
      var points;
      const { x: rotatedX, y: rotatedY } = this.getRotatedCenterCoordinates(
        x,
        y,
        width,
        height,
        parseFloat(this.drawingMeta.doorRotation)
      );
      points = this.calculateRotatedPoints(
        mousePos.x,
        mousePos.y,
        width,
        height,
        parseFloat(this.drawingMeta.doorRotation)
      );

      if (this.drawingState === "") {
        this.exitStairDoors.push({
          floorPlanId: this.obj._id,
          code: "exitStairDoors_" + (this.exitStairDoors.length + 1),
          name: "Exit Door " + (this.exitStairDoors.length + 1),
          points: points,
          stroke: this.stroke,
          stroke: this.drawingMeta.strokeColor,
          strokeWidth: this.drawingMeta.strokeWidth,
          closed: true,
          fill: helper.hexToRgbA(
            this.drawingMeta.fillColor,
            this.drawingMeta.fillOpacity
          ),
          draggable: true,
        });

        this.drawingState = "progress";
      } else if (this.drawingState === "progress") {
        this.exitStairDoors[this.exitStairDoors.length - 1].points.push(x, y);
      } else if (this.drawingState == "end") {
        this.autoDrawExitSeperation();
      }
      // this.handleSaveHistory();
    },
    async handleDrawDoorExitVertical() {
      this.isDrawingActive = true;
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();

      const aspectRatio = 1;

      const width = this.drawingMeta.width;
      const height = this.drawingMeta.width * aspectRatio;
      const x = mousePos.x - width / 2;
      const y = mousePos.y - height / 2;
      // const points = this.calculateRotatedPoints(x, y, width, height, parseFloat(this.drawingMeta.doorRotation));
      const { x: rotatedX, y: rotatedY } = this.getRotatedCenterCoordinates(
        x,
        y,
        width,
        height,
        parseFloat(this.drawingMeta.doorRotation)
      );
      const points = this.calculateRotatedPoints(
        mousePos.x,
        mousePos.y,
        width,
        height,
        parseFloat(this.drawingMeta.doorRotation)
      );

      this.exitStairDoors.push({
        floorPlanId: this.obj._id,
        code: "exitStairDoors_" + (this.exitStairDoors.length + 1),
        name: "Exit Door " + (this.exitStairDoors.length + 1),
        doorType: "swingIn",
        points: points,
        width: parseFloat(this.drawingMeta.width),
        height: this.drawingMeta.width * aspectRatio,
        posX: rotatedX,
        posY: rotatedY,
        rotation: parseFloat(this.drawingMeta.doorRotation),
        stroke: this.drawingMeta.strokeColor,
        strokeWidth: this.drawingMeta.strokeWidth,
        closed: true,
        fill: helper.hexToRgbA(
          this.drawingMeta.fillColor,
          this.drawingMeta.fillOpacity
        ),
        draggable: true,
      });

      let payload = { ...this.exitStairDoors[this.exitStairDoors.length - 1] };
      await this.createAndHandleApiResponse(this.exitStairDoorApi, payload);
      this.drawingState = "";
      this.autoDrawExitSeperation();
      // this.handleSaveHistory();
    },
    async handleDrawDoorExitHorizontal() {
      this.isDrawingActive = true;
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;
      // var distanceToAdd = 20;
      var width = parseInt(this.drawingMeta.width);
      var widthInPixel = drawing.convertDistanceMeterToPixel(
        width / 1000,
        this.obj.scale.distanceInMeterForOnePixel
      );

      var depth = parseInt(this.drawingMeta.depth);
      var depthInPixel = drawing.convertDistanceMeterToPixel(
        depth / 1000,
        this.obj.scale.distanceInMeterForOnePixel
      );

      if (this.drawingState === "") {
        this.exitStairDoors.push({
          floorPlanId: this.obj._id,
          code: "exitStairDoors_" + (this.exitStairDoors.length + 1),
          name: "Exit Door " + (this.exitStairDoors.length + 1),
          doorType: "Swing In",
          points: [
            x,
            y,
            x + widthInPixel,
            y,
            x + widthInPixel,
            y + depthInPixel,
            x,
            y + depthInPixel,
          ],
          stroke: this.drawingMeta.strokeColor,
          strokeWidth: this.drawingMeta.strokeWidth,
          closed: true,
          fill: helper.hexToRgbA(
            this.drawingMeta.fillColor,
            this.drawingMeta.fillOpacity
          ),
          draggable: true,
        });

        // this.anchors = [];
        this.mouseClickPoint = [];
        this.drawingState = "";
        let payload = {
          ...this.exitStairDoors[this.exitStairDoors.length - 1],
        };
        await this.createAndHandleApiResponse(this.exitStairDoorApi, payload);
        this.autoDrawExitSeperation();
      }
      // this.handleSaveHistory();
    },
    getMiddlePoint(item) {
      return drawing.getMiddlePoint(item);
    },
    getFloorDiagonalPoints() {
      var self = this;
      var pointList = [];
      for (let k = 0; k < self.obj.rooms.length; k++) {
        var currentRoom = self.obj.rooms[k];
        if (currentRoom.points == null) continue;

        for (let i = 0; i < currentRoom.points.length; i = i + 2) {
          var currentPointX = currentRoom.points[i];
          var currentPointY = currentRoom.points[i + 1];
          pointList.push(currentPointX);
          pointList.push(currentPointY);
        }
      }

      var longest = 0;
      var point1 = [];
      var point2 = [];
      for (let i = 0; i < pointList.length; i = i + 2) {
        var currentPointX = pointList[i];
        var currentPointY = pointList[i + 1];

        for (let j = 0; j < pointList.length; j = j + 2) {
          var otherPointX = pointList[j];
          var otherPointY = pointList[j + 1];

          if (currentPointX == otherPointX && currentPointY == otherPointY)
            continue;

          var distance = drawing.getDistanceInPixel(
            currentPointX,
            currentPointY,
            otherPointX,
            otherPointY
          );
          if (distance > longest) {
            point1 = [currentPointX, currentPointY];
            point2 = [otherPointX, otherPointY];
            longest = distance;
          }
        }
      }
      return [point1[0], point1[1], point2[0], point2[1]];
    },

    autoDrawDiagonalDistance() {
      var self = this;

      var points = this.getFloorDiagonalPoints();
      if (points[0] == null) return;

      var point1 = [points[0], points[1]];
      var point2 = [points[2], points[3]];

      this.diagonalDistances = [];
      // this.stroke = "yellow";
      this.diagonalDistances.push({
        floorPlanId: self.obj._id,
        code: "diagonalDistances" + (this.diagonalDistances.length + 1),
        name: "Diagonal Distances " + (this.diagonalDistances.length + 1),
        points: [point1[0], point1[1], point2[0], point2[1]],
        stroke: "rgb(255, 0, 0, 0.5)",
        strokeWidth: 5,
        lineCap: "round",
        lineJoin: "round",
        dash: [10, 10],
      });

      this.obj.diagonalDistances = this.diagonalDistances;
    },

    autoDrawExitSeperation() {
      var self = this;

      const finalExitDoor = self.obj.doors.filter((door) => door.isFinalExitDoor)

      if (finalExitDoor.length < 2) {
        self.exitSeperations = [];
        self.obj.exitSeperations = self.exitSeperations;
        return;
      }
      var exitDistanceList = [];
      for (let i = 0; i < finalExitDoor.length; i++) {
        for (let j = 0; j < finalExitDoor.length; j++) {
          if (i == j) continue;

          var isDuplicate = false;
          for (let k = 0; k < exitDistanceList.length; k++) {
            if (
              exitDistanceList[k].code1 == finalExitDoor[i].code &&
              exitDistanceList[k].code2 == finalExitDoor[j].code
            ) {
              isDuplicate = true;
            }
            if (
              exitDistanceList[k].code2 == finalExitDoor[i].code &&
              exitDistanceList[k].code1 == finalExitDoor[j].code
            ) {
              isDuplicate = true;
            }
          }
          if (isDuplicate) continue;
          var center = drawing.getCenterPosition(
            [
              finalExitDoor[i].points[0],
              finalExitDoor[i].points[1],
            ],
            [finalExitDoor[i].points[2], finalExitDoor[i].points[3]]
          );
          var center2 = drawing.getCenterPosition(
            [
              finalExitDoor[j].points[0],
              finalExitDoor[j].points[1],
            ],
            [finalExitDoor[j].points[2], finalExitDoor[j].points[3]]
          );
          exitDistanceList.push({
            code1: finalExitDoor[i].code,
            code2: finalExitDoor[j].code,
            x: center[0],
            y: center[1],
            x2: center2[0],
            y2: center2[1],
          });
        }
      }

      this.exitSeperations = [];
      for (let k = 0; k < exitDistanceList.length; k++) {
        this.exitSeperations.push({
          code: "exitSeparation" + (this.exitSeperations.length + 1),
          name: "Separation " + (this.exitSeperations.length + 1),
          points: [
            exitDistanceList[k].x,
            exitDistanceList[k].y,
            exitDistanceList[k].x2,
            exitDistanceList[k].y2,
          ],
          stroke: "rgb(58, 141, 243, 0.3)",
          strokeWidth: 5,
          lineCap: "round",
          lineJoin: "round",
          /*
           * line segments with a length of 33px
           * with a gap of 10px
           */
          dash: [10, 10],
          floorPlanId: this.obj._id,
        });
      }

      self.obj.exitSeperations = self.exitSeperations;

      // return exitDistanceList;

      // for (let i = 0; i < this.exitStairDoors.length; i++) {
      //   for (let j = 0; j < this.exitStairDoors.length; j++) {}
      // }
    },
    handleDone() {
      switch (this.drawType) {
        case "polygon":
          this.handleDrawPolygon();
          break;
        case "travelDistance":
          this.handleDrawTravelDistance();
          break;
        case "commonPath":
          this.handleDrawCommonPath();
          break;
        case "fireRating":
          this.handleDrawFireRating();
          break;
        case "deadEnd":
          this.handleDrawDeadEnd();
          break;
        case "room":
          this.handleDrawRoom();
          break;
        case "stairExit":
          this.handleDrawStairExit();
          break;
        case "stairExitRect":
          this.handleDrawStairExitRect();
          break;
        case "obstacle":
        case "obstruction":
          this.handleDrawObstacle();
          break;
        case "obstacleRect":
        case "obstructionRect":
          this.handleDrawObstacleRect();
          break;
        case "corridorEnd":
          this.handleDrawCorridorEnd();
          break;
        case "corridorEndRect":
          this.handleDrawCorridorEndRect();
          break;
        case "doors":
          this.handleDrawDoorPolygon();
          break;
        case "exitDoor":
          this.drawingState = "";
          // this.handleDrawDoorExit();
          break;
      }
      this.updateCursor("default");
    },
    initSaveHistory() {
      this.step = 0;
      this.stageHistory = [
        {
          drawType: this.drawType,
          drawingState: this.drawingState,
          rooms: this.obj.rooms ? [...this.obj.rooms] : [],
          doors: this.obj.doors ? [...this.obj.doors] : [],
          exitStairAreas: this.exitStairAreas ? [...this.exitStairAreas] : [],
          exitStairDoors: this.exitStairDoors ? [...this.exitStairDoors] : [],
          exitStairWidths: this.exitStairWidths
            ? [...this.exitStairWidths]
            : [],
          exitSeperations: this.exitSeperations
            ? [...this.exitSeperations]
            : [],
          commonPaths: this.commonPaths ? [...this.commonPaths] : [],
          travelDistances: this.travelDistances
            ? [...this.travelDistances]
            : [],
          diagonalDistances: this.diagonalDistances
            ? [...this.diagonalDistances]
            : [],
          obstacles: this.obstacles ? [...this.obstacles] : [],
          corridorEnds: this.corridorEnds ? [...this.corridorEnds] : [],
          deadEnds: this.deadEnds ? [...this.deadEnds] : [],
        },
      ];
    },
    handleSaveHistory() {
      this.step += 1;
      var obj = {
        drawType: this.drawType,
        drawingState: this.drawingState,
        rooms: this.obj.rooms ? [...this.obj.rooms] : [],
        doors: this.obj.doors ? [...this.obj.doors] : [],
        exitStairAreas: this.exitStairAreas ? [...this.exitStairAreas] : [],
        exitStairDoors: this.exitStairDoors ? [...this.exitStairDoors] : [],
        exitStairWidths: this.exitStairWidths ? [...this.exitStairWidths] : [],
        exitSeperations: this.exitSeperations ? [...this.exitSeperations] : [],
        commonPaths: this.commonPaths ? [...this.commonPaths] : [],
        travelDistances: this.travelDistances ? [...this.travelDistances] : [],
        diagonalDistances: this.diagonalDistances
          ? [...this.diagonalDistances]
          : [],
        obstacles: this.obstacles ? [...this.obstacles] : [],
        corridorEnds: this.corridorEnds ? [...this.corridorEnds] : [],
        deadEnds: this.deadEnds ? [...this.deadEnds] : [],
      };
      var deep = _.cloneDeep(obj);
      /*
        Create a new history array by combining the existing history up to the current step
        with the newly cloned state added at the current step
      */
      this.stageHistory = [...this.stageHistory.slice(0, this.step), deep];
    },

    handlePrevStep() {
      if (this.step == 0) {
        return;
      } else {
        this.step = this.step - 1;
        let _cur = this.stageHistory[this.step];
        const {
          drawType,
          drawingState,
          rooms,
          doors,
          exitStairAreas,
          exitStairDoors,
          exitStairWidths,
          exitSeperations,
          commonPaths,
          travelDistances,
          diagonalDistances,
          obstacles,
          corridorEnds,
          deadEnds,
        } = _cur;
        // this.drawType = drawType;
        // this.drawingState = drawingState;
        this.obj.rooms = [...rooms];
        this.obj.doors = [...doors];
        this.exitStairAreas = [...exitStairAreas];
        this.exitStairDoors = [...exitStairDoors];
        this.exitStairWidths = [...exitStairWidths];
        this.exitSeperations = [...exitSeperations];
        this.commonPaths = [...commonPaths];
        this.travelDistances = [...travelDistances];
        this.diagonalDistances = [...diagonalDistances];
        this.obstacles = [...obstacles];
        this.corridorEnds = [...corridorEnds];
        this.deadEnds = [...deadEnds];
      }
      // if (this.drawType == "") {
      //   this.toolClick("cancel");
      // }
    },
    handleNextStep() {
      if (this.step == this.stageHistory.length - 1) {
        return;
      } else {
        this.step = this.step + 1;
        let _cur = this.stageHistory[this.step];
        const {
          drawType,
          drawingState,
          rooms,
          doors,
          exitStairAreas,
          exitStairDoors,
          exitStairWidths,
          exitSeperations,
          commonPaths,
          travelDistances,
          diagonalDistances,
          obstacles,
          corridorEnds,
          deadEnds,
        } = _cur;
        // this.drawType = drawType;
        // this.drawingState = drawingState;
        this.obj.rooms = [...rooms];
        this.obj.doors = [...doors];
        this.exitStairAreas = [...exitStairAreas];
        this.exitStairDoors = [...exitStairDoors];
        this.exitStairWidths = [...exitStairWidths];
        this.exitSeperations = [...exitSeperations];
        this.commonPaths = [...commonPaths];
        this.travelDistances = [...travelDistances];
        this.diagonalDistances = [...diagonalDistances];
        this.obstacles = [...obstacles];
        this.corridorEnds = [...corridorEnds];
        this.deadEnds = [...deadEnds];
      }
    },
    handleStageMouseDown(e) {
      this.isPaint = true;
      // if (this.analysisMethod.method == "color") {
      //   try {
      //     const mousePos = this.$refs.stage
      //       .getStage()
      //       .getRelativePointerPosition();
      //     const x = mousePos.x;
      //     const y = mousePos.y;
      //     var ctx = this.$refs.layer._konvaNode.canvas.getContext("2d");
      //     var data = ctx.getImageData(x, y, 1, 1).data;
      //     // console.log("data", ctx.getImageData(x, y, 1, 1).data);

      //     this.analysisColorCode = [data[0], data[1], data[2]];
      //     console.log("analysisColorCode", this.analysisColorCode);
      //   } catch (err) {}
      // }
      // if (this.analysisMethod.method == "template") {
      //   const mousePos = this.$refs.stage
      //     .getStage()
      //     .getRelativePointerPosition();
      //   const x = mousePos.x;
      //   const y = mousePos.y;

      //   if (this.analysisTemplateList.length > 2)
      //     this.analysisTemplateList = [];

      //   this.analysisTemplateList.push({
      //     code: "analysisTemplateList" + (this.analysisTemplateList.length + 1),
      //     name:
      //       "analysisTemplateList " + (this.analysisTemplateList.length + 1),
      //     x: x,
      //     y: y,
      //     width: 0,
      //     height: 0,
      //     stroke: Konva.Util.getRandomColor(),
      //     strokeWidth: 1,
      //     // closed: true,
      //     // fill: "rgb(58, 141, 243, 0.3)",
      //   });

      //   // this.analysisTemplateList.push({
      //   //   x: x,
      //   //   y: y,
      //   //   width: 0,
      //   //   height: 0,
      //   //   color: Konva.Util.getRandomColor(),
      //   // });
      // }
    },
    handleStageMouseClick(event) {
      if (event.evt.button == 2) {
        // // Right click
        // const supportedDrawTypes = [
        //   "room",
        // ];

        // if (supportedDrawTypes.includes(this.drawType)) {
        //   this.drawingState = "end";
        //   const handlerMap = {
        //     room: this.handleDrawRoom,    
        //   };

        //   const handlerFunction = handlerMap[this.drawType];

        //   if (handlerFunction) {
        //     handlerFunction();
        //   }
        // }        
        // this.handleDone();
        // this.drawType = "";
      } else {
        switch (this.drawType) {
          case "scale":
            this.handleDrawScale();
            break;
          case "exitStairWidth":
            this.handleDrawExitStairWidth();
            break;
          case "diagonalDistance":
            this.handleDrawDiagonalDistance();
            break;
          case "travelDistance":
            this.handleDrawTravelDistance();
            break;
          case "commonPath":
            this.handleDrawCommonPath();
            break;
          case "fireRating":
            this.handleDrawFireRating();
            break;
          case "deadEnd":
            this.handleDrawDeadEnd();
            break;
          case "room":
            this.handleDrawRoom();
            break;
          case "roomRect":
            this.handleDrawRoomRect();
            break;
          case "stairExit":
            this.handleDrawStairExit();
            break;
          case "stairExitRect":
            this.handleDrawStairExitRect();
            break;
          case "obstacle":
          case "obstruction":
            this.handleDrawObstacle();
            break;
          case "obstacleRect":
          case "obstructionRect":
            this.handleDrawObstacleRect();
            break;
          case "corridorEnd":
            this.handleDrawCorridorEnd();
            break;
          case "corridorEndRect":
            this.handleDrawCorridorEndRect();
            break;
          case "doors":
            this.handleDrawDoorPolygon();
            break;
          case "doorVertical":
            this.handleDrawDoorDirection("vertical");
            break;
          case "doorHorizontal":
            this.handleDrawDoorDirection("horizontal");
            break;
          case "exitDoor":
            this.handleDrawDoorExit();
            break;
          case "exitDoorVertical":
            this.handleDrawDoorExitVertical();
            break;
          case "exitDoorHorizontal":
            this.handleDrawDoorExitHorizontal();
            break;
        }
      }
    },
    handleSaveSilent() {
      this.handleSaveAndNotify(false);
    },
    handleSave() {
      this.handleSaveAndNotify(true);
    },
    handleSaveAndNotify(notify) {
      var self = this;
      self.obj.lines = this.lines;
      self.obj.scales = this.scales;
      self.obj.polygons = this.polygons;
      self.obj.obstacles = self.obj.obstacles ? this.obstacles : [];
      self.obj.exitSeperations = this.exitSeperations;
      self.obj.exitStairWidths = this.exitStairWidths;
      self.obj.diagonalDistances = this.diagonalDistances;
      self.obj.travelDistances = self.obj.travelDistances
        ? this.travelDistances
        : [];
      self.obj.commonPaths = this.commonPaths;
      self.obj.fireRating = this.fireRating;
      self.obj.exitStairAreas = this.exitStairAreas;
      self.obj.exitStairDoors = this.exitStairDoors;
      self.obj.corridorEnds = this.corridorEnds;
      self.obj.deadEnds = this.deadEnds;
      self.obj.kreoXScale = this.kreoXScale;
      self.obj.kreoYScale = this.kreoYScale;

      for (let i = 0; i < self.obj.rooms.length; i++) {
        if (self.obj.rooms[i].minimumNumberOfExitId == "")
          self.obj.rooms[i].minimumNumberOfExitId = null;
      }
      this.removeDoorPreview();
      this.handleDone();
      this.toolClick("cancel");

      this.api
        .update(self.obj, self.obj._id)
        .then(() => {
          if (notify) self.toast("Save", "Save Success", "success");
          // self.$router.push({ path: "/tenant/floorPlanlist" });
          self.resetObj();
        })
        .catch(({ data }) => {
          self.toast("Error", data.message, "danger");
        });
    },

    onContextMenu(e) {
      let stage = this.$refs.stage.getStage();
      e.evt.preventDefault();
      if (e.target === stage) {
        return;
      }
      var position = {
        left: e.evt.clientX + 4,
        top: e.evt.clientY + 4,
      };

      // this.handleDone();
      // this.drawTypeSelect(this.drawType);
    },
    handleStageMouseUp(e) {
      this.isPaint = false;
    },
    handleStageMouseMove() {
      // if (!this.isPaint) {
      //   return;
      // }
      if (this.analysisMethod.method == "template") {
        const mousePos = this.$refs.stage
          .getStage()
          .getRelativePointerPosition();
        const x = mousePos.x;
        const y = mousePos.y;
        // this.moveTo.x = x;
        // this.moveTo.y = y;

        const oldX =
          this.analysisTemplateList[this.analysisTemplateList.length - 1].x;
        const oldY =
          this.analysisTemplateList[this.analysisTemplateList.length - 1].y;
        this.analysisTemplateList[this.analysisTemplateList.length - 1].width =
          x - oldX;
        this.analysisTemplateList[this.analysisTemplateList.length - 1].height =
          y - oldY;
      }

      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      const x = mousePos.x;
      const y = mousePos.y;

      if (this.drawType === "commonPath") {
        if (this.drawingState === "progress") {
          const lastCommonPath = this.commonPaths[this.commonPaths.length - 1];
          lastCommonPath.points = lastCommonPath.points
            .slice(0, -2)
            .concat([x, y]);
          this.$refs.stage.getStage().batchDraw();
        }

        this.commonPaths.forEach((el, index) => {
          this.toolTip.x = mousePos.x + 5;
          this.toolTip.y = mousePos.y + 5;
          this.toolTip.text = this.getLineDetailInfo(this.commonPaths[index]);
          this.toolTip.visible = true;
        });
      }

      if (this.drawType === "fireRating") {
        if (this.drawingState === "progress") {
          const lastFireRating = this.fireRating[this.fireRating.length - 1];
          lastFireRating.points = lastFireRating.points
            .slice(0, -2)
            .concat([x, y]);
          this.$refs.stage.getStage().batchDraw();
        }

        this.fireRating.forEach((el, index) => {
          this.toolTip.x = mousePos.x + 5;
          this.toolTip.y = mousePos.y + 5;
          this.toolTip.text = this.getLineDetailInfo(this.fireRating[index]);
          this.toolTip.visible = true;
        });
      }

      if (this.drawType === "travelDistance") {
        if (this.drawingState === "progress") {
          const travelDistance =
            this.travelDistances[this.travelDistances.length - 1];
          travelDistance.points = travelDistance.points
            .slice(0, -2)
            .concat([x, y]);
          this.$refs.stage.getStage().batchDraw();
        }

        this.travelDistances.forEach((el, index) => {
          this.toolTip.x = mousePos.x + 5;
          this.toolTip.y = mousePos.y + 5;
          this.toolTip.text = this.getLineDetailInfo(
            this.travelDistances[index]
          );
          this.toolTip.visible = true;
        });
      }

      if (this.drawType === "deadEnd") {
        if (this.drawingState === "progress") {
          const deadEnd = this.deadEnds[this.deadEnds.length - 1];
          deadEnd.points = deadEnd.points.slice(0, -2).concat([x, y]);
          this.$refs.stage.getStage().batchDraw();
        }

        this.deadEnds.forEach((el, index) => {
          this.toolTip.x = mousePos.x + 5;
          this.toolTip.y = mousePos.y + 5;
          this.toolTip.text = this.getLineDetailInfo(this.deadEnds[index]);
          this.toolTip.visible = true;
        });
      }

      if (this.drawType === "roomRect") {
        if (this.drawingState === "end") {
          if (this.obj.rooms.length > 0) {
            const lastRoom = this.obj.rooms[this.obj.rooms.length - 1];
            lastRoom.points[2] = x;
            lastRoom.points[3] = lastRoom.points[1];
            lastRoom.points[4] = x;
            lastRoom.points[5] = y;
            lastRoom.points[6] = lastRoom.points[0];
            lastRoom.points[7] = y;
          }
          this.$refs.stage.getStage().batchDraw();
        }
      }

      if (this.drawType === "room") {
        if (this.drawingState === "progress") {
          const room = this.obj.rooms[this.obj.rooms.length - 1];
          room.points.splice(-2, 2, x, y);
        } else if (this.drawingState === "") {
          // Create a new room object with the starting point
          const newRoom = {
            code:
              "room" +
              (this.obj.rooms.length + 1) +
              "_" +
              Math.floor(Math.random() * 16777215).toString(),
            name: "Room " + (this.obj.rooms.length + 1),
            points: [x, y],
            stroke: this.drawingMeta.strokeColor,
            strokeWidth: this.drawingMeta.strokeWidth,
            closed: true,
            fill: helper.hexToRgbA(
              this.drawingMeta.fillColor,
              this.drawingMeta.fillOpacity
            ),
            roomDoors: [],
            floorPlanId: this.obj._id,
          };
          this.obj.rooms.push(newRoom);
          this.drawingState = "progress";
        }
        this.$refs.stage.getStage().batchDraw();
      }

      if (this.drawType === "stairExit") {
        if (this.drawingState === "progress") {
          const exitStairArea =
            this.exitStairAreas[this.exitStairAreas.length - 1];
          exitStairArea.points.splice(-2, 2, x, y);
        } else if (this.drawingState === "") {
          const newExitStair = {
            code:
              "exitStairAreas" +
              (this.exitStairAreas.length + 1) +
              "_" +
              Math.floor(Math.random() * 16777215).toString(),
            name: "Exit Stair Area " + (this.exitStairAreas.length + 1),
            points: [x, y],
            stroke: this.drawingMeta.strokeColor,
            strokeWidth: this.drawingMeta.strokeWidth,
            closed: true,
            fill: helper.hexToRgbA(
              this.drawingMeta.fillColor,
              this.drawingMeta.fillOpacity
            ),
            floorPlanId: this.obj._id,
          };
          this.exitStairAreas.push(newExitStair);
          this.drawingState = "progress";
        }
        this.$refs.stage.getStage().batchDraw();
      }

      if (this.drawType === "obstacle" || this.drawType === "obstruction") {
        const codePrefix =
          this.drawType === "obstruction" ? "obstruction_" : "obstacle_";
        const namePrefix =
          this.drawType === "obstruction" ? "Obstruction " : "Obstacle ";

        if (this.drawingState === "progress") {
          const room = this.obj.obstacles[this.obj.obstacles.length - 1];
          room.points.splice(-2, 2, x, y);
        } else if (this.drawingState === "") {
          const newObstacle = {
            code:
              codePrefix +
              moment().format("YYMMDDHHmmss") +
              "_" +
              Math.floor(Math.random() * 16777215).toString(),
            name: namePrefix + (this.obstacles.length + 1),
            points: [x, y],
            stroke: this.drawingMeta.strokeColor,
            strokeWidth: this.drawingMeta.strokeWidth,
            closed: true,
            fill: this.drawingMeta.fillColor,
            floorPlanId: this.obj._id,
          };
          this.obj.obstacles.push(newObstacle);
          this.drawingState = "progress";
        }
        this.$refs.stage.getStage().batchDraw();
      }

      if (this.drawType === "corridorEnd") {
        if (this.drawingState === "progress") {
          const room = this.obj.corridorEnds[this.obj.corridorEnds.length - 1];
          room.points.splice(-2, 2, x, y);
        } else if (this.drawingState === "") {
          const newCorridorEnds = {
            floorPlanId: this.obj._id,
            code:
              "corridorEnd_" +
              moment().format("YYMMDDHHmmss") +
              "_" +
              Math.floor(Math.random() * 16777215).toString(),
            name: "Corridor Ends " + (this.corridorEnds.length + 1),
            points: [x, y],
            stroke: this.drawingMeta.strokeColor,
            strokeWidth: this.drawingMeta.strokeWidth,
            closed: true,
            fill: helper.hexToRgbA(
              this.drawingMeta.fillColor,
              this.drawingMeta.fillOpacity
            ),
          };
          this.obj.corridorEnds.push(newCorridorEnds);
          this.drawingState = "progress";
        }
        this.$refs.stage.getStage().batchDraw();
      }

      if (this.drawType === "stairExitRect") {
        if (this.drawingState === "end") {
          if (this.exitStairAreas.length > 0) {
            const lastStairExit =
              this.exitStairAreas[this.exitStairAreas.length - 1];
            lastStairExit.points[2] = x;
            lastStairExit.points[3] = lastStairExit.points[1];
            lastStairExit.points[4] = x;
            lastStairExit.points[5] = y;
            lastStairExit.points[6] = lastStairExit.points[0];
            lastStairExit.points[7] = y;
          }
          this.$refs.stage.getStage().batchDraw();
        }
      }

      if (
        this.drawType === "obstacleRect" ||
        this.drawType === "obstructionRect"
      ) {
        if (this.drawingState === "end") {
          if (this.obstacles.length > 0) {
            const lastObstacles = this.obstacles[this.obstacles.length - 1];
            lastObstacles.points[2] = x;
            lastObstacles.points[3] = lastObstacles.points[1];
            lastObstacles.points[4] = x;
            lastObstacles.points[5] = y;
            lastObstacles.points[6] = lastObstacles.points[0];
            lastObstacles.points[7] = y;
          }
          this.$refs.stage.getStage().batchDraw();
        }
      }

      if (this.drawType === "corridorEndRect") {
        if (this.drawingState === "end") {
          if (this.corridorEnds.length > 0) {
            const lastCorridorEnds =
              this.corridorEnds[this.corridorEnds.length - 1];
            lastCorridorEnds.points[2] = x;
            lastCorridorEnds.points[3] = lastCorridorEnds.points[1];
            lastCorridorEnds.points[4] = x;
            lastCorridorEnds.points[5] = y;
            lastCorridorEnds.points[6] = lastCorridorEnds.points[0];
            lastCorridorEnds.points[7] = y;
          }
          this.$refs.stage.getStage().batchDraw();
        }
      }

      if (
        this.drawType === "doorHorizontal" ||
        this.drawType === "doorVertical"
      ) {
        const mousePos = this.$refs.stage
          .getStage()
          .getRelativePointerPosition();

        let aspectRatio = 1;

        const width = this.drawingMeta.width;
        const height = this.drawingMeta.width * aspectRatio;
        const x = mousePos.x - width / 2;
        const y = mousePos.y - height / 2;
        // const points = this.calculateRotatedPoints(x, y, width, height, parseFloat(this.drawingMeta.doorRotation));
        const { x: rotatedX, y: rotatedY } = this.getRotatedCenterCoordinates(
          mousePos.x,
          mousePos.y,
          width,
          height,
          0
        );
        const { x: rotatedLeftX, y: rotatedLeftY } = this.getRotatedCenterCoordinates(
          x,
          y,
          width,
          height,
          parseFloat(this.drawingMeta.doorRotation)
        );
        const points = this.calculateRotatedPoints(
          rotatedX,
          rotatedY,
          width,
          height,
          0
        );
        this.checkDoorCheckAnyClash(points)

        if (
          this.drawingMeta.doorType === "swingIn" ||
          this.drawingMeta.doorType === "swingOut" ||
          this.drawingMeta.doorType === "swingBoth"
        ) {
          if (this.drawingMeta.doorDirection === "swingLeft") {
            this.swingDoor = this.leftSwingDoor;
          } else {
            this.swingDoor = this.rightSwingDoor;
          }
        } else if (this.drawingMeta.doorType === "folding") {
          this.swingDoor = this.foldingDoor;
        } else if (this.drawingMeta.doorType === "openPath") {
          this.swingDoor = this.openPathDoor;
        } else if (this.drawingMeta.doorType === "sliding") {
          this.swingDoor = this.slidingDoor;
        } else if (this.drawingMeta.doorType === "doubleDoor") {
          this.swingDoor = this.doubleDoor;
        }

        if (this.drawingMeta.fireRating === "30 minutes") {
          this.fireRatingDoor = this.fr30Door;
        } else if (this.drawingMeta.fireRating === "60 minutes") {
          this.fireRatingDoor = this.fr60Door;
        } else if (this.drawingMeta.fireRating === "90 minutes") {
          this.fireRatingDoor = this.fr90Door;
        } else if (this.drawingMeta.fireRating === "120 minutes") {
          this.fireRatingDoor = this.fr120Door;
        } else if (this.drawingMeta.fireRating === "180 minutes") {
          this.fireRatingDoor = this.fr180Door;
        } else if (this.drawingMeta.fireRating === "240 minutes") {
          this.fireRatingDoor = this.fr240Door;
        } else {
          this.fireRatingDoor = null;
        }

        const previewDoor = {
          code: "doorPreview",
          name: "Door Preview",
          floorPlanId: this.obj._id,
          image: this.swingDoor,
          fireRatingDoorImg: this.fireRatingDoor,
          x: rotatedLeftX,
          y: rotatedLeftY,
          points: points,
          rotation: parseFloat(this.drawingMeta.doorRotation),
          width: this.drawingMeta.width,
          height: this.drawingMeta.width * aspectRatio,
        };

        this.doorPreviewElement = previewDoor;
      }

      if (
        this.drawType === "exitDoorHorizontal" ||
        this.drawType === "exitDoorVertical"
      ) {
        const mousePos = this.$refs.stage
          .getStage()
          .getRelativePointerPosition();

        const aspectRatio = 1;

        var width = parseInt(this.drawingMeta.width);
        var depth = parseInt(this.drawingMeta.width * aspectRatio);
        var height = depth;

        const x = mousePos.x - width / 2;
        const y = mousePos.y - depth / 2;

        // Determine the door orientation based on drawType
        var points;
        const { x: rotatedX, y: rotatedY } = this.getRotatedCenterCoordinates(
          x,
          y,
          width,
          height,
          parseFloat(this.drawingMeta.doorRotation)
        );
        points = this.calculateRotatedPoints(
          mousePos.x,
          mousePos.y,
          width,
          height,
          parseFloat(this.drawingMeta.doorRotation)
        );

        const previewDoor = {
          code: "doorPreview",
          floorPlanId: this.obj._id,
          image: this.exitDoorImg,
          posX: rotatedX,
          posY: rotatedY,
          points: points,
          rotation: this.drawingMeta.doorRotation,
          width: parseFloat(this.drawingMeta.width),
          height: parseFloat(this.drawingMeta.width), // Calculate the height based on the width and aspect ratio
        };

        // Add or update the door preview in the list of doors
        const doorPreviewIndex = this.exitStairDoors.findIndex(
          (door) => door.code === "doorPreview"
        );
        if (doorPreviewIndex !== -1) {
          // Update the existing door preview
          this.exitStairDoors.splice(doorPreviewIndex, 1, previewDoor);
        } else {
          // Add the door preview to the list of doors
          this.exitStairDoors.push(previewDoor);
        }
        this.$refs.stage.getStage().batchDraw();
      }

      if (this.drawType === "doors") {
        if (this.drawingState === "progress") {
          // Update the last point of the door to the current mouse position
          const door = this.obj.doors[this.obj.doors.length - 1];
          door.points.splice(-2, 2, x, y);

          // Redraw the stage to reflect the updated door
          this.$refs.stage.getStage().batchDraw();
        }
      }

      if (this.drawType === "exitDoor") {
        if (this.drawingState === "progress") {
          const exitDoor = this.exitStairDoors[this.exitStairDoors.length - 1];
          exitDoor.points.splice(-2, 2, x, y);
        } else if (this.drawingState === "") {
          const newExitDoor = {
            code:
              "exitStairDoors_" +
              moment().format("YYMMDDHHmmss") +
              "_" +
              Math.floor(Math.random() * 16777215).toString(),
            name: "Exit Door " + (this.exitStairDoors.length + 1),
            points: [x, y],
            stroke: this.stroke,
            strokeWidth: this.drawingMeta.strokeWidth,
            closed: true,
            fill: helper.hexToRgbA(
              this.drawingMeta.fillColor,
              this.drawingMeta.fillOpacity
            ),
          };

          this.exitStairDoors.push(newExitDoor);
          this.drawingState = "progress";
        }
        this.$refs.stage.getStage().batchDraw();
      }

      if (this.drawType === "exitStairWidth") {
        if (this.isDrawingActive && this.drawingState === "end") {
          const currentLine =
            this.exitStairWidths[this.exitStairWidths.length - 1];
          currentLine.points = [
            this.mouseClickPoint[0].x,
            this.mouseClickPoint[0].y,
            x,
            y,
          ];
          this.$refs.stage.getStage().batchDraw();
        }
      }

      if (this.drawType === "scale") {
        if (this.drawingState === "end") {
          this.obj.scale.points = [
            this.mouseClickPoint[0].x,
            this.mouseClickPoint[0].y,
            x,
            y,
          ];
        }
        this.$refs.stage.getStage().batchDraw();
      }

      if (!this.isDrawingActive) {
        return;
      }
    },
    onMouseOut() {
      this.toolTip.visible = false;
      const arraysToReset = [this.obj.travelDistances, this.obj.commonPaths, this.obj.deadEnds];

      arraysToReset.forEach(array => {
        array.forEach(el => {
          // el.strokeWidth = 2;
          delete el.shadowColor
          delete el.shadowBlur
          delete el.shadowOffset
          delete el.shadowOpacity
        });
      });
    },

    getCenterOfShape(points) {
      return drawing.getCenterOfShape(points);
    },
    getRoomInfo(item) {
      //to prevent mess up with the drawing for small area
      var areaInPixel = this.getPolygonArea(item);
      if (areaInPixel < 1000) return "";

      var code = item.name;
      var area = this.getPolygonAreaInMeter(item);
      return code + "\n" + area + " m 2";
    },
    getPolygonAreaInMeter(item) {
      var areaInPixel = this.getPolygonArea(item);
      var areaInMeter =
        areaInPixel *
        this.obj.scale.distanceInMeterForOnePixel *
        this.obj.scale.distanceInMeterForOnePixel;
      areaInMeter = Math.round(areaInMeter * 1000) / 1000;
      return areaInMeter;
    },
    onMouseMove(item) {
      // let stage = this.$refs.stage.getStage();
      // var mousePos = stage.getPointerPosition();
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      this.toolTip.x = mousePos.x + 5;
      this.toolTip.y = mousePos.y + 5;
      this.toolTip.text = this.getHoverMouseDetails(item);
      this.toolTip.visible = true;
    },
    getHoverMouseDetails(item) {
      if (item.code.startsWith("room")) {
        var code = item.name;
        var area = this.getPolygonAreaInMeter(item);
        return code + "\n" + area + " m 2";
      }
      return item.name;
    },
    getPolygonArea(item) {
      var X = [];
      var Y = [];
      for (let i = 0; i < item.points.length; i++) {
        if (i % 2 == 0) X.push(item.points[i]);
        else Y.push(item.points[i]);
      }
      return this.polygonArea(X, Y, X.length);
    },
    polygonArea(X, Y, n) {
      // Initialize area
      let area = 0.0;

      // Calculate value of shoelace formula
      let j = n - 1;
      for (let i = 0; i < n; i++) {
        area += (X[j] + X[i]) * (Y[j] - Y[i]);

        // j is previous vertex to i
        j = i;
      }

      // Return absolute value
      return Math.abs(area / 2.0);
    },

    // corridorEndLabelClick() {
    // },
    degreesToRadians(degrees) {
      let radians = (degrees * Math.PI) / 180;
      return radians;
    },
    checkDoorCheckAnyClash(points) {
      const roomClashed = []
      const corridorClashed = []
      const doorPoints = points

      // this.prepareGrid(true, false);
      this.rooms.forEach((room) => {
        const roomPoints = JSON.parse(JSON.stringify(room.points));
        const doorClashed = this.doPolygonsIntersect(doorPoints, roomPoints)
        if (doorClashed) {
          roomClashed.push(JSON.parse(JSON.stringify(room)))
        }
      });
      this.corridorEnds.forEach((corridor) => {
        const corridorPoints = JSON.parse(JSON.stringify(corridor.points));
        const doorClashed = this.doPolygonsIntersect(doorPoints, corridorPoints)
        if (doorClashed) {
          corridorClashed.push(JSON.parse(JSON.stringify(corridor)))
        }
      });
      this.glowingEffects = {
        'roomClashed': roomClashed,
        'corridorClashed': corridorClashed,
      }

      this.obj.rooms.forEach((room) => {
        const isClashed = this.glowingEffects.roomClashed.some(
          (clashedRoom) => clashedRoom.code === room.code
        );

        if (isClashed) {
          this.$set(room, 'fill', 'rgb(255,77,79,0.8)');
        } else {
          this.$set(room, 'fill', 'rgb(255,77,79,0.5)');
        }
      });

      this.obj.corridorEnds.forEach((corridor) => {
        const isClashed = this.glowingEffects.corridorClashed.some(
          (clashedCorridor) => clashedCorridor.code === corridor.code
        );

        if (isClashed) {
          this.$set(corridor, 'fill', 'rgb(255,165,0,0.8)');
        } else {
          this.$set(corridor, 'fill', 'rgb(255,165,0,0.5)');
        }
      });
    },
    async addImage() {
      const mousePos = this.$refs.stage.getStage().getRelativePointerPosition();
      let aspectRatio = 1;

      const width = this.drawingMeta.width;
      const height = this.drawingMeta.width * aspectRatio;
      const x = mousePos.x - width / 2;
      const y = mousePos.y - height / 2;
      // const points = this.calculateRotatedPoints(x, y, width, height, parseFloat(this.drawingMeta.doorRotation));
      const { x: rotatedX, y: rotatedY } = this.getRotatedCenterCoordinates(
        mousePos.x,
        mousePos.y,
        width,
        height,
        0
      );
      const { x: rotatedLeftX, y: rotatedLeftY } = this.getRotatedCenterCoordinates(
        x,
        y,
        width,
        height,
        parseFloat(this.drawingMeta.doorRotation)
      );
      const points = this.calculateRotatedPoints(
        rotatedX,
        rotatedY,
        width,
        height,
        0
      );
      this.checkDoorCheckAnyClash(points)

      if (
        this.drawingMeta.doorType === "swingIn" ||
        this.drawingMeta.doorType === "swingOut" ||
        this.drawingMeta.doorType === "swingBoth"
      ) {
        if (this.drawingMeta.doorDirection === "swingLeft") {
          this.swingDoor = this.leftSwingDoor;
        } else {
          this.swingDoor = this.rightSwingDoor;
        }
      } else if (this.drawingMeta.doorType === "folding") {
        this.swingDoor = this.foldingDoor;
      } else if (this.drawingMeta.doorType === "openPath") {
        this.swingDoor = this.openPathDoor;
      } else if (this.drawingMeta.doorType === "sliding") {
        this.swingDoor = this.slidingDoor;
      } else if (this.drawingMeta.doorType === "doubleDoor") {
        this.swingDoor = this.doubleDoor;
      }

      if (this.drawingMeta.fireRating === "30 minutes") {
        this.fireRatingDoor = this.fr30Door;
      } else if (this.drawingMeta.fireRating === "60 minutes") {
        this.fireRatingDoor = this.fr60Door;
      } else if (this.drawingMeta.fireRating === "90 minutes") {
        this.fireRatingDoor = this.fr90Door;
      } else if (this.drawingMeta.fireRating === "120 minutes") {
        this.fireRatingDoor = this.fr120Door;
      } else if (this.drawingMeta.fireRating === "180 minutes") {
        this.fireRatingDoor = this.fr180Door;
      } else if (this.drawingMeta.fireRating === "240 minutes") {
        this.fireRatingDoor = this.fr240Door;
      }

      let numericValue = parseInt(this.drawingMeta.fireRating, 10);
      let fireRatingId = !isNaN(numericValue)
        ? this.fireRatings.find((el) => el.minutes === numericValue)?.id
        : null;

      const newNumber = this.findAvailableNumber(this.obj.doors);

      this.obj.doors.push({
        floorPlanId: this.obj._id,
        code:
          "door" +
          newNumber +
          "_" +
          Math.floor(Math.random() * 16777215).toString(),
        name: "Door " + newNumber,
        doorType: this.drawingMeta.doorType,
        doorDirection: this.drawingMeta.doorDirection,
        image: this.swingDoor,
        width: parseFloat(this.drawingMeta.width),
        height: this.drawingMeta.width * aspectRatio,
        x: rotatedLeftX,
        y: rotatedLeftY,
        rotation: parseFloat(this.drawingMeta.doorRotation),
        posX: rotatedLeftX,
        posY: rotatedLeftY,
        draggable: true,
        fill: "#000",
        points: points,
        fireRatingId: fireRatingId,
        fireRatingDoorImg: this.fireRatingDoor,
        isFinalExitDoor: this.drawingMeta.doorOptions.finalExitDoor,
        isRoomDoor: this.drawingMeta.doorOptions.roomDoor,
        isUnitDoor: this.drawingMeta.doorOptions.unitDoor,
        isAccessible: this.drawingMeta.isAccessible,
      });

      let payload = { ...this.obj.doors[this.obj.doors.length - 1] };
      delete payload.x;
      delete payload.y;
      delete payload.image;
      delete payload.fireRatingDoorImg;

      await this.createAndHandleApiResponse(this.doorApi, payload);
    },
    async updateDoorPositionOnDragStop(item, event) {
      const getCenterCoordinates = (x, y, width, height) => {
        return { centerX: x + width / 2, centerY: y + height / 2 };
      }

      // Function to update the points when the object is dragged
      const updateOnDrag = (item) => {
        const { centerX, centerY } = getCenterCoordinates(item.posX, item.posY, item.width, item.height);
        const points = this.calculateRotatedPoints(centerX, centerY, item.width, item.height, item.rotation);

        // Update the points property of the object
        item.points = points;
        return points
      }
      item.posX = event.target.x();
      item.posY = event.target.y();
      updateOnDrag(item);  // Call this function to update the points
      let {
        _id,
        createdAt,
        updatedAt,
        __v,
        room,
        image,
        x,
        y,
        fireRatingDoorImg,
        ...payload
      } = item;

      this.doorApi
        .update(item, payload)
        .then((response) => {
          this.toast("Info", `Successfully update ${response.name}`, "success");
          this.resetObj();
        })
        .catch(({ data }) => {
          this.toast("Error", data.message, "danger");
        });

    },
    updateDoorPositionOnMouseMove(item, event) {
      const getCenterCoordinates = (x, y, width, height) => {
        return { centerX: x + width / 2, centerY: y + height / 2 };
      }

      // Function to update the points when the object is dragged
      const updateOnDrag = (item) => {
        const { centerX, centerY } = getCenterCoordinates(item.posX, item.posY, item.width, item.height);
        const points = this.calculateRotatedPoints(centerX, centerY, item.width, item.height, item.rotation);

        // Update the points property of the object
        item.points = points;
        return points
      }
      item.posX = event.target.x();
      item.posY = event.target.y();
      const points = updateOnDrag(item);  // Call this function to update the points
      this.checkDoorCheckAnyClash(points)
    },
    loadImage(source, propertyName) {
      const image = new window.Image();
      image.src = source;
      image.onload = () => {
        this[propertyName] = image;
      };
    },
    getFireRatings() {
      this.fireRatingApi
        .getList()
        .then((response) => {
          this.fireRatings = response.results;
        })
        .catch(({ data }) => {
          self.toast("Error", data.message, "danger");
        });
    },
    calculateRotatedPoints(cx, cy, w, h, theta) {
      // Convert theta from degrees to radians
      theta = (theta * Math.PI) / 180;

      const halfWidth = w / 2;
      const halfHeight = h / 2;

      const corners = [
        [-halfWidth, -halfHeight],
        [halfWidth, -halfHeight],
        [halfWidth, halfHeight],
        [-halfWidth, halfHeight],
      ];

      const rotatedPoints = [];

      for (const [dx, dy] of corners) {
        const xPrime = cx + (dx * Math.cos(theta) - dy * Math.sin(theta));
        const yPrime = cy + (dx * Math.sin(theta) + dy * Math.cos(theta));

        rotatedPoints.push(xPrime, yPrime);
      }

      return rotatedPoints; // [x1, y1, x2, y2, x3, y3, x4, y4]
    },

    getRotatedCenterCoordinates(x, y, width, height, rotationDegree) {
      const theta = (rotationDegree * Math.PI) / 180;
      const centerX = x + width / 2;
      const centerY = y + height / 2;
      const rotatedX =
        centerX +
        (x - centerX) * Math.cos(theta) -
        (y - centerY) * Math.sin(theta);
      const rotatedY =
        centerY +
        (x - centerX) * Math.sin(theta) +
        (y - centerY) * Math.cos(theta);
      return { x: rotatedX, y: rotatedY };

    },
    handleKreoInputChange() {
      var self = this;

      var kreoScaleObj = {
        kreoXScale: self.kreoScale.x,
        kreoYScale: self.kreoScale.x,
      };

      self.resizeLoading = true;

      self.kreoAiApi
        .autoMeasure(self.obj._id, kreoScaleObj)
        .then(() => {
          self.resizeLoading = false;
          self.resetObj();
        })
        .catch(({ data }) => {
          self.resizeLoading = false;
          console.log(data);
          if (data.code === 500) {
            console.log("Error: ", data.message);
          } else {
            self.toast("Error", data.message, "danger");
          }
        });
    },
  },
  mounted() {
    window.addEventListener("resize", this.changeRect);
    window.addEventListener("keydown", this.onKeyboardShortcutEvent);
    this.getFireRatings();
    document.body.classList.add("overflow-hidden");
    setTimeout(() => {
      this.changeRect();
      // this.imageConfig.x = (this.stageSize.width/4)
      // this.imageConfig.y = (this.stageSize.height/4)
      this.imageConfig.image = this.image
    }, 1000);
    // :closeOnBackdrop="false"
  },
  created() {
    this.loadImage(LeftSwingDoorImg, "leftSwingDoor");
    this.loadImage(RightSwingDoorImg, "rightSwingDoor");
    this.loadImage(FoldingDoorImg, "foldingDoor");
    this.loadImage(OpenPathDoorImg, "openPathDoor");
    this.loadImage(SlidingDoorImg, "slidingDoor");
    this.loadImage(DoubleDoorImg, "doubleDoor");
    this.loadImage(Fr30Door, "fr30Door");
    this.loadImage(Fr60Door, "fr60Door");
    this.loadImage(Fr90Door, "fr90Door");
    this.loadImage(Fr120Door, "fr120Door");
    this.loadImage(Fr180Door, "fr180Door");
    this.loadImage(Fr240Door, "fr240Door");
    this.loadImage(ExitDoorImg, "exitDoorImg");
  },
  beforeDestroy() {
    // Remove the CSS class from the body when the component is destroyed
    document.body.classList.remove("overflow-hidden");
  },
};
</script>

<style>
.work-component {
  display: inline-block;
  position: fixed;
  z-index: 100;
  right: 0;
  width: 320px;
  top: 125px;
  /* width: 100%;
  height: 100vh; */
}

.top-mid {
  /* float: left; */
  /* z-index: 3; */
  /* position: fixed; */
  /* left: 500px;
  top: 20px; */

  /* left: 0; */
  /* top: 164px; */
  /* background: white; */
  width: 264px;
  padding: 24px;
  background: #f5f5f5;
  position: fixed;
  top: 86px;
  z-index: 100;
}

.top-left {
  /* float: left;
  z-index: 3;
  position: fixed;
  left: 20px;
  top: 20px; */
}

.top-right {
  /* float: right;
  z-index: 3;
  position: fixed;
  right: 50px;
  top: 20px; */
}

.bottom-right {
  float: right;
  z-index: 3;
  position: fixed;
  bottom: 0;
  right: 0;
}

.scale-modal .close {
  display: none !important;
}

.ai-overlay {
  position: fixed;
  left: 264px;
  padding: 20px;
  z-index: 2;

  width: calc(100vw - 264px);
  height: 100%;
  background: rgb(0 0 0 / 80%);
  animation: fadeIn 0.4s cubic-bezier(0.16, 1, 0.3, 1) 0s 1 normal forwards;

  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.ai-overlay svg {
  width: 100px;
  height: 100px;
}

.ai-overlay h1 {
  color: #fff;
  font-size: 1rem;
  text-shadow: 1px 1px 1px #607d8b, 3px 3px 5px #ffffff;
}

.olf-ft-switch {
  display: flex;
  align-items: center;
  gap: 20px;
}

.btn-grp {
  margin-bottom: 1rem;
}

.btn-grp-label {
  font-size: 12px;
  margin-bottom: 0.5rem;
}

.elapsed-time-info {
  display: flex;
  justify-content: space-between;
}

.elapsed-time-info p {
  margin: 0;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}
</style>
