<template>
  <aside class="d-flex">
    <div class="bottom-btns">
      <!-- {{ scaleText }} -->
        <!-- <a  @click="scaleBoard()" class="scale-btn" :class="{ 'selected--': selectedItem === 'scale' }" v-c-tooltip="'Scale'">
          <img :src="require('@/assets/images/icons/scale.svg')" alt="" class="scale-icon">
        </a> -->
        <!-- <a @click="aiSuggest()" class="scale-btn" :class="{ 'selected--': selectedItem === 'ai-suggest' }">
          <img :src="require('@/assets/images/icons/ai-suggest.svg')" alt="" class="scale-icon">
        </a> -->
      </div>
  </aside>
</template>
<script>
import '@/index.css'
export default {
  name: "WpBottomMid",
  props: {
    scale: null,
  },
  data() {
    return {
      selectedItem: '',
    }
  },
  methods: {
    // dashboard(){
    //    // alert('dashboard')
    // }
    scaleBoard() {
      this.$emit('scaleClick')
      this.selectedItem = 'scale';
    },
    aiSuggest() {
      this.$emit('aiClick')
      this.selectedItem = 'ai-suggest';
    }
  },
  computed: {
    scaleText(){
      return "SCALE: " + this.lengthInPixel + " pixel : " +
      this.lengthInMeter + " meter";
    },
    lengthInPixel() {
      try {
        return Math.round(this.scale.lengthInPixel);
      } catch (error) {
        // console.log(error);
      }
      return 0;
    },
    lengthInMeter() {
      try {
        return this.scale.lengthInMeter;
      } catch (error) {
        // console.log(error);
      }
      return 0;
    },
  }
};
</script>

<style>
.bottom-btns {
  display: flex;
  gap: 8px;
}
.scale-btn {
  width: 48px;
  height: 48px;
  display: flex;
  background: #fff;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  cursor: pointer;
}

.scale-btn.selected-- {
  background: #0A1439;
}

.scale-btn.selected-- .scale-icon {
  filter: invert(100%);
}
.scale-icon {
  width: 16px;
  height: 16px;
}

</style>
