<template>
  <div>test
    <v-group :config="configGroup">
      <v-rect :config="configArea"> </v-rect>
      <v-label
        :config="{
          x: 30,
          y: 15,
        }"
      >
        <v-text :config="{ text: 'A :', fill: 'black' }" />
      </v-label>
      <v-label
        :config="{
          x: 50,
          y: 15,
        }"
      >
        <v-text :config="{ text: areaText, fill: 'black' }" />
      </v-label>
      <v-rect :config="configOl1"></v-rect>
      <v-label
        :config="{
          x: 100,
          y: 15,
        }"
      >
        <v-text :config="{ text: 'OLF :', fill: 'black' }" />
      </v-label>
      <v-label
        :config="{
          x: 135,
          y: 15,
        }"
      >
        <v-text :config="{ text: olfText, fill: 'black' }" />
      </v-label>
      <v-rect :config="configOlf"></v-rect>
      <v-label
        :config="{
          x: 20,
          y: 55,
        }"
      >
        <v-text :config="{ text: 'OL :', fill: 'black' }" />
      </v-label>
      <v-label
        :config="{
          x: 50,
          y: 55,
        }"
      >
        <v-text :config="{ text: olnText, fill: 'black' }" /> </v-label
      ><v-rect :config="configExit"></v-rect>
      <v-label
        :config="{
          x: 110,
          y: 55,
        }"
      >
        <v-text :config="{ text: 'E :', fill: 'black' }" />
      </v-label>
      <v-label
        :config="{
          x: 130,
          y: 55,
        }"
      >
        <v-text :config="{ text: exitText, fill: 'black' }" />
      </v-label>
    </v-group>
  </div>
</template>

<script>
  import WpMenuLeft from "@/components/WpMenuLeft.vue";
export default {

  name: "KvRoom",
  components: {},
  props: {
    obj: null,
    distanceInMeterForOnePixel: null,
  },
  data() {
    return {};
  },
  computed: {
    exitText(){
      return this.obj.totalDoorProvided;
    },
    areaText() {
      var area = this.getPolygonAreaInMeter();
      return Math.round(area);
    },
    olfText() {
      try {
        return this.obj.occupantLoadFactor.olfValue;
      } catch (error) {}
      return "N/A";
    },
    olnText() {
      try {
        var area = this.getPolygonAreaInMeter();
        var occupantNumber = area / this.obj.occupantLoadFactor.olfValue;
        return Math.round(occupantNumber);
      } catch (error) {}
      return "N/A";
    },
    configGroup() {
      return {
        name: this.obj.id + "group",
        id: this.obj.id,
        x: drawing.getCenterOfShape(this.obj.points)[0] - 50,
        y: drawing.getCenterOfShape(this.obj.points)[1] - 50,
        draggable: true,
      };
    },
    configArea() {
      return {
        width: 82,
        height: 38,
        stroke: "red",
        strokeWidth: 1,
        x: 0,
        y: 0,
      };
    },
    configOl1() {
      return {
        width: 82,
        height: 38,
        stroke: "red",
        strokeWidth: 1,
        x: 82,
        y: 0,
      };
    },
    configOlf() {
      return {
        width: 82,
        height: 38,
        stroke: "red",
        strokeWidth: 1,
        x: 0,
        y: 38,
      };
    },
    configExit() {
      return {
        width: 82,
        height: 38,
        stroke: "red",
        strokeWidth: 1,
        x: 82,
        y: 38,
      };
    },
  },
  watch: {
    obj(newVal, oldVal) {
    },
  },
  methods: {
    getDistanceFromPixelPointInMeter() {},
    getPolygonAreaInMeter() {
      var areaInPixel = this.getPolygonArea();
      var areaInMeter =
        areaInPixel *
        this.distanceInMeterForOnePixel *
        this.distanceInMeterForOnePixel;
      areaInMeter = Math.round(areaInMeter * 1000) / 1000;
      return areaInMeter;
    },
    getPolygonArea() {
      var X = [];
      var Y = [];
      for (let i = 0; i < this.obj.points.length; i++) {
        if (i % 2 == 0) X.push(this.obj.points[i]);
        else Y.push(this.obj.points[i]);
      }
      return this.polygonArea(X, Y, X.length);
    },
    polygonArea(X, Y, n) {
      // Initialize area
      let area = 0.0;

      // Calculate value of shoelace formula
      let j = n - 1;
      for (let i = 0; i < n; i++) {
        area += (X[j] + X[i]) * (Y[j] - Y[i]);

        // j is previous vertex to i
        j = i;
      }

      // Return absolute value
      return Math.abs(area / 2.0);
    },
  },
};
</script>
