<template>
  <aside class="d-flex">
    <div class="row btn-row">
      <CButton @click="$emit('undo')" color="secondary" :disabled="undoBtnDisabled" 
      class="util-btn"  v-c-tooltip="'Undo'">
        <span class="material-icons">undo</span>
      </CButton>
      <CButton @click="$emit('redo')" color="secondary" :disabled="redoBtnDisabled"
      class="util-btn" v-c-tooltip="'Redo'">
        <span class="material-icons">redo</span>
      </CButton>
      <CButton @click="$emit('clear')" color="secondary"  
      class="util-btn reset--" v-c-tooltip="'Clear'">
        <img width="20px" :src="require('@/assets/images/icons/reset.png')" alt="">
      </CButton>
      <CButton @click="$emit('save')" color="secondary"  
      class="util-btn" v-c-tooltip="'Save'">
        <span class="material-icons">save</span>
      </CButton>
      <CButton @click="$emit('run')" color="secondary"  
      class="util-btn" v-c-tooltip="'Run'">
        <span class="material-icons">play_arrow</span>
      </CButton>
      <!-- <CButton @click="$emit('reload')" color="secondary"  class="ml-1 mr-1 float-right">Reload</CButton> -->
    </div>
  </aside>
</template>
<script>
import "@/index.css";
export default {
  name: "WpQuickTool",
  props: {
    undoBtnDisabled: Boolean,
    redoBtnDisabled: Boolean,
  },
  methods: {
    save() {
      this.$emit("save");
    },
  },
};
</script>

<style>
.btn-row {
  gap: 10px;
}

.util-btn {
  background: #fff;
  border: 0;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.4s ease;
  color: #0A1439;
}

.util-btn:hover,
.util-btn:active,
.util-btn:focus,
.util-btn:visited {
  background: #2566EB;
  color: #fff;
  box-shadow: 0;
}


.util-btn:disabled {
  background-color: grey;
  cursor: not-allowed;
  color: #0A1439;
}

.reset--:hover img {
  filter: invert(1);
}
</style>
